import React from "react";
import PropTypes from "prop-types";
import {
  Alignment,
  Button,
  ControlGroup,
  Icon,
  MenuItem,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import LabelWithInfo from "../LabelWithInfo";

const SortBySelect = ({
  geneList,
  allFields,
  dataFields,
  selectedGene,
  selectedDataField,
  sortBy,
  selectGene,
  selectDataField,
  selectSortBy,
}) => (
  <div className="sort-by-container">
    <LabelWithInfo title="Sample Order" body={[]} />
    <RadioGroup
      className="sample-order-container"
      onChange={(e) => selectSortBy(e.target.value)}
      selectedValue={sortBy}
    >
      <Radio
        label=<div>
          <Icon icon="layout-hierarchy" />
          Most Frequent Variant
        </div>
        value="most-frequent-variant"
        alignIndicator={Alignment.RIGHT}
      />
      <Radio
        label=<ControlGroup>
          <Icon icon="layout-linear" />
          Single Gene
          <Select
            disabled={geneList.length === 0}
            filterable={false}
            itemRenderer={(gene, { handleClick, modifiers }) => {
              if (!modifiers.matchesPredicate) {
                return null;
              }
              return (
                <MenuItem
                  key={gene.id}
                  text={gene[gene.type]}
                  onClick={handleClick}
                />
              );
            }}
            items={geneList}
            onItemSelect={(gene) => selectGene(gene)}
            popoverProps={{
              minimal: true,
              position: "auto-end",
              usePortal: true,
            }}
          >
            <Button
              disabled={geneList.length === 0}
              intent={sortBy === "gene" ? "primary" : ""}
              minimal
              rightIcon="caret-up"
              text={
                selectedGene.type !== ""
                  ? selectedGene[selectedGene.type]
                  : "No gene selected"
              }
              small
            />
          </Select>
        </ControlGroup>
        value="gene"
        alignIndicator={Alignment.RIGHT}
        disabled={selectedGene === { type: "" }}
      />
      <Radio
        label=<ControlGroup>
          <Icon icon="vertical-bar-chart-asc" />
          Data Field
          <Select
            disabled={dataFields.length === 0}
            filterable={false}
            itemRenderer={(field, { handleClick, modifiers }) => {
              if (!modifiers.matchesPredicate) {
                return null;
              }
              return (
                <MenuItem
                  key={field.field}
                  text={field.name}
                  onClick={handleClick}
                />
              );
            }}
            items={allFields.filter((field) =>
              dataFields.includes(field.field)
            )}
            onItemSelect={(field) => selectDataField(field.field)}
            popoverProps={{ minimal: true, position: "top", usePortal: false }}
          >
            <Button
              disabled={dataFields.length === 0}
              intent={sortBy === "dataField" ? "primary" : ""}
              minimal
              rightIcon="caret-up"
              text={
                selectedDataField !== "" &&
                allFields.filter((field) => selectedDataField === field.field)
                  .length !== 0
                  ? allFields
                      .filter((field) => selectedDataField === field.field)
                      .shift().name
                  : "No data field selected"
              }
              small
            />
          </Select>
        </ControlGroup>
        value="dataField"
        alignIndicator={Alignment.RIGHT}
        disabled={selectedDataField === ""}
      />
    </RadioGroup>
  </div>
);

SortBySelect.propTypes = {
  geneList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  allFields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      table: PropTypes.string.isRequired,
      group: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  dataFields: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  sortBy: PropTypes.string.isRequired,
  selectedGene: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  selectedDataField: PropTypes.string.isRequired,
  selectGene: PropTypes.func.isRequired,
  selectDataField: PropTypes.func.isRequired,
  selectSortBy: PropTypes.func.isRequired,
};

export default SortBySelect;
