import ReduxQuerySync from "redux-query-sync";
import history from "../components/myHistory";
import { defaultUi, defaultTutorial } from "../defaultStates";
import {
  decodeUi,
  encodeUi,
  decodeTutorial,
  encodeTutorial,
} from "../util/urlParams";

const querySyncEnhancer = ReduxQuerySync.enhancer({
  params: {
    session: {
      selector: (state) => state.session,
      action: (value) => ({ type: "INPUT_SESSION", value }),
      stringToValue: (string) => ({ id: string }),
      valueToString: (value) => value.id,
      defaultValue: {
        id: "",
      },
    },
    ui: {
      selector: (state) => state.ui,
      action: (value) => ({
        type: "SET_PRESENT_AND_SAVED",
        selector: "ui",
        value,
      }),
      stringToValue: decodeUi,
      valueToString: encodeUi,
      defaultValue: {
        present: defaultUi,
        saved: defaultUi,
      },
    },
    tutorial: {
      selector: (state) => state.tutorial,
      action: (value) => ({ type: "SET_TUTORIAL", value }),
      stringToValue: decodeTutorial,
      valueToString: encodeTutorial,
      defaultValue: defaultTutorial,
    },
  },
  initialTruth: "location",
  replaceState: true,
  history,
});

export default querySyncEnhancer;
