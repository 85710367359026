import { connect } from "react-redux";
import { toggleExpressionDataFieldsWithLogScale } from "../../actions";
import ExpressionDataFieldScaleSelect from "../../components/DataFieldScaleSelect";

const mapStateToProps = (state) => ({
  allFields: state.dataFetch.allDataFields.data,
  dataFields: state.expression.present.dataFields,
  dataFieldsWithLogScale: state.expression.present.dataFieldsWithLogScale,
});

const mapDispatchToProps = (dispatch) => ({
  toggle: (dataField) =>
    dispatch(toggleExpressionDataFieldsWithLogScale(dataField)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionDataFieldScaleSelect);
