import { connect } from "react-redux";
import { selectExpressionTabId } from "../../actions";
import { saveCohort } from "../../actions/cohorts";
import ExpressionStatistics from "../../components/expression/Statistics";

const mapStateToProps = (state) => {
  const { data } = state.dataFetch.expression;
  return {
    selectedTabId: state.expression.present.ui.selectedTabId,
    potentialValues: data.potentialValues,
    quartiles: data.quartiles,
    dataFieldsLegend: data.dataFieldsLegend,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectTabId: (id) => dispatch(selectExpressionTabId(id)),
  save: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionStatistics);
