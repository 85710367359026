import { defaultSurvival } from "../defaultStates";

const survival = (state = defaultSurvival, action) => {
  switch (action.type) {
    case "TOGGLE_CONFIDENCE_INTERVAL":
      return { ...state, useConfidenceInterval: !state.useConfidenceInterval };
    case "SELECT_CONFIDENCE_INTERVAL":
      return { ...state, confidenceInterval: action.value };
    case "SELECT_LOGRANK_WEIGHTING":
      return { ...state, logrankWeighting: action.value };
    case "SELECT_SURVIVAL_TAB_ID":
      return { ...state, ui: { selectedTabId: action.id } };
    default:
      return state;
  }
};

export default survival;
