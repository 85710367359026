import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Callout,
  Classes,
  Dialog,
  Divider,
  InputGroup,
  NonIdealState,
  Tag,
  TagInput,
} from "@blueprintjs/core";

class PendingDialog extends React.Component {
  constructor(props) {
    super(props);
    const { cohorts } = this.props;
    this.state = {
      isOpen: cohorts.length > 0,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  static getDerivedStateFromProps(props, _unused) {
    const { cohorts } = props;
    return {
      isOpen: cohorts.length > 0,
    };
  }

  toggleDialog() {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  render() {
    const { cohorts, confirm, remove, rename } = this.props;
    const { isOpen } = this.state;
    return (
      <Dialog
        className="pending-cohorts-dialog"
        icon="person"
        isOpen={isOpen}
        onClose={() => remove(cohorts.map((c) => c.id))}
        title="Pending Cohorts"
        usePortal={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <Callout intent="primary" title="Instructions">
            Use the inputs below to tinker with the pending cohorts.
          </Callout>
          <div className="cohorts-container pending">
            <div className="list pending">
              <div className="inner">
                {cohorts.length !== 0 ? (
                  cohorts.map((c) => (
                    <div className="element" key={c.id}>
                      <div className="pending-body">
                        <div className="row large">
                          <div className="column-12-12">
                            <InputGroup
                              placeholder={c.name}
                              fill
                              leftIcon="label"
                              onChange={(e) => rename(c.id, e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row large">
                          <div className="column-6-12">
                            <Tag
                              className="id-count"
                              fill
                              large
                              minimal
                              icon="person"
                            >
                              {c.patientIds.length}
                            </Tag>
                            <Tag
                              className="id-count"
                              fill
                              large
                              minimal
                              icon="lab-test"
                            >
                              {c.sampleIds.length}
                            </Tag>
                          </div>
                        </div>
                        <div className="row large">
                          <div className="column-12-12">
                            <div>
                              <TagInput
                                fill
                                minimal
                                values={[c.source].concat(c.tags)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ButtonGroup large minimal vertical>
                          <Button
                            icon="cross"
                            intent="danger"
                            onClick={() => remove([c.id])}
                          />
                          <Button disabled />
                          <Button
                            icon="confirm"
                            intent="success"
                            onClick={() => confirm([c.id])}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  ))
                ) : (
                  <NonIdealState
                    icon="blocked-person"
                    title="No Pending Cohorts"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              disabled={cohorts.length === 0}
              text="Cancel All"
              intent="danger"
              onClick={() => remove(cohorts.map((c) => c.id))}
            />
            <Button
              disabled={cohorts.length === 0}
              text="Confirm All"
              intent="success"
              onClick={() => confirm(cohorts.map((c) => c.id))}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

PendingDialog.propTypes = {
  cohorts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      mapping: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
      patientIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      sampleIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      source: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  confirm: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  rename: PropTypes.func.isRequired,
};

export default PendingDialog;
