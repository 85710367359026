import React from "react";
import PropTypes from "prop-types";
import { Radio, RadioGroup } from "@blueprintjs/core";

const ColoringRadio = ({ coloring, selectColoring }) => (
  <div>
    <RadioGroup
      onChange={(e) => selectColoring(e.target.value)}
      selectedValue={coloring}
    >
      <Radio label="Cohort" value="cohort" />
      <Radio label="NGS Source" value="ngsSource" />
    </RadioGroup>
  </div>
);

ColoringRadio.propTypes = {
  coloring: PropTypes.string.isRequired,
  selectColoring: PropTypes.func.isRequired,
};

export default ColoringRadio;
