import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  Divider,
  InputGroup,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { debounce } from "../../util";
import LabelWithInfo from "../LabelWithInfo";

class HighlightedSaved extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      renamePending: false,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.initiateRename = this.initiateRename.bind(this);
    this.handleRename = debounce(this.handleRename, 2000);
  }

  static getDerivedStateFromProps(props, _unused) {
    const { id } = props;
    return {
      isOpen: id !== "id",
    };
  }

  handleRename(id, newName) {
    const { rename } = this.props;
    this.setState({
      renamePending: false,
    });
    rename(id, newName);
  }

  initiateRename() {
    this.setState({
      renamePending: true,
    });
  }

  toggleDialog() {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  render() {
    const { id, name, source, tags, clear, remove } = this.props;
    const { isOpen, renamePending } = this.state;
    return (
      <Dialog
        className="highlighted-cohort-dialog"
        icon="person"
        isOpen={isOpen}
        onClose={() => clear()}
        title={name}
        usePortal={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>
            <div className="cohort-name-container">
              <LabelWithInfo
                title="Cohort Name"
                body={[
                  "Use the input below to update the name of this cohort.",
                ]}
              />
              <InputGroup
                defaultValue={name}
                fill
                leftIcon="label"
                onChange={(e) => {
                  this.initiateRename();
                  this.handleRename(id, e.target.value);
                }}
                rightElement={
                  <Spinner size={15} value={renamePending ? undefined : 0} />
                }
              />
            </div>
            <Divider />
            <div className="tag-container">
              <LabelWithInfo
                title="Cohort Tags"
                body={["A list of tags used to hold cohort metadata."]}
              />
              <Tag intent="primary" minimal round>
                {source}
              </Tag>
              {tags.map((t) => (
                <Tag key={t} minimal round>
                  {t}
                </Tag>
              ))}
            </div>
            <Divider />
            <ButtonGroup
              fill
              minimal
              style={{ width: "20%", marginLeft: "80%" }}
            >
              <Button
                icon="trash"
                intent="danger"
                onClick={() => {
                  clear();
                  remove(id);
                }}
                small
              />
              <Button
                icon="confirm"
                intent="success"
                onClick={() => clear()}
                small
              />
            </ButtonGroup>
          </div>
        </div>
      </Dialog>
    );
  }
}

HighlightedSaved.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  source: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  clear: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  rename: PropTypes.func.isRequired,
};

HighlightedSaved.defaultProps = {
  id: "id",
  name: "SavedCohort",
  source: "Source",
  tags: [],
};

export default HighlightedSaved;
