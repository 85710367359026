import { connect } from "react-redux";
import { selectHLAPlotTabId } from "../../actions";
import HLAPlotContainer from "../../components/hla/PlotContainer";

const mapStateToProps = (state, ownProps) => {
  return {
    plotTabId: state.hla.present.ui.plotTabId,
    resizePlotsTriggerProp: ownProps.resizePlotsTriggerProp,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectPlotTabId: (id) => dispatch(selectHLAPlotTabId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HLAPlotContainer);
