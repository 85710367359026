import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Icon, Tab, Tabs, Text } from "@blueprintjs/core";
import { legendColors, legendSymbols } from "../../constants";
import FeatureUnderConstruction from "../FeatureUnderConstruction";
import LabelWithInfo from "../LabelWithInfo";
import LegendTabPanel from "../LegendTabPanel";
import PotentialValuesTabPanel from "../PotentialValuesTabPanel";

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      selectedTabId,
      field,
      potentialValues,
      quartiles,
      valueGroups,
      selectTabId,
      save,
    } = this.props;
    let isFieldNumeric = true;
    if (field.class === "enum" || field.class === "str") {
      isFieldNumeric = false;
    }
    let noCohortsSelected = true;
    if (
      potentialValues !== undefined &&
      Object.entries(potentialValues).length !== 0
    ) {
      noCohortsSelected = false;
    }

    return (
      <div className="statistics-container">
        <Tabs
          id="TabsExample"
          onChange={(newTabId) => selectTabId(newTabId)}
          selectedTabId={selectedTabId}
        >
          <Tab
            id="legend-tab"
            title="Legend"
            panel=<LegendTabPanel />
            panelClassName="legend-container"
          />
          <Tab
            id="potential-values-tab"
            title="Potential Values"
            panel=<PotentialValuesTabPanel
              noCohortsSelected={noCohortsSelected}
              potentialValues={potentialValues}
            />
            panelClassName="potential-values-container"
          />
          <Tab
            id="cohort-creation-tab"
            title={isFieldNumeric ? "Quartiles" : "Values"}
            panel={
              isFieldNumeric ? (
                <div>
                  <LabelWithInfo title="Cohorts By Quartiles" body={[]} />
                  <div className="list">
                    {quartiles !== undefined &&
                    Object.keys(quartiles).length !== 0 ? (
                      Object.entries(quartiles).map(([name, fields]) =>
                        Object.entries(fields).map(([_unused, q]) => (
                          <div key={name}>
                            <Text className="cohort-label" ellipsize>
                              <Icon icon="person" />
                              {name.replace("<br>", " ")}
                            </Text>
                            {q.map(([typeLabel, quartilesArray]) => (
                              <div className="cohort-container" key={typeLabel}>
                                <Text>
                                  <Icon
                                    icon={legendSymbols[typeLabel]}
                                    style={{ color: legendColors[typeLabel] }}
                                  />
                                  {typeLabel}
                                </Text>
                                <ButtonGroup className="buttons" fill>
                                  <Button
                                    small
                                    text="Q1"
                                    onClick={() =>
                                      save(
                                        quartilesArray[0].map(
                                          (id) => id.split("+")[0]
                                        ),
                                        field.table === "Samples"
                                          ? quartilesArray[0].map(
                                              (id) => id.split("+")[1]
                                            )
                                          : [],
                                        "Distribution",
                                        [field.name, typeLabel, "Q1"]
                                      )
                                    }
                                  />
                                  <Button
                                    small
                                    text="Q2"
                                    onClick={() =>
                                      save(
                                        quartilesArray[1].map(
                                          (id) => id.split("+")[0]
                                        ),
                                        field.table === "Samples"
                                          ? quartilesArray[1].map(
                                              (id) => id.split("+")[1]
                                            )
                                          : [],
                                        "Distribution",
                                        [field.name, typeLabel, "Q2"]
                                      )
                                    }
                                  />
                                  <Button
                                    small
                                    text="Q3"
                                    onClick={() =>
                                      save(
                                        quartilesArray[2].map(
                                          (id) => id.split("+")[0]
                                        ),
                                        field.table === "Samples"
                                          ? quartilesArray[2].map(
                                              (id) => id.split("+")[1]
                                            )
                                          : [],
                                        "Distribution",
                                        [field.name, typeLabel, "Q3"]
                                      )
                                    }
                                  />
                                  <Button
                                    small
                                    text="Q4"
                                    onClick={() =>
                                      save(
                                        quartilesArray[3].map(
                                          (id) => id.split("+")[0]
                                        ),
                                        field.table === "Samples"
                                          ? quartilesArray[3].map(
                                              (id) => id.split("+")[1]
                                            )
                                          : [],
                                        "Distribution",
                                        [field.name, typeLabel, "Q4"]
                                      )
                                    }
                                  />
                                  <Button
                                    small
                                    icon="multi-select"
                                    onClick={() => {
                                      save(
                                        quartilesArray[0].map(
                                          (id) => id.split("+")[0]
                                        ),
                                        field.table === "Samples"
                                          ? quartilesArray[0].map(
                                              (id) => id.split("+")[1]
                                            )
                                          : [],
                                        "Distribution",
                                        [field.name, typeLabel, "Q1"]
                                      );
                                      save(
                                        quartilesArray[1].map(
                                          (id) => id.split("+")[0]
                                        ),
                                        field.table === "Samples"
                                          ? quartilesArray[1].map(
                                              (id) => id.split("+")[1]
                                            )
                                          : [],
                                        "Distribution",
                                        [field.name, typeLabel, "Q2"]
                                      );
                                      save(
                                        quartilesArray[2].map(
                                          (id) => id.split("+")[0]
                                        ),
                                        field.table === "Samples"
                                          ? quartilesArray[2].map(
                                              (id) => id.split("+")[1]
                                            )
                                          : [],
                                        "Distribution",
                                        [field.name, typeLabel, "Q3"]
                                      );
                                      save(
                                        quartilesArray[3].map(
                                          (id) => id.split("+")[0]
                                        ),
                                        field.table === "Samples"
                                          ? quartilesArray[3].map(
                                              (id) => id.split("+")[1]
                                            )
                                          : [],
                                        "Distribution",
                                        [field.name, typeLabel, "Q4"]
                                      );
                                    }}
                                  />
                                </ButtonGroup>
                              </div>
                            ))}
                          </div>
                        ))
                      )
                    ) : (
                      <Text>Nothing to display.</Text>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <LabelWithInfo title="Cohorts By Value" body={[]} />
                  <div className="list">
                    {valueGroups !== undefined &&
                    Object.keys(quartiles).length !== 0 ? (
                      Object.entries(valueGroups).map(([name, fields]) =>
                        Object.entries(fields).map(([_unused, vg]) => (
                          <div key={name}>
                            <Text className="cohort-label" ellipsize>
                              <Icon icon="person" />
                              {name.replace("<br>", " ")}
                            </Text>
                            {Array.isArray(vg) ? (
                              vg.map(([typeLabel, valueGroupsArray]) => (
                                <div
                                  className="cohort-container"
                                  key={typeLabel}
                                >
                                  <Text>
                                    <Icon
                                      icon={legendSymbols[typeLabel]}
                                      style={{ color: legendColors[typeLabel] }}
                                    />
                                    {typeLabel}
                                  </Text>
                                  <ButtonGroup
                                    className="buttons"
                                    fill
                                    style={{ width: "100%" }}
                                    vertical
                                  >
                                    {Object.entries(valueGroupsArray).map(
                                      ([valueName, ids]) => (
                                        <Button
                                          key={valueName}
                                          onClick={() =>
                                            save(
                                              ids.map((id) => id.split("+")[0]),
                                              field.table === "Samples"
                                                ? ids.map(
                                                    (id) => id.split("+")[1]
                                                  )
                                                : [],
                                              "Distribution",
                                              [field.name, typeLabel, valueName]
                                            )
                                          }
                                          small
                                          text={valueName}
                                        />
                                      )
                                    )}
                                    <Button
                                      onClick={() => {
                                        Object.entries(
                                          valueGroupsArray
                                        ).forEach(([valueName, ids]) =>
                                          save(
                                            ids.map((id) => id.split("+")[0]),
                                            field.table === "Samples"
                                              ? ids.map(
                                                  (id) => id.split("+")[1]
                                                )
                                              : [],
                                            "Distribution",
                                            [field.name, typeLabel, valueName]
                                          )
                                        );
                                      }}
                                      small
                                      icon="multi-select"
                                    />
                                  </ButtonGroup>
                                </div>
                              ))
                            ) : (
                              <div />
                            )}
                          </div>
                        ))
                      )
                    ) : (
                      <Text>Nothing to display.</Text>
                    )}
                  </div>
                </div>
              )
            }
            panelClassName={
              isFieldNumeric ? "quartiles-container" : "values-container"
            }
          />
          <Tab
            id="statistics-tab"
            title="Statistics"
            panel={<FeatureUnderConstruction description="" />}
            panelClassName="statistics-panel-container"
          />
          <Tabs.Expander />
        </Tabs>
      </div>
    );
  }
}

Statistics.propTypes = {
  selectedTabId: PropTypes.string.isRequired,
  field: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
    table: PropTypes.string,
    group: PropTypes.string,
    class: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  potentialValues: PropTypes.object.isRequired,
  quartiles: PropTypes.object.isRequired,
  valueGroups: PropTypes.object.isRequired,
  selectTabId: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default Statistics;
