import { connect } from "react-redux";
import { getSelectedCohortsWithIds } from "../../util";
import { EndpointMapping } from "../../constants";
import { fetchData, checkStatus } from "../../actions/async";
import { toggleVolcanoDialog, setTimerId } from "../../actions";
import VolcanoBatchCorrectionButton from "../../components/volcano/BatchCorrectionButton";

const mapStateToProps = (state) => ({
  selectedCohorts: getSelectedCohortsWithIds(state),
  numGenes: state.volcano.present.geneList.length,
  isDialogOpen: state.volcano.present.isDialogOpen,
  isTaskStarted: state.task.status === "STARTED",
});

const mapDispatchToProps = (dispatch) => ({
  toggleDialog: () => dispatch(toggleVolcanoDialog()),
  triggerTask: () => dispatch(fetchData([EndpointMapping.BATCH_CORRECTION])),
  checkStatus: (taskId) => dispatch(checkStatus(taskId)),
  setTimerId: (timerId) => dispatch(setTimerId(timerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VolcanoBatchCorrectionButton);
