import React from "react";
import PropTypes from "prop-types";
import { Button, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";

const SessionMenu = ({
  sessionId,
  uiURL,
  tutorialURL,
  storeSession,
  forkSession,
  newToast,
}) => (
  <Menu>
    <MenuDivider title="Current Session" />
    <div className="current-session">
      <pre>{sessionId}</pre>
      <Button
        icon="duplicate"
        minimal
        onClick={() => {
          navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
              if (result.state === "granted" || result.state === "prompt") {
                navigator.clipboard.writeText(window.location.href).then(
                  () =>
                    newToast({
                      intent: "success",
                      message: "Copied current session to clipboard.",
                    }),
                  () => {}
                );
              }
            });
        }}
      />
    </div>
    <MenuDivider title="Session Options" />
    <MenuItem disabled icon="history" text="See Related Sessions" />
    <MenuItem
      href="/"
      icon="clean"
      label={"\u{21E7}F"}
      text="Start Fresh Session"
    />
    <MenuItem icon="fork" text="Fork New Session">
      <MenuItem
        icon="square"
        onClick={() => forkSession(false)}
        shouldDismissPopover={false}
        text="Classic"
      />
    </MenuItem>
    <MenuItem
      icon="share"
      onClick={() => {
        storeSession();
        navigator.permissions
          .query({ name: "clipboard-write" })
          .then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
              navigator.clipboard
                .writeText(
                  `${window.location.origin}${window.location.pathname}?session=${sessionId}_shareable` +
                    `&ui=${uiURL}` +
                    `&tutorial=${tutorialURL}`
                )
                .then(
                  () =>
                    newToast({
                      intent: "success",
                      message: "Copied shareable session to clipboard.",
                    }),
                  () => {}
                );
            }
          });
      }}
      shouldDismissPopover={false}
      text="Copy Shareable Session"
    />
  </Menu>
);

SessionMenu.propTypes = {
  sessionId: PropTypes.string.isRequired,
  uiURL: PropTypes.string.isRequired,
  tutorialURL: PropTypes.string.isRequired,
  storeSession: PropTypes.func.isRequired,
  forkSession: PropTypes.func.isRequired,
  newToast: PropTypes.func.isRequired,
};

export default SessionMenu;
