import React from "react";
import PropTypes from "prop-types";
import { Blockquote, Menu, MenuItem } from "@blueprintjs/core";

const TreatmentPresetMenu = ({ replaceNodes }) => (
  <Menu className="treatment-presets">
    <MenuItem
      icon="calendar"
      text="By Treatment Status"
      shouldDismissPopover={false}
    >
      <MenuItem
        text="Pre Treatment"
        shouldDismissPopover={false}
        onClick={() => replaceNodes("Pre Treatment")}
      />
      <Blockquote>
        Selects samples taken before the patient progressed on an ICI treatment.
      </Blockquote>
      <MenuItem
        text="On/Post Treatment"
        shouldDismissPopover={false}
        onClick={() => replaceNodes("On/Post Treatment")}
      />
      <Blockquote>
        Selects samples taken after the patient progressed on an ICI treatment.
      </Blockquote>
      <MenuItem
        text="On Treatment"
        shouldDismissPopover={false}
        onClick={() => replaceNodes("On Treatment")}
      />
      <Blockquote>
        Selects samples taken shortly after the patient progressed on an ICI
        treatment.
      </Blockquote>
      <MenuItem
        text="Anytime"
        shouldDismissPopover={false}
        onClick={() => replaceNodes("Anytime")}
      />
      <Blockquote>
        Selects samples taken at any time relative to when the patient
        progressed on an ICI treatment.
      </Blockquote>
    </MenuItem>
    <MenuItem
      icon="endorsed"
      text="Published Cohorts"
      shouldDismissPopover={false}
    >
      {[
        "VanAllen-2015-Science",
        "Riaz-2017-Cell",
        "Gide-2019-CancerCell",
        "Hugo-2016-Cell",
        "Auslander-2018-NatMed",
        "Valero-2021-NatGen",
        "Rizvi-2015-Science",
        "Cloughesy-2019-NatMed",
      ].map((c) => (
        <MenuItem
          text={c}
          shouldDismissPopover={false}
          onClick={() => replaceNodes(c)}
        />
      ))}
    </MenuItem>
  </Menu>
);

TreatmentPresetMenu.propTypes = {
  replaceNodes: PropTypes.func.isRequired,
};

export default TreatmentPresetMenu;
