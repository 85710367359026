import { defaultDistribution } from "../defaultStates";

const distribution = (state = defaultDistribution, action) => {
  switch (action.type) {
    case "SELECT_DISTRIBUTION_SOURCE":
      return {
        ...state,
        source: action.source,
      };
    case "SELECT_DISTRIBUTION_DATA_FIELD":
      return {
        ...state,
        dataField: action.field,
      };
    case "SELECT_DISTRIBUTION_DATA_FIELD_SCALE":
      return {
        ...state,
        scale: action.scale,
      };
    case "SELECT_DISTRIBUTION_TAB_ID":
      return { ...state, ui: { selectedTabId: action.id } };
    default:
      return state;
  }
};

export default distribution;
