import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ControlGroup,
  HTMLSelect,
  Icon,
  MenuItem,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import LabelWithInfo from "../LabelWithInfo";

const groupColor = (group) => {
  switch (group) {
    case "Demographic":
      return "#7D5125";
    case "Clinical":
      return "#C22762";
    case "DNA":
      return "#2458B3";
    case "CIBERSORT":
      return "#238C2C";
    case "ssGSEA":
      return "#BF8C0A";
    default:
      return "gray";
  }
};

const getSelectedField = (allFields, selectedField) => {
  if (allFields === undefined) {
    return undefined;
  }
  return allFields.filter((field) => field.field === selectedField).shift();
};

const DataFieldPicker = ({
  firstOrSecond,
  allFields,
  selectedField,
  scale,
  tooltip,
  selectDataField,
  selectScale,
}) => (
  <div className="data-field-picker-container">
    <Tooltip {...tooltip}>
      <div>
        <LabelWithInfo
          title={
            firstOrSecond === "first"
              ? "Data Field (X Axis)"
              : "Data Field (Y Axis)"
          }
          body={[
            "Select the data field used in the Distribution plot.",
            "Data fields labelled `Patient` will use the selected cohorts' visible patients while those labelled `Sample` will use the visible samples.",
          ]}
        />
        <ControlGroup fill>
          <Select
            itemPredicate={(query, item) =>
              query.toLowerCase() ===
                item.name.toLowerCase().slice(0, query.length) ||
              query.toLowerCase() ===
                item.table.toLowerCase().slice(0, query.length) ||
              query.toLowerCase() ===
                item.group.toLowerCase().slice(0, query.length)
            }
            itemRenderer={(field, { handleClick, modifiers }) => {
              if (!modifiers.matchesPredicate) {
                return null;
              }
              const text = `${field.name}`;
              return (
                <MenuItem
                  active={modifiers.active}
                  label=<div className="data-field-label-tag-container">
                    <Tag
                      style={{
                        backgroundColor:
                          field.table === "Patient" ? "#5642A6" : "#008075",
                      }}
                    >
                      {field.table === "Patient" ? "Patient" : "Sample"}
                    </Tag>
                    <Tag
                      style={{
                        backgroundColor: groupColor(field.group),
                      }}
                    >
                      {field.group}
                    </Tag>
                    <Tag
                      style={{
                        backgroundColor: "#8A9BA8",
                      }}
                    >
                      <Icon
                        icon={
                          field.class === "float" || field.class === "int"
                            ? "numerical"
                            : "layers"
                        }
                      />
                    </Tag>
                  </div>
                  key={field.field}
                  onClick={handleClick}
                  text={text}
                />
              );
            }}
            items={allFields}
            onItemSelect={(field) => selectDataField(field.field)}
            popoverProps={{
              className: "data-field-select",
              minimal: true,
              usePortal: false,
            }}
          >
            <Button
              icon="variable"
              fill
              rightIcon="caret-down"
              text={
                getSelectedField(allFields, selectedField) !== undefined
                  ? getSelectedField(allFields, selectedField).name
                  : "No Selection"
              }
            />
          </Select>
          <HTMLSelect
            disabled={
              getSelectedField(allFields, selectedField) !== undefined
                ? !(
                    getSelectedField(allFields, selectedField).class ===
                      "int" ||
                    getSelectedField(allFields, selectedField).class === "float"
                  )
                : true
            }
            options={[
              { label: "Linear", value: "linear" },
              { label: "Log", value: "log" },
              { label: "NA", value: "-", disabled: true },
            ]}
            onChange={(e) => selectScale(e.target.value)}
            value={
              getSelectedField(allFields, selectedField) !== undefined
                ? getSelectedField(allFields, selectedField).class === "int" ||
                  getSelectedField(allFields, selectedField).class === "float"
                  ? scale
                  : "-"
                : "-"
            }
          />
        </ControlGroup>
      </div>
    </Tooltip>
  </div>
);

DataFieldPicker.propTypes = {
  firstOrSecond: PropTypes.string.isRequired,
  allFields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      table: PropTypes.string.isRequired,
      group: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedField: PropTypes.string.isRequired,
  scale: PropTypes.string.isRequired,
  tooltip: PropTypes.object.isRequired,
  selectDataField: PropTypes.func.isRequired,
  selectScale: PropTypes.func.isRequired,
};

export default DataFieldPicker;
