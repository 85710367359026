import React from "react";
import PropTypes from "prop-types";
import { Alignment, Icon, Radio, RadioGroup, Tooltip } from "@blueprintjs/core";
import LabelWithInfo from "./LabelWithInfo";

const SourcePicker = ({ source, sourceOptions, tooltip, selectSource }) => (
  <div className="source-picker-container">
    <Tooltip {...tooltip}>
      <div>
        <LabelWithInfo
          title="Cohort Stratification"
          body={[
            "This parameter determines the how the pool of patients and samples, provided by the selected cohorts, are used in the plot.",
            "There are currently two options: a single union of all selected cohorts' patients and samples or a variable number of mutually exclusive partitions based on selected cohorts' overlap.",
          ]}
        />
        <RadioGroup
          className="source-picker"
          onChange={(e) => selectSource(e.target.value)}
          selectedValue={source}
        >
          <Radio
            label=<span>
              <Icon icon="resolve" /> Single Union
            </span>
            value="saved-cohorts-union"
            alignIndicator={Alignment.RIGHT}
            disabled={!sourceOptions.includes("saved-cohorts-union")}
          />
          <Radio
            label=<span>
              <Icon icon="right-join" /> Mutually Exclusive Partitions
            </span>
            value="saved-cohorts-intersection"
            alignIndicator={Alignment.RIGHT}
            disabled={!sourceOptions.includes("saved-cohorts-intersection")}
          />
        </RadioGroup>
      </div>
    </Tooltip>
  </div>
);

SourcePicker.propTypes = {
  source: PropTypes.string.isRequired,
  sourceOptions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  tooltip: PropTypes.object.isRequired,
  selectSource: PropTypes.func.isRequired,
};

export default SourcePicker;
