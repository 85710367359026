import React from "react";
import PropTypes from "prop-types";
import {
  Callout,
  Icon,
  Label,
  Popover,
  PopoverInteractionKind,
} from "@blueprintjs/core";

const LabelWithInfo = ({ title, body }) => (
  <Label className="with-info-popover">
    <b>{title}</b>{" "}
    <Popover
      content=<div className="info-container">
        <Callout icon="info-sign" title={title}>
          <div className="callout-body">
            {body.map((t, i) => (
              <p key={i.toString()}>{t}</p>
            ))}
          </div>
        </Callout>
      </div>
      interactionKind={PopoverInteractionKind.HOVER}
    >
      <Icon icon="info-sign" />
    </Popover>
  </Label>
);

LabelWithInfo.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
};

export default LabelWithInfo;
