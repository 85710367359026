import {
  EndpointMapping,
  EndpointFieldMapping,
  EndpointTextMapping,
} from "../constants";
import { getSelectedCohortsWithIds } from "../util";
import { setTimerId, newToast } from "./index";

const requestData = (dataType) => ({
  type: "REQUEST_DATA",
  dataType,
});

const receiveData = (dataType, json) => ({
  type: "RECEIVE_DATA",
  dataType,
  data: json,
  receivedAt: Date.now(),
});

export const updateSession = () => (dispatch, getState) => {
  const state = getState();
  return fetch(EndpointMapping.UPDATE_SESSION, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      sessionId: state.session.id,
      cohort: { ...state.cohort },
      distribution: { ...state.distribution },
      correlation: { ...state.correlation },
      mutation: { ...state.mutation },
      expression: { ...state.expression },
      volcano: { ...state.volcano },
      hla: { ...state.hla },
      survival: { ...state.survival },
      task: { ...state.task },
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response;
    })
    .then((response) => response.json())
    .then((_unused) => {
      // dispatch(receiveSessionId(json));
    })
    .catch((_unused) => {
      dispatch(
        newToast({
          icon: "error",
          intent: "danger",
          message: `There was an error updating the session.`,
        })
      );
    });
};

export const storeSession = () => (dispatch, getState) => {
  const state = getState();
  return fetch(EndpointMapping.STORE_SESSION, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      sessionId: state.session.id,
      cohort: { ...state.cohort },
      distribution: { ...state.distribution },
      correlation: { ...state.correlation },
      mutation: { ...state.mutation },
      expression: { ...state.expression },
      volcano: { ...state.volcano },
      hla: { ...state.hla },
      survival: { ...state.survival },
      task: { ...state.task },
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      if (json.error !== undefined && json.error.length > 0) {
        dispatch(
          newToast({
            icon: "error",
            intent: "danger",
            message: `There was an error storing the session: ${json.error}`,
          })
        );
      }
    })
    .catch((_unused) => {
      dispatch(
        newToast({
          icon: "error",
          intent: "danger",
          message: `There was an error storing the session.`,
        })
      );
    });
};

export const cancelTask = () => (dispatch, getState) => {
  const state = getState();
  const { task } = state;
  const { taskId, status, timerId } = task;

  // Ingore if there is no running task
  if (status !== "STARTED" || taskId === "") {
    return Promise.resolve("Ignoring cancelTask.");
  }

  let res;
  return fetch(`revoke/${taskId}`, { method: "GET" })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      res = json;
      if ("status" in json) {
        // When the task is finished, clean it up
        if (json.status === "CANCELLED") {
          clearInterval(timerId);
          dispatch(setTimerId(0));
          dispatch(
            newToast({
              icon: "tick-circle",
              intent: "primary",
              message: json.message,
            })
          );
        } else if (json.status === "UNRECOGNIZED") {
          dispatch(
            newToast({
              icon: "tick-circle",
              intent: "warning",
              message: json.message,
            })
          );
        }
        // Update the status if something new came in
        if (json.taskId !== taskId || json.status !== status) {
          dispatch(updateSession());
        }
      }
      dispatch(receiveData("batchCorrectedExpression", res));
    })
    .catch((_unused) => {
      dispatch(
        newToast({
          icon: "error",
          intent: "danger",
          message: `There was an error receiving data from the Status endpoint.`,
        })
      );
    });
};

export const checkStatus = () => (dispatch, getState) => {
  const state = getState();
  const { task } = state;
  const { taskId, status, timerId } = task;
  /*
   * Case: existing session has successful task that finished.
   */
  if (status === "SUCCESS" && timerId === 0) {
    // do nothing so that the data is fetched one time
    // TODO: what if the data is not cached
  } else if (status === "STARTED" && timerId === 0) {
    /*
     * Case: existing session has task still running.
     */
    const newTimerId = setInterval(() => checkStatus(), 2000);
    setTimerId(newTimerId);
    dispatch(updateSession()); // make sure newTimerId gets into the session
  } else if (status !== "STARTED") {
    /*
     * Stop the timer/interval and clear the timer state
     * if the task is not STARTED (of if SUCCESS).
     */
    clearInterval(timerId);
    dispatch(setTimerId(0));
    return Promise.resolve("Clearing interval.");
  }

  let res;
  return fetch(`status/${taskId}`, { method: "GET" })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      res = json;
      if ("status" in json) {
        // Toast message if it is new
        if (json.status === "STARTED" && json.message !== task.message) {
          dispatch(
            newToast({
              icon: "",
              intent: "primary",
              message: json.message,
            })
          );
        }
        // When the task is finished, clean it up
        if (json.status === "SUCCESS") {
          clearInterval(timerId);
          dispatch(setTimerId(0));
          dispatch(
            newToast({
              icon: "tick-circle",
              intent: "success",
              message: json.message,
            })
          );
        }
        // Update the status if something new came in
        if (json.taskId !== taskId || json.status !== status) {
          dispatch(updateSession());
        }
      }
      dispatch(receiveData("batchCorrectedExpression", res));
    })
    .catch((_unused) => {
      dispatch(
        newToast({
          icon: "error",
          intent: "danger",
          message: `There was an error receiving data from the Status endpoint.`,
        })
      );
    });
};

const fetchDataFromEndpoint = (endpoint) => (dispatch, getState) => {
  let requestBody;
  const state = getState();
  const cohorts = getSelectedCohortsWithIds(state);
  switch (endpoint) {
    case EndpointMapping.CORRELATION:
      requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cohorts,
          source: state.correlation.present.source,
          firstDataField: state.correlation.present.firstDataField,
          secondDataField: state.correlation.present.secondDataField,
          firstScale: state.correlation.present.firstScale,
          secondScale: state.correlation.present.secondScale,
        }),
      };
      break;
    case EndpointMapping.DISTRIBUTION:
      requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cohorts,
          source: state.distribution.present.source,
          dataField: state.distribution.present.dataField,
          scale: state.distribution.present.scale,
        }),
      };
      break;
    case EndpointMapping.MUTATION:
      requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cohorts,
          source: state.mutation.present.source,
          genes: state.mutation.present.geneList,
          dataFields: state.mutation.present.dataFields,
          sortBy: state.mutation.present.sortBy,
          selectedGene: state.mutation.present.selectedGene,
          selectedDataField: state.mutation.present.selectedDataField,
          dataFieldsWithLogScale: state.mutation.present.dataFieldsWithLogScale,
        }),
      };
      break;
    case EndpointMapping.BATCH_CORRECTION:
      requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cohorts,
          genes: state.volcano.present.geneList,
          doShrinkFoldChange: state.volcano.present.doShrinkFoldChange,
        }),
      };
      break;
    case EndpointMapping.EXPRESSION:
      requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cohorts,
          source: state.expression.present.source,
          genes: state.expression.present.geneList,
          dataFields: state.expression.present.dataFields,
          sortBy: state.expression.present.sortBy,
          selectedGene: state.expression.present.selectedGene,
          selectedDataField: state.expression.present.selectedDataField,
          scale: state.expression.present.scale,
          normalize: state.expression.present.normalize,
          dataFieldsWithLogScale:
            state.expression.present.dataFieldsWithLogScale,
        }),
      };
      break;
    case EndpointMapping.HLA_ZYGOSITY_DENSITY:
    case EndpointMapping.HLA_ALLELE_PAIR_MATRIX:
      requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cohorts,
        }),
      };
      break;
    case EndpointMapping.SURVIVAL:
      requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cohorts,
          useConfidenceInterval: state.survival.present.useConfidenceInterval,
          confidenceInterval: state.survival.present.confidenceInterval,
          logrankWeighting: state.survival.present.logrankWeighting,
        }),
      };
      break;
    default:
      requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };
  }
  return fetch(endpoint, requestBody)
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      if ("message" in json) {
        dispatch(
          newToast({
            icon: "warning-sign",
            intent: "warning",
            message: json.message,
          })
        );
      }
      dispatch(receiveData(EndpointFieldMapping[endpoint], json));
      if (endpoint === EndpointMapping.BATCH_CORRECTION) {
        dispatch(updateSession());
      }
    })
    .catch((_unused) => {
      dispatch(
        newToast({
          icon: "error",
          intent: "danger",
          message: `There was an error receiving data from the ${EndpointTextMapping[endpoint]} endpoint.`,
        })
      );
    });
};

export const fetchData = (endpoints) => (dispatch) => {
  endpoints.forEach((endpoint) => {
    dispatch(requestData(EndpointFieldMapping[endpoint]));
  });
  return Promise.all(
    endpoints.map((endpoint) => dispatch(fetchDataFromEndpoint(endpoint)))
  );
};

const receiveSession = (json) => ({
  type: "RECEIVE_SESSION",
  data: json,
  receivedAt: Date.now(),
});

const receiveSessionId = (json) => ({
  type: "RECEIVE_SESSION_ID",
  data: json,
  receivedAt: Date.now(),
});

export const fetchSession = () => (dispatch, getState) => {
  const state = getState();
  return fetch(EndpointMapping.FETCH_SESSION, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      sessionId: state.session.id,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveSession(json));
      if (json.error_message === "is_not_chrome") {
        dispatch(
          newToast({
            icon: "error",
            intent: "danger",
            message: `IOExplorer is intented for use on the Chrome Browser.`,
            timeout: -1,
          })
        );
      } else if (json.error_message === "is_mobile") {
        dispatch(
          newToast({
            icon: "error",
            intent: "danger",
            message: `IOExplorer is intented for use on Desktop machines.`,
            timeout: -1,
          })
        );
      }
    })
    .then(() =>
      dispatch(
        fetchData([
          EndpointMapping.DISTRIBUTION,
          EndpointMapping.CORRELATION,
          EndpointMapping.MUTATION,
          EndpointMapping.EXPRESSION,
          EndpointMapping.HLA_ZYGOSITY_DENSITY,
          EndpointMapping.HLA_ALLELE_PAIR_MATRIX,
          EndpointMapping.SURVIVAL,
        ])
      )
    )
    .then(() => {
      /*
       * Check the status of the task in 3 seconds.
       * (We wait to match sure the session is properly fetched.)
       */
      setTimeout(() => dispatch(checkStatus()), 3000);
    })
    .catch((_unused) => {
      dispatch(
        newToast({
          icon: "error",
          intent: "danger",
          message: `There was an error fetching the session.`,
        })
      );
    });
};

export const forkSession = () => (dispatch, getState) => {
  const state = getState();
  return fetch(EndpointMapping.FORK_SESSION, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      cohort: { ...state.cohort },
      distribution: { ...state.distribution },
      correlation: { ...state.correlation },
      mutation: { ...state.mutation },
      expression: { ...state.expression },
      volcano: { ...state.volcano },
      hla: { ...state.hla },
      survival: { ...state.survival },
      task: { ...state.task },
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveSessionId(json));
    })
    .catch((_unused) => {
      dispatch(
        newToast({
          icon: "error",
          intent: "danger",
          message: `There was an error forking the session.`,
        })
      );
    });
};

export const postComment = (intent, text) => (dispatch, getState) => {
  const state = getState();
  return fetch(EndpointMapping.POST_COMMENT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      intent,
      comment: text,
      url: window.location.href,
      cohort: { ...state.cohort },
      distribution: { ...state.distribution },
      correlation: { ...state.correlation },
      mutation: { ...state.mutation },
      expression: { ...state.expression },
      volcano: { ...state.volcano },
      hla: { ...state.hla },
      survival: { ...state.survival },
      task: { ...state.task },
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response;
    })
    .then((_unused) => {
      dispatch(
        newToast({
          icon: "thumbs-up",
          intent: "success",
          message: `Thank you for your feedback!.`,
        })
      );
    })
    .catch((_unused) => {
      dispatch(
        newToast({
          icon: "error",
          intent: "danger",
          message: `There was an error posting feedback.`,
        })
      );
    });
};
