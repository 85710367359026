import { defaultExpression } from "../defaultStates";

function modifySource(state, action) {
  switch (action.type) {
    case "SELECT_EXPRESSION_SOURCE":
      return action.source;
    default:
      return state.source;
  }
}

function modifyGeneList(state, action) {
  switch (action.type) {
    case "ADD_EXPRESSION_GENE":
      return state.geneList.concat([
        { id: state.geneList.length, ...action.gene },
      ]);
    case "ADD_MULTIPLE_EXPRESSION_GENES":
      return state.geneList.concat(
        action.geneList.map((gene, i) => ({
          id: state.geneList.length + i,
          ...gene,
        }))
      );
    case "REMOVE_EXPRESSION_GENE":
      return state.geneList.filter((gene) => gene.id !== action.gene.id);
    case "CLEAR_EXPRESSION_GENES":
      return [];
    default:
      return state.geneList;
  }
}

function modifyGeneSet(state, action) {
  switch (action.type) {
    case "SELECT_EXPRESSION_GENE_SET":
      return action.geneSet;
    default:
      return state.geneSet;
  }
}

function modifySortBy(state, action) {
  switch (action.type) {
    case "SELECT_EXPRESSION_SORT_BY":
      return action.sortBy;
    case "REMOVE_EXPRESSION_GENE":
      return state.sortBy === "gene" && state.selectedGene === action.gene.id
        ? "clustering"
        : state.sortBy;
    case "CLEAR_EXPRESSION_GENES":
      return state.sortBy === "gene" ? "clustering" : state.sortBy;
    case "REMOVE_EXPRESSION_DATA_FIELD":
      return state.sortBy === "dataField" &&
        state.selectedDataField === action.dataField
        ? "clustering"
        : state.sortBy;
    case "CLEAR_EXPRESSION_DATA_FIELDS":
      return state.sortBy === "dataField" ? "clustering" : state.sortBy;
    default:
      return state.sortBy;
  }
}

function modifySelectedGene(state, action) {
  switch (action.type) {
    case "REMOVE_EXPRESSION_GENE":
      return state.selectedGene === action.gene
        ? { type: "" }
        : state.selectedGene;
    case "CLEAR_EXPRESSION_GENES":
      return { type: "" };
    case "SELECT_EXPRESSION_SORT_BY_GENE":
      return action.gene;
    default:
      return state.selectedGene;
  }
}

function modifySelectedDataField(state, action) {
  switch (action.type) {
    case "REMOVE_EXPRESSION_DATA_FIELD":
      return state.selectedDataField === action.dataField
        ? ""
        : state.selectedDataField;
    case "CLEAR_EXPRESSION_DATA_FIELDS":
      return "";
    case "SELECT_EXPRESSION_SORT_BY_DATA_FIELD":
      return action.dataField;
    default:
      return state.selectedDataField;
  }
}

function modifyDataFields(state, action) {
  switch (action.type) {
    case "ADD_EXPRESSION_DATA_FIELD":
      return state.dataFields.concat(action.dataField);
    case "REMOVE_EXPRESSION_DATA_FIELD":
      return state.dataFields.filter((field) => field !== action.dataField);
    case "CLEAR_EXPRESSION_DATA_FIELDS":
      return [];
    default:
      return state.dataFields;
  }
}

function modifyScale(state, action) {
  switch (action.type) {
    case "SELECT_EXPRESSION_SCALE":
      return action.scale;
    default:
      return state.scale;
  }
}

function modifyNormalize(state, action) {
  switch (action.type) {
    case "SELECT_EXPRESSION_NORMALIZE":
      return action.normalize;
    default:
      return state.normalize;
  }
}

function modifyDataFieldsWithLogScale(state, action) {
  switch (action.type) {
    case "TOGGLE_EXPRESSION_DATA_FIELDS_WITH_LOG_SCALE":
      if (state.dataFieldsWithLogScale.includes(action.dataField)) {
        return state.dataFieldsWithLogScale.filter(
          (field) => field !== action.dataField
        );
      }
      return state.dataFieldsWithLogScale.concat([action.dataField]);
    case "REMOVE_EXPRESSION_DATA_FIELD":
      return state.dataFieldsWithLogScale.filter(
        (field) => field !== action.dataField
      );
    case "CLEAR_EXPRESSION_DATA_FIELDS":
      return [];
    default:
      return state.dataFieldsWithLogScale;
  }
}

function modifyUI(state, action) {
  switch (action.type) {
    case "SELECT_EXPRESSION_TAB_ID":
      return { ...state.ui, selectedTabId: action.id };
    default:
      return state.ui;
  }
}

const expression = (state = defaultExpression, action) => ({
  source: modifySource(state, action),
  geneList: modifyGeneList(state, action),
  geneSet: modifyGeneSet(state, action),
  sortBy: modifySortBy(state, action),
  selectedGene: modifySelectedGene(state, action),
  selectedDataField: modifySelectedDataField(state, action),
  dataFields: modifyDataFields(state, action),
  scale: modifyScale(state, action),
  normalize: modifyNormalize(state, action),
  dataFieldsWithLogScale: modifyDataFieldsWithLogScale(state, action),
  ui: modifyUI(state, action),
});

export default expression;
