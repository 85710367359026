import * as R from "ramda";
import { connect } from "react-redux";
import { matchId, uniqueIds, assignIdsToCohort } from "../../util";
import { toggleCohort, highlightCohort } from "../../actions/cohorts";
import Saved from "../../components/cohorts/Saved";

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const saved = matchId(id, state.cohort.present.saved);

  const { name, isSelected } = saved;

  const treatmentTable = R.path(["dataFetch", "treatments", "data"], state);
  const dnaCountsTable = R.path(["dataFetch", "dnaCounts", "data"], state);
  const rnaCountsTable = R.path(["dataFetch", "rnaCounts", "data"], state);

  const { patientIds, sampleIds } = assignIdsToCohort(saved, treatmentTable);

  const sampleIdsSet = new Set(sampleIds);
  const { sampleIds: dnaSampleIds } = uniqueIds(
    dnaCountsTable.filter((r) => sampleIdsSet.has(r.sampleId))
  );
  const { sampleIds: rnaSampleIds } = uniqueIds(
    rnaCountsTable.filter((r) => sampleIdsSet.has(r.sampleId))
  );

  return {
    id,
    name,
    isSelected,
    counts: {
      allPatients: patientIds.length,
      allSamples: sampleIds.length,
      visiblePatients: patientIds.length,
      visibleSamples: sampleIds.length,
      dnaSamples: dnaSampleIds.length,
      rnaSamples: rnaSampleIds.length,
    },
    isFetchingTreatment: false,
    isFetchingDNACounts: false,
    isFetchingRNACounts: false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (id) => dispatch(toggleCohort(id)),
  highlight: (id) => dispatch(highlightCohort(id)),
  hide: () => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(Saved);
