import { connect } from "react-redux";
import {
  toggleConfidenceInterval,
  selectConfidenceInterval,
} from "../../actions";
import ConfidenceIntervalPicker from "../../components/survival/ConfidenceIntervalPicker";

const mapStateToProps = (state) => ({
  useConfidenceInterval: state.survival.present.useConfidenceInterval,
  confidenceInterval: state.survival.present.confidenceInterval,
  tooltip: {},
});

const mapDispatchToProps = (dispatch) => ({
  toggleConfidenceInterval: () => dispatch(toggleConfidenceInterval()),
  selectConfidenceInterval: (v) => dispatch(selectConfidenceInterval(v)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfidenceIntervalPicker);
