import * as R from "ramda";
import { newToast } from "../actions";
import { getSelectedCohortsWithIds } from "../util";

const plotLoadingMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const nextState = store.getState();
  switch (action.type) {
    case "TOGGLE_PUBLISHED":
    case "TOGGLE_COHORT":
    case "SELECT_ALL_PUBLISHED_COHORTS":
    case "SELECT_ALL_SAVED_COHORTS":
    case "START_TUTORIAL":
    case "STOP_TUTORIAL":
    case "RECEDE_TUTORIAL":
    case "ADVANCE_TUTORIAL":
    case "RESET_TUTORIAL":
    case "SKIP_TO_END_OF_TUTORIAL": {
      const cohorts = getSelectedCohortsWithIds(nextState);
      const patientIds = R.flatten(R.pluck("patientIds", cohorts));
      /* istanbul ignore next */
      const cutoff = process.env.JEST_WORKER_ID === undefined ? 5000 : 1;
      if (patientIds.length > cutoff) {
        store.dispatch(
          newToast({
            icon: "stopwatch",
            intent: "warning",
            message: `You have selected a large amount of data. Please allow some time for the plots to load.`,
          })
        );
      }
      break;
    }
    default:
      break;
  }
  return result;
};

export default plotLoadingMiddleware;
