import { defaultCorrelation } from "../defaultStates";

const correlation = (state = defaultCorrelation, action) => {
  switch (action.type) {
    case "SELECT_CORRELATION_SOURCE":
      return {
        ...state,
        source: action.source,
      };
    case "SELECT_CORRELATION_FIRST_DATA_FIELD":
      return {
        ...state,
        firstDataField: action.field,
      };
    case "SELECT_CORRELATION_SECOND_DATA_FIELD":
      return {
        ...state,
        secondDataField: action.field,
      };
    case "SELECT_CORRELATION_FIRST_DATA_FIELD_SCALE":
      return {
        ...state,
        firstScale: action.scale,
      };
    case "SELECT_CORRELATION_SECOND_DATA_FIELD_SCALE":
      return {
        ...state,
        secondScale: action.scale,
      };
    case "SWAP_CORRELATION_DATA_FIELDS":
      return {
        ...state,
        firstDataField: state.secondDataField,
        secondDataField: state.firstDataField,
        firstScale: state.secondScale,
        secondScale: state.firstScale,
      };
    case "SELECT_CORRELATION_TAB_ID":
      return { ...state, ui: { selectedTabId: action.id } };
    default:
      return state;
  }
};

export default correlation;
