import { defaultHLA } from "../defaultStates";

const hla = (state = defaultHLA, action) => {
  switch (action.type) {
    case "SELECT_HLA_PLOT_TAB_ID":
      return { ...state, ui: { ...state.ui, plotTabId: action.id } };
    case "SELECT_HLA_TAB_ID":
      return { ...state, ui: { ...state.ui, selectedTabId: action.id } };
    default:
      return state;
  }
};

export default hla;
