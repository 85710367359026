import * as R from "ramda";
import { connect } from "react-redux";
import {
  selectTreatmentTreeNode,
  expandTreatmentTreeNode,
  collapseTreatmentTreeNode,
  insertTreatmentTreeNode,
  removeTreatmentTreeNode,
  clearTreatmentTreeNodes,
  replaceTreatmentTreeNodes,
} from "../../actions/cohorts";
import TreatmentFilter from "../../components/cohorts/TreatmentFilter";

const isAnyNodeSelected = (root) => {
  if (root !== undefined) {
    if (root.isSelected) {
      return true;
    }
    // Check if any nodes in the next list are selected.
    return R.any(isAnyNodeSelected)(root.childNodes);
  }
  return undefined;
};

const isLeafNodeSelected = (root) => {
  if (root !== undefined) {
    if (root.isSelected) {
      return !root.hasCaret;
    }
    // Check if any nodes in the next list are selected.
    return R.any(isLeafNodeSelected)(root.childNodes);
  }
  return undefined;
};

const mapStateToProps = (state, ownProps) => {
  const { tree, disable } = ownProps;
  return {
    nodes: tree,
    disableAll: disable,
    isAnyNodeSelected: R.any(isAnyNodeSelected)(tree),
    isLeafNodeSelected: R.any(isLeafNodeSelected)(tree),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { id: cohortId } = ownProps;
  return {
    selectNode: (nodeId) => dispatch(selectTreatmentTreeNode(cohortId, nodeId)),
    expandNode: (nodeId) => dispatch(expandTreatmentTreeNode(cohortId, nodeId)),
    collapseNode: (nodeId) =>
      dispatch(collapseTreatmentTreeNode(cohortId, nodeId)),
    insertNode: (op, status, target, icon, isLeaf) =>
      dispatch(
        insertTreatmentTreeNode(cohortId, op, status, target, icon, isLeaf)
      ),
    replaceNodes: (preset) =>
      dispatch(replaceTreatmentTreeNodes(cohortId, preset)),
    removeNode: () => dispatch(removeTreatmentTreeNode(cohortId)),
    clearNodes: () => dispatch(clearTreatmentTreeNodes(cohortId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentFilter);
