import { connect } from "react-redux";
import {
  selectMutationSortBy,
  selectMutationSortByGene,
  selectMutationSortByDataField,
} from "../../actions";
import MutationSortBySelect from "../../components/mutation/SortBySelect";

const mapStateToProps = (state) => ({
  geneList: state.mutation.present.geneList,
  dataFields: state.mutation.present.dataFields,
  sortBy: state.mutation.present.sortBy,
  selectedGene: state.mutation.present.selectedGene,
  allFields: state.dataFetch.allDataFields.data,
  selectedDataField: state.mutation.present.selectedDataField,
});

const mapDispatchToProps = (dispatch) => ({
  selectGene: (gene) => dispatch(selectMutationSortByGene(gene)),
  selectDataField: (dataField) =>
    dispatch(selectMutationSortByDataField(dataField)),
  selectSortBy: (sortBy) => dispatch(selectMutationSortBy(sortBy)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MutationSortBySelect);
