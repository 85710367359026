import * as R from "ramda";
import {
  EndpointFieldMapping,
  EndpointMapping,
  treatmentStatusMapping,
  treatmentTargetMapping,
  presetTreatmentTreeMapping,
} from "../constants";

function modifyNextId(state, action) {
  switch (action.type) {
    case "SAVE_COHORT":
      return state.nextId + 1;
    default:
      return state.nextId;
  }
}

const maxArrayNodes = (a) => R.reduce(R.max, -1, R.pluck("id", a));

const findHighestId = (nodes) => {
  if (nodes !== undefined) {
    if (nodes.length === 0) {
      return 0;
    }
    return R.max(
      maxArrayNodes(nodes),
      findHighestId(R.flatten(R.pluck("childNodes", nodes)))
    );
  }
  return undefined;
};

const changeOneNode = (nodes, id, prop, value) => {
  if (nodes !== undefined) {
    return nodes.map((node, _unused) => {
      if (node.id === id) {
        return {
          ...node,
          [prop]: value,
        };
      }
      return {
        ...node,
        childNodes: changeOneNode(node.childNodes, id, prop, value),
      };
    });
  }
  return undefined;
};

const changeAllNodes = (nodes, prop, value) => {
  if (nodes !== undefined) {
    return nodes.map((node, _unused) => ({
      ...node,
      [prop]: value,
      childNodes: changeAllNodes(node.childNodes, prop, value),
    }));
  }
  return undefined;
};

const insertNode = (nodes, nextId, op, status, target, icon, isLeaf) => {
  if (nodes !== undefined) {
    if (nodes.length === 0) {
      return [
        {
          childNodes: [],
          disabled: false,
          icon,
          id: nextId,
          hasCaret: !isLeaf,
          isExpanded: true,
          isSelected: true,
          label:
            op !== undefined
              ? op
              : `${treatmentStatusMapping[status]} ${treatmentTargetMapping[target]}`,
          nodeData: op !== undefined ? "op" : { status, target },
        },
      ];
    }
    return nodes.map((node, _unused) => {
      if (node.isSelected) {
        return {
          ...node,
          childNodes: node.childNodes.concat({
            childNodes: [],
            disabled: false,
            icon,
            id: nextId,
            hasCaret: !isLeaf,
            isSelected: false,
            label:
              op !== undefined
                ? op
                : `${treatmentStatusMapping[status]} ${treatmentTargetMapping[target]}`,
            nodeData: op !== undefined ? "op" : { status, target },
          }),
        };
      }
      if (node.childNodes.length > 0) {
        return {
          ...node,
          childNodes: insertNode(
            node.childNodes,
            nextId,
            op,
            status,
            target,
            icon,
            isLeaf
          ),
        };
      }
      return node;
    });
  }
  return undefined;
};

const removeNode = (nodes, id) => {
  if (nodes !== undefined) {
    return nodes
      .filter((node) => !node.isSelected)
      .map((node, _unused) => ({
        ...node,
        childNodes:
          node.childNodes.length > 0 ? removeNode(node.childNodes, id) : [],
      }));
  }
  return undefined;
};

const handleNodeSelect = (nodes, id) => {
  let newNodes;
  newNodes = changeAllNodes(nodes, "isSelected", false);
  newNodes = changeOneNode(newNodes, id, "isSelected", true);
  newNodes = changeOneNode(newNodes, id, "isExpanded", true);
  return newNodes;
};

const handleNodeCollapse = (nodes, id) => {
  return changeOneNode(nodes, id, "isExpanded", false);
};

const handleNodeExpand = (nodes, id) => {
  return changeOneNode(nodes, id, "isExpanded", true);
};

function modifyPublished(state, action) {
  switch (action.type) {
    case "TOGGLE_PUBLISHED":
      return state.published.map((d) =>
        d.name === action.name ? { ...d, isSelected: !d.isSelected } : d
      );
    case "SELECT_TREATMENT_TREE_NODE":
      return state.published.map((p) =>
        p.id === action.cohortId
          ? {
              ...p,
              mapping: {
                ...p.mapping,
                treatmentTree: handleNodeSelect(
                  p.mapping.treatmentTree,
                  action.nodeId
                ),
              },
            }
          : p
      );
    case "EXPAND_TREATMENT_TREE_NODE":
      return state.published.map((p) =>
        p.id === action.cohortId
          ? {
              ...p,
              mapping: {
                ...p.mapping,
                treatmentTree: handleNodeExpand(
                  p.mapping.treatmentTree,
                  action.nodeId
                ),
              },
            }
          : p
      );
    case "COLLAPSE_TREATMENT_TREE_NODE":
      return state.published.map((p) =>
        p.id === action.cohortId
          ? {
              ...p,
              mapping: {
                ...p.mapping,
                treatmentTree: handleNodeCollapse(
                  p.mapping.treatmentTree,
                  action.nodeId
                ),
              },
            }
          : p
      );
    case "INSERT_TREATMENT_TREE_NODE":
      return state.published.map((p) =>
        p.id === action.cohortId
          ? {
              ...p,
              mapping: {
                ...p.mapping,
                treatmentTree: insertNode(
                  p.mapping.treatmentTree,
                  findHighestId(p.mapping.treatmentTree) + 1,
                  action.op,
                  action.status,
                  action.target,
                  action.icon,
                  action.isLeaf
                ),
              },
            }
          : p
      );
    case "REMOVE_TREATMENT_TREE_NODE":
      return state.published.map((p) =>
        p.id === action.cohortId
          ? {
              ...p,
              mapping: {
                ...p.mapping,
                treatmentTree: removeNode(
                  p.mapping.treatmentTree,
                  action.nodeId
                ),
              },
            }
          : p
      );
    case "CLEAR_TREATMENT_TREE_NODES":
      return state.published.map((p) =>
        p.id === action.cohortId
          ? { ...p, mapping: { ...p.mapping, treatmentTree: [] } }
          : p
      );
    case "REPLACE_TREATMENT_TREE_NODES":
      return state.published.map((p) =>
        p.id === action.cohortId
          ? {
              ...p,
              mapping: {
                ...p.mapping,
                treatmentTree: presetTreatmentTreeMapping[action.preset],
              },
            }
          : p
      );
    case "TOGGLE_APPLY_TO_PATIENTS":
      return state.published.map((d) =>
        d.id === action.id
          ? {
              ...d,
              mapping: {
                ...d.mapping,
                applyToPatients: !d.mapping.applyToPatients,
              },
            }
          : d
      );
    case "SELECT_ALL_PUBLISHED_COHORTS":
      return state.published.map((c) => ({ ...c, isSelected: true }));
    case "CLEAR_COHORT_SELECTION":
      return state.published.map((c) => ({ ...c, isSelected: false }));
    case "HIGHLIGHT_COHORT":
      return state.published.map((c) =>
        c.id === action.id
          ? { ...c, isHighlighted: true }
          : { ...c, isHighlighted: false }
      );
    case "CLEAR_HIGHLIGHTED_COHORT":
      return state.published.map((c) => ({ ...c, isHighlighted: false }));
    default:
      return state.published;
  }
}

const instantiateCohort = (
  id,
  name,
  isPublished,
  source,
  tags,
  patientIds,
  sampleIds
) => ({
  id,
  name,
  mapping: {
    treatmentTree: isPublished ? presetTreatmentTreeMapping[name] : [],
    applyToPatients: true,
    useFixedSamples: sampleIds.length !== 0,
  },
  patientIds: R.uniq(patientIds),
  sampleIds: R.uniq(sampleIds),
  source,
  tags,
  isSelected: false,
  isPublished,
  isHidden: false,
  isHighlighted: false,
});

function modifyPending(state, action) {
  const { source, tags, patientIds, sampleIds } = action;
  switch (action.type) {
    case "SAVE_COHORT":
      return [
        ...state.pending,
        instantiateCohort(
          `Saved_Cohort_${state.nextId}`,
          `Saved Cohort #${state.nextId}`,
          false,
          source,
          tags,
          patientIds,
          sampleIds
        ),
      ];
    case "REMOVE_PENDING_COHORTS":
    case "CONFIRM_COHORTS":
      return state.pending.filter((c) => !action.cohortIds.includes(c.id));
    case "RENAME_PENDING_COHORT":
      return state.pending.map((c) =>
        c.id === action.cohortId ? { ...c, name: action.name } : c
      );
    default:
      return state.pending;
  }
}

function modifySaved(state, action) {
  switch (action.type) {
    case "TOGGLE_COHORT":
      return state.saved.map((s) =>
        s.id === action.id ? { ...s, isSelected: !s.isSelected } : s
      );
    case "CONFIRM_COHORTS":
      return state.saved.concat(
        state.pending.filter((pc) => action.cohortIds.includes(pc.id))
      );
    case "RENAME_COHORT":
      return state.saved.map((c) =>
        c.id === action.id
          ? { ...c, name: action.name !== "" ? action.name : `cohort${c.id}` }
          : c
      );
    case "REMOVE_COHORT":
      return state.saved.filter((c) => c.id !== action.id);
    case "SELECT_TREATMENT_TREE_NODE":
      return state.saved.map((c) =>
        c.id === action.cohortId
          ? {
              ...c,
              mapping: {
                ...c.mapping,
                treatmentTree: handleNodeSelect(
                  c.mapping.treatmentTree,
                  action.nodeId
                ),
              },
            }
          : c
      );
    case "EXPAND_TREATMENT_TREE_NODE":
      return state.saved.map((c) =>
        c.id === action.cohortId
          ? {
              ...c,
              mapping: {
                ...c.mapping,
                treatmentTree: handleNodeExpand(
                  c.mapping.treatmentTree,
                  action.nodeId
                ),
              },
            }
          : c
      );
    case "COLLAPSE_TREATMENT_TREE_NODE":
      return state.saved.map((c) =>
        c.id === action.cohortId
          ? {
              ...c,
              mapping: {
                ...c.mapping,
                treatmentTree: handleNodeCollapse(
                  c.mapping.treatmentTree,
                  action.nodeId
                ),
              },
            }
          : c
      );
    case "INSERT_TREATMENT_TREE_NODE":
      return state.saved.map((c) =>
        c.id === action.cohortId
          ? {
              ...c,
              mapping: {
                ...c.mapping,
                treatmentTree: insertNode(
                  c.mapping.treatmentTree,
                  findHighestId(c.mapping.treatmentTree) + 1,
                  action.op,
                  action.status,
                  action.target,
                  action.icon,
                  action.isLeaf
                ),
              },
            }
          : c
      );
    case "REMOVE_TREATMENT_TREE_NODE":
      return state.saved.map((c) =>
        c.id === action.cohortId
          ? {
              ...c,
              mapping: {
                ...c.mapping,
                treatmentTree: removeNode(
                  c.mapping.treatmentTree,
                  action.nodeId
                ),
              },
            }
          : c
      );
    case "CLEAR_TREATMENT_TREE_NODES":
      return state.saved.map((c) =>
        c.id === action.cohortId
          ? { ...c, mapping: { ...c.mapping, treatmentTree: [] } }
          : c
      );
    case "REPLACE_TREATMENT_TREE_NODES":
      return state.saved.map((p) =>
        p.id === action.cohortId
          ? {
              ...p,
              mapping: {
                ...p.mapping,
                treatmentTree: presetTreatmentTreeMapping[action.preset],
              },
            }
          : p
      );
    case "TOGGLE_APPLY_TO_PATIENTS":
      return state.saved.map((c) =>
        c.id === action.id
          ? {
              ...c,
              mapping: {
                ...c.mapping,
                applyToPatients: !c.mapping.applyToPatients,
              },
            }
          : c
      );
    case "TOGGLE_USE_FIXED_SAMPLES":
      return state.saved.map((c) =>
        c.id === action.id
          ? {
              ...c,
              mapping: {
                ...c.mapping,
                useFixedSamples: !c.mapping.useFixedSamples,
              },
            }
          : c
      );
    case "SELECT_ALL_SAVED_COHORTS":
      return state.saved.map((c) => ({ ...c, isSelected: true }));
    case "CLEAR_COHORT_SELECTION":
      return state.saved.map((c) => ({ ...c, isSelected: false }));
    case "HIGHLIGHT_COHORT":
      return state.saved.map((c) =>
        c.id === action.id
          ? { ...c, isHighlighted: true }
          : { ...c, isHighlighted: false }
      );
    case "CLEAR_HIGHLIGHTED_COHORT":
      return state.saved.map((c) => ({ ...c, isHighlighted: false }));
    default:
      return state.saved;
  }
}

const instantiatePublishedCohort = (name) =>
  instantiateCohort(name, name, true, "Published", [], [], []);

const cohort = (
  state = {
    nextId: 0,
    published: [],
    pending: [],
    saved: [],
  },
  action
) => {
  const map = new Map();
  // Handle a few special cases with published cohorts
  switch (action.type) {
    case "RECEIVE_DATA":
      if (action.dataType === EndpointFieldMapping[EndpointMapping.DATASETS]) {
        /*
         * The datasets endpoint returns much of the relevant data
         * for a particular dataset. This must play nicely with the
         * cohort state that the app is managing for the user.
         *
         * Here, if a dataset is not in the session's list of published
         * cohorts, it is added with default parameters. If a dataset
         * already exists in the list of published cohorts,
         * it is not affected.
         */
        const datasetNames = R.pluck("name", action.data);
        const publishedCohortNames = R.pluck("name", state.published);
        const keepNewNames = R.filter(
          (name) => !R.includes(name, publishedCohortNames)
        );
        const instantiatePublishedCohorts = R.map(instantiatePublishedCohort);
        const instantiateNewPublishedCohorts = R.pipe(
          keepNewNames,
          instantiatePublishedCohorts
        );
        return {
          ...state,
          published: R.concat(
            state.published,
            instantiateNewPublishedCohorts(datasetNames)
          ),
        };
      }
      return state;
    case "RECEIVE_SESSION":
      /*
       * The session holds the cohort state on behalf of the user.
       * Therefore, it must be preserved even
       * even if the underlying list of published cohorts has changed.
       */
      state.published.forEach((item) => map.set(item.id, item));
      action.data.cohort.published.forEach((item) =>
        map.set(item.id, { ...map.get(item.id), ...item })
      );
      return {
        ...action.data.cohort,
        published: Array.from(map.values()),
      };
    default:
      return {
        nextId: modifyNextId(state, action),
        published: modifyPublished(state, action),
        pending: modifyPending(state, action),
        saved: modifySaved(state, action),
      };
  }
};

export default cohort;
