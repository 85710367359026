import React from "react";
import PropTypes from "prop-types";
import { Alignment, Slider, Switch, Tooltip } from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";

const SourcePicker = ({
  useConfidenceInterval,
  confidenceInterval,
  tooltip,
  toggleConfidenceInterval,
  selectConfidenceInterval,
}) => (
  <div className="confidence-interval-picker-container">
    <Tooltip {...tooltip}>
      <div>
        <LabelWithInfo
          title="Confidence Interval"
          body={[
            "The Kaplan-Meier model uses observed survival events to estimate a survival function (or survival curve). The Confidence Interval (CI) parameter modulates the confidence level that the estimated survival function matches the true survival function for a given population.",
            "Use the switch to toggle whether the upper and lower CI endpoints are displayed on the plot; use the slider to set the CI.",
          ]}
        />
        <Switch
          alignIndicator={Alignment.RIGHT}
          checked={useConfidenceInterval}
          label="Display Confidence Interval"
          onChange={() => toggleConfidenceInterval()}
        />
        <Slider
          disabled={!useConfidenceInterval}
          labelRenderer={(value) => {
            if (value === 1) {
              return "0.95";
            }
            if (value === 2) {
              return "0.99";
            }
            if (value === 3) {
              return "0.995";
            }
            if (value === 4) {
              return "0.999";
            }
            return "";
          }}
          max={4}
          min={1}
          onChange={(value) => {
            if (value === 1) {
              selectConfidenceInterval("0.95");
            } else if (value === 2) {
              selectConfidenceInterval("0.99");
            } else if (value === 3) {
              selectConfidenceInterval("0.995");
            } else {
              selectConfidenceInterval("0.999");
            }
          }}
          value={
            confidenceInterval === "0.95"
              ? 1
              : confidenceInterval === "0.99"
              ? 2
              : confidenceInterval === "0.995"
              ? 3
              : 4
          }
        />
      </div>
    </Tooltip>
  </div>
);

SourcePicker.propTypes = {
  useConfidenceInterval: PropTypes.bool.isRequired,
  confidenceInterval: PropTypes.string.isRequired,
  tooltip: PropTypes.object.isRequired,
  toggleConfidenceInterval: PropTypes.func.isRequired,
  selectConfidenceInterval: PropTypes.func.isRequired,
};

export default SourcePicker;
