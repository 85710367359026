import React from "react";
import PropTypes from "prop-types";
import { Alignment, Radio, RadioGroup, Tooltip } from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";

const LogrankWeightingPicker = ({ weighting, tooltip, select }) => (
  <div className="logrank-weighting-container">
    <Tooltip {...tooltip}>
      <div>
        <LabelWithInfo title="Logrank Weighting" body={[]} />
        <RadioGroup
          onChange={(e) => select(e.target.value)}
          selectedValue={weighting}
        >
          <Radio
            label=<span>Wilcoxon (Breslow)</span>
            value="wilcoxon"
            alignIndicator={Alignment.RIGHT}
          />
          <Radio
            label=<span>Tarone-Ware</span>
            value="tarone-ware"
            alignIndicator={Alignment.RIGHT}
          />
          <Radio
            label=<span>Peto</span>
            value="peto"
            alignIndicator={Alignment.RIGHT}
          />
        </RadioGroup>
      </div>
    </Tooltip>
  </div>
);

LogrankWeightingPicker.propTypes = {
  weighting: PropTypes.string.isRequired,
  tooltip: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired,
};

export default LogrankWeightingPicker;
