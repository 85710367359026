import React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "@blueprintjs/core";
import HLAAllelePairMatrixPlot from "../../containers/hla/AllelePairMatrixPlot";
import HLAZygosityDensityPlot from "../../containers/hla/ZygosityDensityPlot";

const PlotContainer = ({
  plotTabId,
  resizePlotsTriggerProp,
  selectPlotTabId,
}) => (
  <div className="plot-container hla">
    <Tabs
      onChange={(newTabId) => selectPlotTabId(newTabId)}
      selectedTabId={plotTabId}
      renderActiveTabPanelOnly
    >
      <Tab
        id="zygosity-density"
        title="HLA Zygosity & HED Density"
        panel={
          <HLAZygosityDensityPlot
            resizePlotsTriggerProp={resizePlotsTriggerProp}
          />
        }
      />
      <Tab
        id="allele-pair-frequency-matrix"
        title="HLA Allele Pair Frequency Matrix"
        panel={
          <HLAAllelePairMatrixPlot
            resizePlotsTriggerProp={resizePlotsTriggerProp}
          />
        }
      />
      <Tabs.Expander />
    </Tabs>
  </div>
);

PlotContainer.propTypes = {
  plotTabId: PropTypes.string.isRequired,
  resizePlotsTriggerProp: PropTypes.bool.isRequired,
  selectPlotTabId: PropTypes.func.isRequired,
};

export default PlotContainer;
