import React from "react";
import PropTypes from "prop-types";
import { Radio, RadioGroup } from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";

const ShrinkFoldChangeRadio = ({
  doShrinkFoldChange,
  toggleDoShrinkFoldChange,
}) => (
  <div>
    <LabelWithInfo title="Shrink Fold Change with lfcShrink" body={[]} />
    <RadioGroup
      onChange={(_unused) => toggleDoShrinkFoldChange()}
      selectedValue={doShrinkFoldChange}
    >
      <Radio label="Shrink Fold Change" value="true" />
      <Radio label="Don't Shrink Fold Change" value="false" />
    </RadioGroup>
  </div>
);

ShrinkFoldChangeRadio.propTypes = {
  doShrinkFoldChange: PropTypes.string.isRequired,
  toggleDoShrinkFoldChange: PropTypes.func.isRequired,
};

export default ShrinkFoldChangeRadio;
