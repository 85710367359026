import { connect } from "react-redux";
import HEDMatrixPlot from "../../components/hla/HEDMatrixPlot";

const mapStateToProps = (state, { hlaLocus }) => {
  const { traces, layout: dataLayout } = state.dataFetch.staticHEDMatrix.data[
    hlaLocus
  ];
  const { isFetching } = state.dataFetch.staticHEDMatrix;
  const { revision } = state.dataFetch.staticHEDMatrix;

  const layout = {
    margin: {
      t: 25,
      b: 0,
      l: 0,
      r: 0,
    },
    // xaxis: {
    //   showgrid: false,
    //   zeroline: false,
    //   ticks: "",
    //   showticklabels: false,
    // },
    // yaxis: {
    //   showgrid: false,
    //   zeroline: false,
    //   ticks: "",
    //   showticklabels: false,
    // },
    autosize: true,
  };

  if (isFetching) {
    return {
      revision,
      data: [],
      layout: {
        margin: {
          t: 50,
          b: 50,
        },
        xaxis: {
          showgrid: false,
          zeroline: false,
          ticks: "",
          showticklabels: false,
        },
        yaxis: {
          showgrid: false,
          zeroline: false,
          ticks: "",
          showticklabels: false,
        },
        autosize: true,
        images: [
          {
            x: 0.3,
            y: 0.8,
            sizex: 0.5,
            sizey: 0.5,
            source: "https://i.ibb.co/tcrz9JW/fetching-results.png",
            xanchor: "left",
            xref: "paper",
            yanchor: "top",
            yref: "paper",
          },
        ],
      },
    };
  }
  return {
    revision,
    data: traces,
    layout: Object.assign(layout, dataLayout),
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HEDMatrixPlot);
