import * as R from "ramda";
import { connect } from "react-redux";
import { togglePublishedSampleMappingPicker } from "../../actions/ui";
import {
  toggleUseFixedSamples,
  toggleApplyToPatients,
} from "../../actions/cohorts";
import MappingPopover from "../../components/cohorts/MappingPopover";

const changeAllNodes = (nodes, prop, value) => {
  if (nodes !== undefined) {
    return nodes.map((node, _unused) => ({
      ...node,
      [prop]: value,
      childNodes: changeAllNodes(node.childNodes, prop, value),
    }));
  }
  return undefined;
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const ui = R.path(["ui", "present"], state);
  const extractById = R.find(R.propEq("id", id));
  const cohortList = R.concat(
    R.path(["cohort", "present", "published"], state),
    R.path(["cohort", "present", "saved"], state)
  );
  const cohort = extractById(cohortList);
  let treatmentTree = R.path(["mapping", "treatmentTree"], cohort);
  const useFixedSamples = R.path(["mapping", "useFixedSamples"], cohort);
  const applyToPatients = R.path(["mapping", "applyToPatients"], cohort);
  if (useFixedSamples) {
    treatmentTree = changeAllNodes(treatmentTree, "disabled", true);
    treatmentTree = changeAllNodes(treatmentTree, "isSelected", false);
  }
  return {
    id,
    treatmentTree,
    applyToPatients,
    useFixedSamples,
    hasFixedSamplesOption: cohort.sampleIds.length > 0,
    isPopoverOpen: ui.whichMappingPopoverOpen === id,
    tooltip:
      ui.isTutorialPanelOpen && state.tutorialTooltips.present !== undefined
        ? state.tutorialTooltips.present.samplesMappingButton
        : state.tutorialTooltips.saved.samplesMappingButton,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleUseFixedSamples: (id) => dispatch(toggleUseFixedSamples(id)),
  toggleApplyToPatients: (id) => dispatch(toggleApplyToPatients(id)),
  togglePopover: (n) => dispatch(togglePublishedSampleMappingPicker(n)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MappingPopover);
