import { connect } from "react-redux";
import {
  selectCorrelationFirstDataField,
  selectCorrelationFirstDataFieldScale,
} from "../../actions";
import CorrelationDataFieldPicker from "../../components/correlation/DataFieldPicker";

const mapStateToProps = (state) => ({
  firstOrSecond: "first",
  allFields: state.dataFetch.allDataFields.data,
  selectedField: state.correlation.present.firstDataField,
  scale: state.correlation.present.firstScale,
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.correlationFirstDataFieldPicker
      : state.tutorialTooltips.saved.correlationFirstDataFieldPicker,
});

const mapDispatchToProps = (dispatch) => ({
  selectDataField: (field) => dispatch(selectCorrelationFirstDataField(field)),
  selectScale: (scale) => dispatch(selectCorrelationFirstDataFieldScale(scale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrelationDataFieldPicker);
