import { connect } from "react-redux";
import { newToast } from "../actions";
import { storeSession, forkSession } from "../actions/async";
import SessionMenu from "../components/SessionMenu";
import { encodeUi, encodeTutorial } from "../util/urlParams";

const mapStateToProps = (state) => ({
  sessionId: state.session.id,
  uiURL: encodeUi(state.ui).split(" ").join("+"),
  tutorialURL: encodeTutorial(state.tutorial).split(" ").join("+"),
});

const mapDispatchToProps = (dispatch) => ({
  storeSession: () => dispatch(storeSession()),
  forkSession: (shareable) => dispatch(forkSession(shareable)),
  newToast: (toast) => dispatch(newToast(toast)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionMenu);
