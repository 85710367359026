import React from "react";
import PropTypes from "prop-types";
import { Text, Switch } from "@blueprintjs/core";
import LabelWithInfo from "./LabelWithInfo";

const getSelectedField = (allFields, selectedField) => {
  if (allFields === undefined) {
    return undefined;
  }
  return allFields.filter((field) => field.field === selectedField).shift();
};

const DataFieldScaleSelect = ({
  allFields,
  dataFields,
  dataFieldsWithLogScale,
  toggle,
}) => {
  const fullDataFields = dataFields
    .map((dataField) => getSelectedField(allFields, dataField))
    .filter((dataField) => dataField !== undefined)
    .filter((field) => field.class === "int" || field.class === "float");
  return (
    <div className="data-field-scale-container">
      <LabelWithInfo title="Data Field Scale" body={[]} />
      {fullDataFields.length !== 0 ? (
        <div>
          {fullDataFields.map((field) => (
            <div className="data-field" key={field.name}>
              <Switch
                alignIndicator="right"
                checked={dataFieldsWithLogScale.includes(field.field)}
                innerLabel="Linear"
                innerLabelChecked="Log"
                labelElement={<Text className="text">{field.name}</Text>}
                onChange={() => toggle(field.field)}
              />
            </div>
          ))}
        </div>
      ) : (
        <Text>No numerical data fields.</Text>
      )}
    </div>
  );
};

DataFieldScaleSelect.propTypes = {
  allFields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      table: PropTypes.string.isRequired,
      group: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  dataFields: PropTypes.array.isRequired,
  dataFieldsWithLogScale: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default DataFieldScaleSelect;
