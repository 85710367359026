import React from "react";
import PropTypes from "prop-types";
import { Button, Card, ProgressBar } from "@blueprintjs/core";

const Status = ({
  taskStatus,
  currentStep,
  totalSteps,
  timerId,
  cancelTask,
}) => (
  <Card
    style={{
      position: "absolute",
      bottom: "20px",
      right: "70px",
      height: "40px",
      width: "220px",
      padding: "8px",
    }}
  >
    <div
      style={{
        float: "left",
        width: "calc(100% - 32px)",
        marginTop: "8px",
      }}
    >
      <ProgressBar
        animate={totalSteps !== 0 && currentStep !== totalSteps}
        intent={
          taskStatus === "STARTED" ? "primary" : taskStatus ? "success" : ""
        }
        stripes={totalSteps !== 0 && currentStep !== totalSteps}
        value={totalSteps !== 0 ? currentStep / totalSteps : 0 / 1}
      />
    </div>
    <Button
      disabled={timerId === 0}
      icon="cross"
      minimal
      onClick={() => cancelTask()}
      small
      style={{ marginLeft: "8px" }}
    />
  </Card>
);

Status.propTypes = {
  taskStatus: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  timerId: PropTypes.number.isRequired,
  cancelTask: PropTypes.func.isRequired,
};

export default Status;
