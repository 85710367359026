import React from "react";
import PropTypes from "prop-types";

const LegendTabPanel = ({ children }) => (
  <div>{children !== undefined ? <div>{children}</div> : <div />}</div>
);

LegendTabPanel.propTypes = {
  // includePatient: PropTypes.bool,
  children: PropTypes.object,
};

LegendTabPanel.defaultProps = {
  // includePatient: true,
  children: undefined,
};

export default LegendTabPanel;
