import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Tooltip } from "@blueprintjs/core";

const CohortsSetOpMenuOpMenu = ({
  unionPatientIds,
  unionSampleIds,
  unionSource,
  unionTags,
  intersectionPatientIds,
  intersectionSampleIds,
  intersectionSource,
  intersectionTags,
  duplicatePatientIds,
  duplicateSampleIds,
  duplicateSource,
  duplicateTags,
  isUnionEnabled,
  isIntersectionEnabled,
  isDuplicateEnabled,
  duplicateButtonTooltip,
  unionButtonTooltip,
  intersectionButtonTooltip,
  union,
  intersection,
  duplicate,
}) => (
  <ButtonGroup style={{ margin: "auto 0", padding: "5px" }}>
    <Tooltip
      {...duplicateButtonTooltip}
      content="Duplicate selected cohort"
      disabled={!isDuplicateEnabled}
      usePortal={false}
    >
      <Button
        disabled={!isDuplicateEnabled}
        icon="duplicate"
        small
        text=""
        onClick={() =>
          duplicate(
            duplicatePatientIds,
            duplicateSampleIds,
            duplicateSource,
            duplicateTags
          )
        }
      />
    </Tooltip>
    <Tooltip
      {...unionButtonTooltip}
      content="Union selected cohorts"
      disabled={!isUnionEnabled}
      usePortal={false}
    >
      <Button
        disabled={!isUnionEnabled}
        icon="resolve"
        small
        text=""
        onClick={() =>
          union(unionPatientIds, unionSampleIds, unionSource, unionTags)
        }
      />
    </Tooltip>
    <Tooltip
      {...intersectionButtonTooltip}
      content="Intersect selected cohort"
      disabled={!isIntersectionEnabled}
      usePortal={false}
    >
      <Button
        disabled={!isIntersectionEnabled}
        icon="intersection"
        small
        text=""
        onClick={() =>
          intersection(
            intersectionPatientIds,
            intersectionSampleIds,
            intersectionSource,
            intersectionTags
          )
        }
      />
    </Tooltip>
  </ButtonGroup>
);

CohortsSetOpMenuOpMenu.propTypes = {
  unionPatientIds: PropTypes.array.isRequired,
  unionSampleIds: PropTypes.array.isRequired,
  unionSource: PropTypes.string.isRequired,
  unionTags: PropTypes.array.isRequired,
  intersectionPatientIds: PropTypes.array.isRequired,
  intersectionSampleIds: PropTypes.array.isRequired,
  intersectionSource: PropTypes.string.isRequired,
  intersectionTags: PropTypes.array.isRequired,
  duplicatePatientIds: PropTypes.array.isRequired,
  duplicateSampleIds: PropTypes.array.isRequired,
  duplicateSource: PropTypes.string.isRequired,
  duplicateTags: PropTypes.array.isRequired,
  isUnionEnabled: PropTypes.bool.isRequired,
  isIntersectionEnabled: PropTypes.bool.isRequired,
  isDuplicateEnabled: PropTypes.bool.isRequired,
  duplicateButtonTooltip: PropTypes.object,
  unionButtonTooltip: PropTypes.object,
  intersectionButtonTooltip: PropTypes.object,
  union: PropTypes.func.isRequired,
  intersection: PropTypes.func.isRequired,
  duplicate: PropTypes.func.isRequired,
};

CohortsSetOpMenuOpMenu.defaultProps = {
  duplicateButtonTooltip: {},
  unionButtonTooltip: {},
  intersectionButtonTooltip: {},
};

export default CohortsSetOpMenuOpMenu;
