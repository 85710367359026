import { defaultVolcano } from "../defaultStates";

const modifyIsDialogOpen = (state, action) => {
  switch (action.type) {
    case "TOGGLE_VOLCANO_DIALOG":
      return !state.isDialogOpen;
    default:
      return state.isDialogOpen;
  }
};

const modifyGeneList = (state, action) => {
  switch (action.type) {
    case "ADD_VOLCANO_GENE":
      return state.geneList.concat([
        { id: state.geneList.length, ...action.gene },
      ]);
    case "ADD_MULTIPLE_VOLCANO_GENES":
      return state.geneList.concat(
        action.geneList.map((gene, i) => ({
          id: state.geneList.length + i,
          ...gene,
        }))
      );
    case "REMOVE_VOLCANO_GENE":
      return state.geneList.filter((gene) => gene.id !== action.gene.id);
    case "CLEAR_VOLCANO_GENES":
      return [];
    default:
      return state.geneList;
  }
};

const modifyGeneSet = (state, action) => {
  switch (action.type) {
    case "SELECT_VOLCANO_GENE_SET":
      return action.geneSet;
    default:
      return state.geneSet;
  }
};

const modifyDoShrinkFoldChange = (state, action) => {
  switch (action.type) {
    case "TOGGLE_VOLCANO_DO_SHRINK_FOLD_CHANGE":
      return !state.doShrinkFoldChange;
    default:
      return state.doShrinkFoldChange;
  }
};

const modifyOptions = (state, action) => {
  switch (action.type) {
    case "SELECT_VOLCANO_COLORING":
      return { ...state.options, coloring: action.coloring };
    case "SELECT_VOLCANO_INTERACTION":
      return { ...state.options, interaction: action.interaction };
    case "SELECT_VOLCANO_PCA_DOMAIN":
      return { ...state.options, pcaDomain: action.pcaDomain };
    default:
      return state.options;
  }
};

const volcano = (state = defaultVolcano, action) => ({
  isDialogOpen: modifyIsDialogOpen(state, action),
  geneList: modifyGeneList(state, action),
  geneSet: modifyGeneSet(state, action),
  doShrinkFoldChange: modifyDoShrinkFoldChange(state, action),
  options: modifyOptions(state, action),
});

export default volcano;
