import { connect } from "react-redux";
import { toggleMutationDataFieldsWithLogScale } from "../../actions";
import MutationDataFieldScaleSelect from "../../components/DataFieldScaleSelect";

const mapStateToProps = (state) => ({
  allFields: state.dataFetch.allDataFields.data,
  dataFields: state.mutation.present.dataFields,
  dataFieldsWithLogScale: state.mutation.present.dataFieldsWithLogScale,
});

const mapDispatchToProps = (dispatch) => ({
  toggle: (dataField) =>
    dispatch(toggleMutationDataFieldsWithLogScale(dataField)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MutationDataFieldScaleSelect);
