const ui = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SELECTION_PANEL":
      return {
        ...state,
        isSelectionPanelOpen: !state.isSelectionPanelOpen,
      };
    case "TOGGLE_TUTORIAL_PANEL":
      return {
        ...state,
        isTutorialPanelOpen: !state.isTutorialPanelOpen,
      };
    case "OPEN_SELECTION_PANEL":
      return {
        ...state,
        isSelectionPanelOpen: true,
      };
    case "OPEN_TUTORIAL_PANEL":
      return {
        ...state,
        isTutorialPanelOpen: true,
      };
    case "TOGGLE_SAMPLE_MAPPING_PICKER":
      return {
        ...state,
        whichMappingPopoverOpen: action.name,
      };
    case "CLEAR_LANDING_PAGE_KEY":
      return {
        ...state,
        landingPageKey: "",
      };
    case "SET_PATHNAME":
      return {
        ...state,
        pathname: action.pathname,
      };
    default:
      return state;
  }
};

export default ui;
