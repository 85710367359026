import React from "react";
import PropTypes from "prop-types";
import Plotly from "plotly.js-cartesian-dist";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

class ZygosityDensityPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frames: [],
      config: {},
      className: "ioexplorer-plot hla",
      style: { display: "block" },
      useResizeHandler: true,
    };
  }

  onSelected(eventData) {
    if (eventData === undefined || eventData.points.length === 0) {
      return;
    }
    const { saveCohort } = this.props;
    const points = eventData.points.filter((point) => point.text !== undefined);
    const selectedValues = eventData.points.map((p) => p.x);
    saveCohort(
      points.map((point) => point.text),
      [],
      "HLA",
      [
        "HED Density",
        eventData.lassoPoints !== undefined
          ? "Lasso"
          : `${Math.min(...Object.entries(eventData.range)[0][1]).toFixed(
              3
            )} - ${Math.max(...Object.entries(eventData.range)[0][1]).toFixed(
              3
            )}`,
        Math.min(...selectedValues).toFixed(3),
        Math.max(...selectedValues).toFixed(3),
      ]
    );
  }

  render() {
    const { data, layout, revision } = this.props;
    const { frames, config, className, style, useResizeHandler } = this.state;
    return (
      <Plot
        data={data}
        layout={layout}
        frames={frames}
        config={config}
        revision={revision}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
        onSelected={(figure) => this.onSelected(figure)}
        className={className}
        style={style}
        useResizeHandler={useResizeHandler}
      />
    );
  }
}

ZygosityDensityPlot.propTypes = {
  data: PropTypes.array.isRequired,
  layout: PropTypes.object.isRequired,
  revision: PropTypes.number.isRequired,
  saveCohort: PropTypes.func.isRequired,
};

export default ZygosityDensityPlot;
