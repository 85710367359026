import { connect } from "react-redux";
import VolcanoChart from "../../components/volcano/VolcanoChart";

const mapStateToProps = (state) => ({
  data: state.dataFetch.batchCorrectedExpression.data.data,
  options: state.volcano.present.options,
});

const mapDispatchToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(VolcanoChart);
