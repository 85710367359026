import { connect } from "react-redux";
import { TutorialList } from "../../tutorial/tutorialList";
import {
  chooseTutorial,
  startTutorial,
  stopTutorial,
  advanceTutorial,
  recedeTutorial,
  resetTutorial,
  skipToEndOfTutorial,
  toggleRestoreStateOnStop,
} from "../../actions";
import TutorialContainer from "../../components/tutorial/Container";

const mapStateToProps = (state) => ({
  allTutorials: TutorialList,
  didTutorialStart: state.tutorial.didTutorialStart,
  restoreStateOnStop: state.tutorial.restoreStateOnStop,
  currentTutorialIdx: state.tutorial.currentTutorial,
  currentStep: state.tutorial.currentStep,
  numSteps: TutorialList[state.tutorial.currentTutorial].descriptions.length,
  description:
    TutorialList[state.tutorial.currentTutorial].descriptions[
      state.tutorial.currentStep
    ],
});

const mapDispatchToProps = (dispatch) => ({
  chooseTutorial: (tutorialIdx) => dispatch(chooseTutorial(tutorialIdx)),
  startTutorial: (currentStep) => dispatch(startTutorial(currentStep)),
  stopTutorial: (restoreState) => dispatch(stopTutorial(restoreState)),
  advanceTutorial: () => dispatch(advanceTutorial()),
  recedeTutorial: () => dispatch(recedeTutorial()),
  resetTutorial: () => dispatch(resetTutorial()),
  skipToEndOfTutorial: (lastStep) => dispatch(skipToEndOfTutorial(lastStep)),
  toggleRestoreStateOnStop: () => dispatch(toggleRestoreStateOnStop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorialContainer);
