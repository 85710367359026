import React from "react";
import PropTypes from "prop-types";
import { Button, Collapse } from "@blueprintjs/core";

class Foldable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggleFoldable = this.toggleFoldable.bind(this);
  }

  toggleFoldable() {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  render() {
    const { buttonTitle, buttonParams, children } = this.props;
    const { isOpen } = this.state;
    return (
      <div style={{ width: "100%", height: "100%", margin: 0 }}>
        <Button {...buttonParams} onClick={this.toggleFoldable}>
          {buttonTitle}
        </Button>
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </div>
    );
  }
}

Foldable.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  buttonParams: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};

export default Foldable;
