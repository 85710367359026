import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

const alphabet = ["Gene A", "Gene B", "Gene C", "Gene D", "Gene E"];
const genericSamples = [
  "Sample 1",
  "Sample 2",
  "Sample 3",
  "Sample 4",
  "Sample 5",
  "Sample 6",
  "Sample 7",
  "Sample 8",
];
const genericVst = [
  {
    ids: "Sample 1",
    geneIdentifier: "Gene A",
    value: 1,
  },
  {
    ids: "Sample 1",
    geneIdentifier: "Gene D",
    value: 2,
  },
  {
    ids: "Sample 2",
    geneIdentifier: "Gene B",
    value: 3,
  },
  {
    ids: "Sample 4",
    geneIdentifier: "Gene E",
    value: -4,
  },
  {
    ids: "Sample 5",
    geneIdentifier: "Gene A",
    value: -1,
  },
  {
    ids: "Sample 7",
    geneIdentifier: "Gene D",
    value: 2,
  },
  {
    ids: "Sample 8",
    geneIdentifier: "Gene B",
    value: 3,
  },
  {
    ids: "Sample 8",
    geneIdentifier: "Gene A",
    value: -1,
  },
];

const Chart = ({ data, geneList, options, saveCohort }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (data && geneList && options && ref.current) {
      let svg = d3.select(ref.current); // eslint-disable-line

      const [
        originalVstData,
        pcaPreDeseqData,
        pcaPreBcData,
        pcaPostBcData,
        _unusedDiffExprData, // eslint-disable-line
        topDendroData,
        sideDendroData,
        _unusedCohortNames, // eslint-disable-line
        userSpecifiedGenesArray,
      ] = data;

      // Extract user-specified genes, unique cohort names, unique source names
      const userSpecifiedGenes = new Set(userSpecifiedGenesArray);
      const uniqueCohortNames = Array.from(
        new Set(pcaPostBcData.map((r) => r.cohort))
      );
      const uniqueSourceNames = Array.from(
        new Set(pcaPostBcData.map((r) => r.ngsSource))
      );

      const height = 600;
      const width = 900;

      svg
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("viewBox", `0 0 ${width} ${height}`);

      const widthOneThird = width / 3;
      const heightOneHalf = height / 2;

      /* Set margins and dimentions */
      const margin = { top: 40, right: 45, bottom: 20, left: 45 };
      const dim = {
        pcaPreDeseq: {
          x: 0,
          y: 0,
          h: heightOneHalf,
          w: widthOneThird,
        },
        pcaPreBc: {
          x: widthOneThird,
          y: 0,
          h: heightOneHalf,
          w: widthOneThird,
        },
        pcaPostBc: {
          x: 2 * widthOneThird,
          y: 0,
          h: heightOneHalf,
          w: widthOneThird,
        },
        heatmap: { x: 0, y: heightOneHalf, h: heightOneHalf, w: width },
      };

      const line = d3.line();

      /* Save Cohort Button */

      const saveCohortButton = svg
        .select("#save-cohort-button")
        .select("rect")
        .attr("x", 180)
        .attr("y", 5)
        .attr("height", 20)
        .attr("width", 120)
        .attr("rx", 4)
        .style("fill", "lightgray")
        .style("stroke", "black")
        .style("stroke-width", "0.5")
        .style("cursor", "not-allowed");

      const saveCohortText = svg
        .select("#save-cohort-button")
        .select("text")
        .attr("x", 35)
        .attr("y", 20);

      saveCohortText.text("No samples selected.");

      /* Batch-Corrected VST */

      svg.selectAll("g.heatmap").selectAll("rect").remove();
      svg.selectAll("g.heatmap").selectAll("path").remove();

      const vstData = originalVstData.filter((d) =>
        userSpecifiedGenes.has(d.geneIdentifier)
      );

      const uniqueGenes = Array.from(
        new Set(vstData.map((d) => d.geneIdentifier))
      );
      const uniqueSamples = Array.from(new Set(vstData.map((d) => d.ids)));

      const uniqueUserSpecifiedGenes = uniqueGenes.filter((geneId) =>
        userSpecifiedGenes.has(geneId)
      );

      let heatmapTickValues;
      if (uniqueUserSpecifiedGenes.length > 0) {
        heatmapTickValues = ["Cohort", "NGS Source"].concat(
          uniqueUserSpecifiedGenes
        );
      } else {
        heatmapTickValues = ["Cohort", "NGS Source"].concat(alphabet);
      }

      const extraGeneNamePadding = 60;
      const topDendroHeight = 40;
      const sideDendroWidth = 40;

      const scaleSamples = d3
        .scaleBand()
        .domain(uniqueSamples.length > 0 ? uniqueSamples : genericSamples)
        .range([
          dim.heatmap.x + margin.left + extraGeneNamePadding,
          dim.heatmap.x + dim.heatmap.w - sideDendroWidth - margin.right,
        ]);

      const scaleGenes = d3
        .scaleBand()
        .domain(heatmapTickValues)
        .range([
          dim.heatmap.y + margin.top + topDendroHeight,
          dim.heatmap.y + dim.heatmap.h - margin.bottom,
        ]);

      let topDendroMaxX = 0;
      let topDendroMaxY = 0;
      let sideDendroMaxX = 0;
      let sideDendroMaxY = 0;
      if (topDendroData.length > 0) {
        topDendroMaxX = d3.max(topDendroData.flat().map(([x, _unused]) => x));
        topDendroMaxY = d3.max(topDendroData.flat().map(([_unused, y]) => y));
        sideDendroMaxX = d3.max(sideDendroData.flat().map(([x, _unused]) => x));
        sideDendroMaxY = d3.max(sideDendroData.flat().map(([_unused, y]) => y));
      }

      const scaleTopDendroX = d3
        .scaleLinear()
        .domain([-0.5, topDendroMaxX + 0.5])
        .range([
          dim.heatmap.x + margin.left + extraGeneNamePadding,
          dim.heatmap.x + dim.heatmap.w - sideDendroWidth - margin.right,
        ]);

      const scaleTopDendroY = d3
        .scaleLinear()
        .domain([0, topDendroMaxY])
        .range([
          dim.heatmap.y + margin.top,
          dim.heatmap.y + margin.top + topDendroHeight - 2,
        ]);

      const scaleSideDendroX = d3
        .scaleLinear()
        .domain([-0.5, sideDendroMaxX + 0.5])
        .range([
          dim.heatmap.x + dim.heatmap.w - sideDendroWidth - margin.right,
          dim.heatmap.x + dim.heatmap.w - margin.right,
        ]);

      const scaleSideDendroY = d3
        .scaleLinear()
        .domain([-0.5, sideDendroMaxY + 0.5])
        .range([
          dim.heatmap.y + dim.heatmap.h - margin.bottom,
          dim.heatmap.y +
            margin.top +
            topDendroHeight +
            scaleGenes.bandwidth() * 2,
        ]);

      // const xAxisSamples = (g) =>
      //   g
      //     .attr(
      //       "transform",
      //       `translate(0,${dim.heatmap.y + dim.heatmap.h - margin.bottom})`
      //     )
      //     .call(d3.axisBottom(scaleSamples).tickValues([]).tickSizeOuter(0));

      const yAxisGenes = (g) =>
        g
          .attr(
            "transform",
            `translate(${
              dim.heatmap.x + margin.left + extraGeneNamePadding - 2
            },0)`
          )
          .call(
            d3
              .axisLeft(scaleGenes)
              .tickValues(heatmapTickValues)
              .tickSizeOuter(0)
          );

      const colorScale = () => {
        if (vstData.length > 0) {
          const [min, max] = d3.extent(vstData, (d) => d.value);
          const newMax = Math.max(-min, max);
          return d3.scaleDiverging([-newMax, 0, newMax], (t) =>
            d3.interpolateRdBu(1 - t)
          );
        }
        return d3.scaleDiverging([-5, 0, 5], (t) => d3.interpolateRdBu(1 - t));
      };

      const color = colorScale();

      const vstCellWidth = Math.max(scaleSamples.bandwidth() - 0.5, 0.1);

      svg
        .selectAll("g.heatmap")
        .selectAll("path.top-dendro")
        .data(topDendroData)
        .join("path")
        .attr("d", (d) =>
          line(d.map(([x, y]) => [scaleTopDendroX(x), scaleTopDendroY(y)]))
        )
        .attr("stroke", "black")
        .attr("fill", "none");

      svg
        .selectAll("g.heatmap")
        .selectAll("path.side-dendro")
        .data(sideDendroData)
        .join("path")
        .attr("d", (d) =>
          line(d.map(([x, y]) => [scaleSideDendroX(x), scaleSideDendroY(y)]))
        )
        .attr("stroke", "black")
        .attr("fill", "none");

      const heatmapCells = svg
        .selectAll("g.heatmap")
        .selectAll("rect.heatmap")
        .data(vstData.length > 0 ? vstData : genericVst)
        .join("rect")
        .attr("width", vstCellWidth)
        .attr("height", scaleGenes.bandwidth() - 0.5)
        .attr("fill", (d) => color(d.value))
        .attr("x", (d) => scaleSamples(d.ids))
        .attr("y", (d) => scaleGenes(d.geneIdentifier));

      const cohortCells = svg
        .selectAll("g.heatmap")
        .selectAll("rect.cohort")
        .data(pcaPostBcData)
        .join("rect")
        .attr("width", vstCellWidth)
        .attr("height", scaleGenes.bandwidth() - 0.5)
        .attr(
          "fill",
          (d) => d3.schemeDark2[uniqueCohortNames.indexOf(d.cohort)]
        )
        .attr("x", (d) => scaleSamples(d.ids))
        .attr("y", (_unused) => scaleGenes("Cohort"));

      const ngsSourceCells = svg
        .selectAll("g.heatmap")
        .selectAll("rect.ngsSource")
        .data(pcaPostBcData)
        .join("rect")
        .attr("width", vstCellWidth)
        .attr("height", scaleGenes.bandwidth() - 0.5)
        .attr(
          "fill",
          (d) => d3.schemeSet1[uniqueSourceNames.indexOf(d.ngsSource)]
        )
        .attr("x", (d) => scaleSamples(d.ids))
        .attr("y", (_unused) => scaleGenes("NGS Source"));

      // svg.selectAll("g.x-axis-heatmap").call(xAxisSamples);
      svg.selectAll("g.y-axis-heatmap").call(yAxisGenes);

      /* PCA */

      const pcaDomainX = [
        d3.min([
          d3.min(pcaPreDeseqData.map((d) => d.PC1)),
          d3.min(pcaPreBcData.map((d) => d.PC1)),
          d3.min(pcaPostBcData.map((d) => d.PC1)),
        ]),
        d3.max([
          d3.max(pcaPreDeseqData.map((d) => d.PC1)),
          d3.max(pcaPreBcData.map((d) => d.PC1)),
          d3.max(pcaPostBcData.map((d) => d.PC1)),
        ]),
      ];

      const pcaDomainY = [
        d3.min([
          d3.min(pcaPreDeseqData.map((d) => d.PC2)),
          d3.min(pcaPreBcData.map((d) => d.PC2)),
          d3.min(pcaPostBcData.map((d) => d.PC2)),
        ]),
        d3.max([
          d3.max(pcaPreDeseqData.map((d) => d.PC2)),
          d3.max(pcaPreBcData.map((d) => d.PC2)),
          d3.max(pcaPostBcData.map((d) => d.PC2)),
        ]),
      ];

      const scalePCAX = d3
        .scaleLinear()
        .domain(
          pcaPreDeseqData.length > 0
            ? options.pcaDomain === "same"
              ? pcaDomainX
              : [
                  d3.min(pcaPreDeseqData.map((d) => d.PC1)),
                  d3.max(pcaPreDeseqData.map((d) => d.PC1)),
                ]
            : [-0.1, 0.1]
        )
        .range([
          dim.pcaPreDeseq.x + margin.left,
          dim.pcaPreDeseq.x + dim.pcaPreDeseq.w - margin.right,
        ]);

      const scalePCAY = d3
        .scaleLinear()
        .domain(
          pcaPreDeseqData.length > 0
            ? options.pcaDomain === "same"
              ? pcaDomainY
              : [
                  d3.min(pcaPreDeseqData.map((d) => d.PC2)),
                  d3.max(pcaPreDeseqData.map((d) => d.PC2)),
                ]
            : [-0.1, 0.1]
        )
        .range([
          dim.pcaPreDeseq.y + dim.pcaPreDeseq.h - margin.bottom,
          dim.pcaPreDeseq.y + margin.top,
        ]);

      const xAxisPCA = (g) =>
        g
          .attr(
            "transform",
            `translate(0,${
              dim.pcaPreDeseq.y + dim.pcaPreDeseq.h - margin.bottom
            })`
          )
          .call(d3.axisBottom(scalePCAX).ticks(5).tickSizeOuter(0));

      const yAxisPCA = (g) =>
        g
          .attr(
            "transform",
            `translate(${dim.pcaPreDeseq.x + margin.left},${dim.pcaPreDeseq.y})`
          )
          .call(d3.axisLeft(scalePCAY).ticks(5).tickSizeOuter(0));

      const scalePCAX2 = d3
        .scaleLinear()
        .domain(
          pcaPreBcData.length > 0
            ? options.pcaDomain === "same"
              ? pcaDomainX
              : [
                  d3.min(pcaPreBcData.map((d) => d.PC1)),
                  d3.max(pcaPreBcData.map((d) => d.PC1)),
                ]
            : [-0.1, 0.1]
        )
        .range([
          dim.pcaPreBc.x + margin.left,
          dim.pcaPreBc.x + dim.pcaPreBc.w - margin.right,
        ]);

      const scalePCAY2 = d3
        .scaleLinear()
        .domain(
          pcaPreBcData.length > 0
            ? options.pcaDomain === "same"
              ? pcaDomainY
              : [
                  d3.min(pcaPreBcData.map((d) => d.PC2)),
                  d3.max(pcaPreBcData.map((d) => d.PC2)),
                ]
            : [-0.1, 0.1]
        )
        .range([
          dim.pcaPreBc.y + dim.pcaPreBc.h - margin.bottom,
          dim.pcaPreBc.y + margin.top,
        ]);

      const xAxisPCA2 = (g) =>
        g
          .attr(
            "transform",
            `translate(0,${dim.pcaPreBc.y + dim.pcaPreBc.h - margin.bottom})`
          )
          .call(d3.axisBottom(scalePCAX2).ticks(5).tickSizeOuter(0));

      const yAxisPCA2 = (g) =>
        g
          .attr(
            "transform",
            `translate(${dim.pcaPreBc.x + margin.left},${dim.pcaPreBc.y})`
          )
          .call(d3.axisLeft(scalePCAY2).ticks(5).tickSizeOuter(0));

      const scalePCAX3 = d3
        .scaleLinear()
        .domain(
          pcaPostBcData.length > 0
            ? options.pcaDomain === "same"
              ? pcaDomainX
              : [
                  d3.min(pcaPostBcData.map((d) => d.PC1)),
                  d3.max(pcaPostBcData.map((d) => d.PC1)),
                ]
            : [-0.1, 0.1]
        )
        .range([
          dim.pcaPostBc.x + margin.left,
          dim.pcaPostBc.x + dim.pcaPostBc.w - margin.right,
        ]);

      const scalePCAY3 = d3
        .scaleLinear()
        .domain(
          pcaPostBcData.length > 0
            ? options.pcaDomain === "same"
              ? pcaDomainY
              : [
                  d3.min(pcaPostBcData.map((d) => d.PC2)),
                  d3.max(pcaPostBcData.map((d) => d.PC2)),
                ]
            : [-0.1, 0.1]
        )
        .range([
          dim.pcaPostBc.y + dim.pcaPostBc.h - margin.bottom,
          dim.pcaPostBc.y + margin.top,
        ]);

      const xAxisPCA3 = (g) =>
        g
          .attr(
            "transform",
            `translate(0,${dim.pcaPostBc.y + dim.pcaPostBc.h - margin.bottom})`
          )
          .call(d3.axisBottom(scalePCAX3).ticks(5).tickSizeOuter(0));

      const yAxisPCA3 = (g) =>
        g
          .attr(
            "transform",
            `translate(${dim.pcaPostBc.x + margin.left},${dim.pcaPostBc.y})`
          )
          .call(d3.axisLeft(scalePCAY3).ticks(5).tickSizeOuter(0));

      /* PCA Axis Titles */
      const marks = [
        {
          xAxisClass: "pca-pre-deseq-x-axis-title",
          xAxisX: dim.pcaPreDeseq.x + dim.pcaPreDeseq.w / 2,
          xAxisY: dim.pcaPreDeseq.y + dim.pcaPreDeseq.h + 5,
          yAxisClass: "pca-pre-deseq-y-axis-title",
          yAxisX: dim.pcaPreDeseq.x + 5,
          yAxisY: dim.pcaPreDeseq.y + dim.pcaPreDeseq.h / 2,
          yAxisTransform: `rotate(-90, ${dim.pcaPreDeseq.x + 5}, ${
            dim.pcaPreDeseq.y + dim.pcaPreDeseq.h / 2
          })`,
        },
        {
          xAxisClass: "pca-pre-bc-x-axis-title",
          xAxisX: dim.pcaPreBc.x + dim.pcaPreBc.w / 2,
          xAxisY: dim.pcaPreBc.y + dim.pcaPreBc.h + 5,
          yAxisClass: "pca-pre-bc-y-axis-title",
          yAxisX: dim.pcaPreBc.x + 5,
          yAxisY: dim.pcaPreBc.y + dim.pcaPreBc.h / 2,
          yAxisTransform: `rotate(-90, ${dim.pcaPreBc.x + 5}, ${
            dim.pcaPreBc.y + dim.pcaPreBc.h / 2
          })`,
        },
        {
          xAxisClass: "pca-post-bc-x-axis-title",
          xAxisX: dim.pcaPostBc.x + dim.pcaPostBc.w / 2,
          xAxisY: dim.pcaPostBc.y + dim.pcaPostBc.h + 5,
          yAxisClass: "pca-post-bc-y-axis-title",
          yAxisX: dim.pcaPostBc.x + 5,
          yAxisY: dim.pcaPostBc.y + dim.pcaPostBc.h / 2,
          yAxisTransform: `rotate(-90, ${dim.pcaPostBc.x + 5}, ${
            dim.pcaPostBc.y + dim.pcaPostBc.h / 2
          })`,
        },
      ];
      marks.forEach((mark) => {
        svg
          .selectAll("g.pca-plot-marks")
          .selectAll(`text.${mark.xAxisClass}`)
          .remove();
        svg
          .selectAll("g.pca-plot-marks")
          .append("text")
          .data(["PC1"])
          .join("text")
          .attr("class", "pca-pre-deseq-x-axis-title")
          .attr("x", mark.xAxisX)
          .attr("y", mark.xAxisY)
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "central")
          .text((d) => d)
          .style("font-size", "8px");

        svg
          .selectAll("g.pca-plot-marks")
          .selectAll(`text.${mark.yAxisClass}`)
          .remove();
        svg
          .selectAll("g.pca-plot-marks")
          .append("text")
          .data(["PC2"])
          .join("text")
          .attr("class", "pca-pre-deseq-y-axis-title")
          .attr("x", mark.yAxisX)
          .attr("y", mark.yAxisY)
          .attr("transform", mark.yAxisTransform)
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "central")
          .text((d) => d)
          .style("font-size", "8px");
      });

      const pcaCircles = svg
        .selectAll("g.pca-pre-deseq")
        .selectAll("circle")
        .data(pcaPreDeseqData)
        .join("circle")
        .attr("fill", (d) =>
          options.coloring === "cohort"
            ? d3.schemeDark2[uniqueCohortNames.indexOf(d.cohort)]
            : d3.schemeSet1[uniqueSourceNames.indexOf(d.ngsSource)]
        )
        .attr("fill-opacity", 0.73)
        .attr("r", 5)
        .attr("cx", (d) => scalePCAX(d.PC1))
        .attr("cy", (d) => scalePCAY(d.PC2));

      // const coordinatesPCA = pcaPreDeseqData.map((d) => [
      //   scalePCAX(d.PC1),
      //   scalePCAY(d.PC2),
      // ]);
      // const delaunayPCA = d3.Delaunay.from(coordinatesPCA);
      // const voronoiPCA = delaunayPCA.voronoi([
      //   dim.pcaPreDeseq.x + margin.left,
      //   dim.pcaPreDeseq.y + margin.top,
      //   dim.pcaPreDeseq.x + dim.pcaPreDeseq.w - margin.right,
      //   dim.pcaPreDeseq.y + dim.pcaPreDeseq.h - margin.bottom,
      // ]);

      const pcaCircles2 = svg
        .selectAll("g.pca-pre-bc")
        .selectAll("circle")
        .data(pcaPreBcData)
        .join("circle")
        .attr("fill", (d) =>
          options.coloring === "cohort"
            ? d3.schemeDark2[uniqueCohortNames.indexOf(d.cohort)]
            : d3.schemeSet1[uniqueSourceNames.indexOf(d.ngsSource)]
        )
        .attr("fill-opacity", 0.73)
        .attr("r", 5)
        .attr("cx", (d) => scalePCAX2(d.PC1))
        .attr("cy", (d) => scalePCAY2(d.PC2));

      // const coordinatesPCA2 = pcaPreBcData.map((d) => [
      //   scalePCAX(d.PC1),
      //   scalePCAY(d.PC2),
      // ]);
      // const delaunayPCA2 = d3.Delaunay.from(coordinatesPCA2);
      // const voronoiPCA2 = delaunayPCA2.voronoi([
      //   dim.pcaPreBc.x + margin.left,
      //   dim.pcaPreBc.y + margin.top,
      //   dim.pcaPreBc.x + dim.pcaPreBc.w - margin.right,
      //   dim.pcaPreBc.y + dim.pcaPreBc.h - margin.bottom,
      // ]);

      const pcaCircles3 = svg
        .selectAll("g.pca-post-bc")
        .selectAll("circle")
        .data(pcaPostBcData)
        .join("circle")
        .attr("fill", (d) =>
          options.coloring === "cohort"
            ? d3.schemeDark2[uniqueCohortNames.indexOf(d.cohort)]
            : d3.schemeSet1[uniqueSourceNames.indexOf(d.ngsSource)]
        )
        .attr("fill-opacity", 0.73)
        .attr("r", 5)
        .attr("cx", (d) => scalePCAX3(d.PC1))
        .attr("cy", (d) => scalePCAY3(d.PC2));

      // const coordinatesPCA3 = pcaPostBcData.map((d) => [
      //   scalePCAX3(d.PC1),
      //   scalePCAY3(d.PC2),
      // ]);
      // const delaunayPCA3 = d3.Delaunay.from(coordinatesPCA3);
      // const voronoiPCA3 = delaunayPCA3.voronoi([
      //   dim.pcaPostBc.x + margin.left,
      //   dim.pcaPostBc.y + margin.top,
      //   dim.pcaPostBc.x + dim.pcaPostBc.w - margin.right,
      //   dim.pcaPostBc.y + dim.pcaPostBc.h - margin.bottom,
      // ]);

      /* Brush Effects */

      const brushedPCA = ({ selection }) => {
        if (selection) {
          const [[x0, y0], [x1, y1]] = selection;
          const brushSelection = pcaCircles
            .attr("r", 3)
            .filter(
              (d) =>
                x0 <= scalePCAX(d.PC1) &&
                x1 > scalePCAX(d.PC1) &&
                y0 <= scalePCAY(d.PC2) &&
                y1 > scalePCAY(d.PC2)
            )
            .attr("r", 7)
            .data();
          const brushIds = new Set(brushSelection.map((d) => d.ids));
          pcaCircles2
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          pcaCircles3
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          heatmapCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          cohortCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          ngsSourceCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          saveCohortText.text(`Selected ${brushIds.size} samples.`);
          saveCohortButton.on("click", () => {
            const ids = Array.from(brushIds);
            saveCohort(
              ids.map((id) => id.split("+")[0]),
              ids.map((id) => id.split("+")[1]),
              "Volcano",
              []
            );
          });
          saveCohortButton.style("cursor", "pointer");
        } else {
          pcaCircles.attr("r", 5);
          pcaCircles2.attr("r", 5);
          pcaCircles3.attr("r", 5);
          heatmapCells.attr("width", vstCellWidth);
          cohortCells.attr("width", vstCellWidth);
          ngsSourceCells.attr("width", vstCellWidth);
          saveCohortButton.on("click", () => {});
          saveCohortButton.style("cursor", "not-allowed");
          saveCohortText.text("No samples selected.");
        }
      };

      const brushedPCA2 = ({ selection }) => {
        if (selection) {
          const [[x0, y0], [x1, y1]] = selection;
          const brushSelection = pcaCircles2
            .attr("r", 3)
            .filter(
              (d) =>
                x0 <= scalePCAX2(d.PC1) &&
                x1 > scalePCAX2(d.PC1) &&
                y0 <= scalePCAY2(d.PC2) &&
                y1 > scalePCAY2(d.PC2)
            )
            .attr("r", 7)
            .data();
          const brushIds = new Set(brushSelection.map((d) => d.ids));
          pcaCircles
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          pcaCircles3
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          heatmapCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          cohortCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          ngsSourceCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          saveCohortText.text(`Selected ${brushIds.size} samples.`);
          saveCohortButton.on("click", () => {
            const ids = Array.from(brushIds);
            saveCohort(
              ids.map((id) => id.split("+")[0]),
              ids.map((id) => id.split("+")[1]),
              "Volcano",
              []
            );
          });
          saveCohortButton.style("cursor", "pointer");
        } else {
          pcaCircles.attr("r", 5);
          pcaCircles2.attr("r", 5);
          pcaCircles3.attr("r", 5);
          heatmapCells.attr("width", vstCellWidth);
          cohortCells.attr("width", vstCellWidth);
          ngsSourceCells.attr("width", vstCellWidth);
          saveCohortButton.on("click", () => {});
          saveCohortButton.style("cursor", "not-allowed");
          saveCohortText.text("No samples selected.");
        }
      };

      const brushedPCA3 = ({ selection }) => {
        if (selection) {
          const [[x0, y0], [x1, y1]] = selection;
          const brushSelection = pcaCircles3
            .attr("r", 3)
            .filter(
              (d) =>
                x0 <= scalePCAX3(d.PC1) &&
                x1 > scalePCAX3(d.PC1) &&
                y0 <= scalePCAY3(d.PC2) &&
                y1 > scalePCAY3(d.PC2)
            )
            .attr("r", 7)
            .data();
          const brushIds = new Set(brushSelection.map((d) => d.ids));
          pcaCircles
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          pcaCircles2
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          heatmapCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          cohortCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          ngsSourceCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          saveCohortText.text(`Selected ${brushIds.size} samples.`);
          saveCohortButton.on("click", () => {
            const ids = Array.from(brushIds);
            saveCohort(
              ids.map((id) => id.split("+")[0]),
              ids.map((id) => id.split("+")[1]),
              "Volcano",
              []
            );
          });
          saveCohortButton.style("cursor", "pointer");
        } else {
          pcaCircles.attr("r", 5);
          pcaCircles2.attr("r", 5);
          pcaCircles3.attr("r", 5);
          heatmapCells.attr("width", vstCellWidth);
          cohortCells.attr("width", vstCellWidth);
          ngsSourceCells.attr("width", vstCellWidth);
          saveCohortButton.on("click", () => {});
          saveCohortButton.style("cursor", "not-allowed");
          saveCohortText.text("No samples selected.");
        }
      };

      const brushedVST = ({ selection }) => {
        if (selection) {
          const [x0, x1] = selection;
          const brushSelection = heatmapCells
            .attr("width", 0)
            .filter(
              (d) => x0 <= scaleSamples(d.ids) && x1 > scaleSamples(d.ids)
            )
            .attr("width", vstCellWidth)
            .data();
          const brushIds = new Set(brushSelection.map((d) => d.ids));
          pcaCircles
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          pcaCircles2
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          pcaCircles3
            .attr("r", 3)
            .filter((d) => brushIds.has(d.ids))
            .attr("r", 7);
          cohortCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          ngsSourceCells
            .attr("width", 0)
            .filter((d) => brushIds.has(d.ids))
            .attr("width", vstCellWidth);
          saveCohortText.text(`Selected ${brushIds.size} samples.`);
          saveCohortButton.on("click", () => {
            const ids = Array.from(brushIds);
            saveCohort(
              ids.map((id) => id.split("+")[0]),
              ids.map((id) => id.split("+")[1]),
              "Volcano",
              []
            );
          });
          saveCohortButton.style("cursor", "pointer");
        } else {
          pcaCircles.attr("r", 5);
          pcaCircles2.attr("r", 5);
          pcaCircles3.attr("r", 5);
          heatmapCells.attr("width", vstCellWidth);
          cohortCells.attr("width", vstCellWidth);
          ngsSourceCells.attr("width", vstCellWidth);
          saveCohortButton.on("click", () => {});
          saveCohortButton.style("cursor", "not-allowed");
          saveCohortText.text("No samples selected.");
        }
      };

      if (options.interaction === "tooltip") {
        // // Original tooltip
        // svg
        //   .selectAll("g.pca-tooltip-old")
        //   .insert("g")
        //   .attr(
        //     "transform",
        //     `translate(${scalePCAX(pcaPreBcData[0].PC1)},${scalePCAY(
        //       pcaPreBcData[0].PC2
        //     )})`
        //   )
        //   .insert("circle")
        //   .attr("r", 18)
        //   .attr("cx", 0)
        //   .attr("cy", 0)
        //   .attr("fill", "none")
        //   .attr("stroke", "black");
        //
        // let minPC1 = d3.min(R.pluck("PC1", pcaPreBcData));
        // let maxPC1 = d3.max(R.pluck("PC1", pcaPreBcData));
        // let midline = (maxPC1 - minPC1) / 2 + minPC1;
        //
        // svg
        //   .selectAll("g.pca-tooltip-old")
        //   .selectAll("g")
        //   .append("text")
        //   .attr(
        //     "x",
        //     pcaPreBcData[0].PC1 < midline
        //       ? 22
        //       : -pcaPreBcData[0].ids.length * 7.4
        //   )
        //   .attr("y", 2)
        //   .attr("font-family", "monospace")
        //   .text(
        //     options.coloring !== "cohort"
        //       ? pcaPreBcData[0].cohort
        //       : pcaPreBcData[0].ngsSource
        //   )
        //   .style("font-size", "10px");
        //
        // // Update tooltip using Voronoi map
        // svg
        //   .selectAll("g.pca-tooltip-old")
        //   .selectAll("path")
        //   .data(pcaPreBcData)
        //   .join("path")
        //   .attr("d", (_unused, i) => line(voronoiPCA.cellPolygon(i)))
        //   .attr("stroke", "white")
        //   .attr("fill", "white")
        //   .attr("opacity", 0)
        //   .on("mouseover", (_unused, d) => {
        //     d3.selectAll("g.pca-tooltip-old")
        //       .selectAll("g")
        //       .attr(
        //         "transform",
        //         `translate(${scalePCAX(d.PC1)},${scalePCAY(d.PC2)})`
        //       );
        //     d3.selectAll("g.pca-tooltip-old")
        //       .selectAll("g")
        //       .selectAll("text")
        //       .attr(
        //         "x",
        //         d.PC1 < midline
        //           ? 22
        //           : -(options.coloring !== "cohort"
        //               ? d.cohort.length
        //               : d.ngsSource.length) * 7.4
        //       )
        //       .text(options.coloring !== "cohort" ? d.cohort : d.ngsSource);
        //   });
        //
        // // Original tooltip
        // svg
        //   .selectAll("g.pca-tooltip-new")
        //   .insert("g")
        //   .attr(
        //     "transform",
        //     `translate(${scalePCAX2(pcaPostBcData[0].PC1)},${scalePCAY2(
        //       pcaPostBcData[0].PC2
        //     )})`
        //   )
        //   .insert("circle")
        //   .attr("r", 18)
        //   .attr("cx", 0)
        //   .attr("cy", 0)
        //   .attr("fill", "none")
        //   .attr("stroke", "black");
        //
        // minPC1 = d3.min(R.pluck("PC1", pcaPostBcData));
        // maxPC1 = d3.max(R.pluck("PC1", pcaPostBcData));
        // midline = (maxPC1 - minPC1) / 2 + minPC1;
        //
        // svg
        //   .selectAll("g.pca-tooltip-new")
        //   .selectAll("g")
        //   .append("text")
        //   .attr(
        //     "x",
        //     pcaPostBcData[0].PC1 < midline
        //       ? 22
        //       : -pcaPostBcData[0].ids.length * 7.4
        //   )
        //   .attr("y", 2)
        //   .attr("font-family", "monospace")
        //   .text(
        //     options.coloring !== "cohort"
        //       ? pcaPostBcData[0].cohort
        //       : pcaPostBcData[0].ngsSource
        //   )
        //   .style("font-size", "10px");
        //
        // // Update tooltip using Voronoi map
        // svg
        //   .selectAll("g.pca-tooltip-new")
        //   .selectAll("path")
        //   .data(pcaPostBcData)
        //   .join("path")
        //   .attr("d", (_unused, i) => line(voronoiPCA2.cellPolygon(i)))
        //   .attr("stroke", "white")
        //   .attr("fill", "white")
        //   .attr("opacity", 0)
        //   .on("mouseover", (_unused, d) => {
        //     d3.selectAll("g.pca-tooltip-new")
        //       .selectAll("g")
        //       .attr(
        //         "transform",
        //         `translate(${scalePCAX2(d.PC1)},${scalePCAY2(d.PC2)})`
        //       );
        //     d3.selectAll("g.pca-tooltip-new")
        //       .selectAll("g")
        //       .selectAll("text")
        //       .attr(
        //         "x",
        //         d.PC1 < midline
        //           ? 22
        //           : -(options.coloring !== "cohort"
        //               ? d.cohort.length
        //               : d.ngsSource.length) * 7.4
        //       )
        //       .text(options.coloring !== "cohort" ? d.cohort : d.ngsSource);
        //   });
      } else if (options.interaction === "lasso" && vstData.length > 0) {
        const brushPCA = d3
          .brush()
          .extent([
            [dim.pcaPreDeseq.x, dim.pcaPreDeseq.y],
            [
              dim.pcaPreDeseq.x + dim.pcaPreDeseq.w,
              dim.pcaPreDeseq.y + dim.pcaPreDeseq.h,
            ],
          ])
          .on("start brush end", brushedPCA);

        const brushPCA2 = d3
          .brush()
          .extent([
            [dim.pcaPreBc.x, dim.pcaPreBc.y],
            [dim.pcaPreBc.x + dim.pcaPreBc.w, dim.pcaPreBc.y + dim.pcaPreBc.h],
          ])
          .on("start brush end", brushedPCA2);

        const brushPCA3 = d3
          .brush()
          .extent([
            [dim.pcaPostBc.x, dim.pcaPostBc.y],
            [
              dim.pcaPostBc.x + dim.pcaPostBc.w,
              dim.pcaPostBc.y + dim.pcaPostBc.h,
            ],
          ])
          .on("start brush end", brushedPCA3);

        const brushVST = d3
          .brushX()
          .extent([
            [dim.heatmap.x, dim.heatmap.y],
            [dim.heatmap.x + dim.heatmap.w, dim.heatmap.y + dim.heatmap.h],
          ])
          .on("start brush end", brushedVST);

        svg.selectAll("g.pca-pre-deseq").append("g").call(brushPCA);
        svg.selectAll("g.pca-pre-bc").append("g").call(brushPCA2);
        svg.selectAll("g.pca-post-bc").append("g").call(brushPCA3);
        svg.selectAll("g.heatmap").append("g").call(brushVST);
      }

      svg.selectAll("g.x-axis-pca-pre-deseq").call(xAxisPCA);
      svg.selectAll("g.y-axis-pca-pre-deseq").call(yAxisPCA);
      svg.selectAll("g.x-axis-pca-pre-bc").call(xAxisPCA2);
      svg.selectAll("g.y-axis-pca-pre-bc").call(yAxisPCA2);
      svg.selectAll("g.x-axis-pca-post-bc").call(xAxisPCA3);
      svg.selectAll("g.y-axis-pca-post-bc").call(yAxisPCA3);

      /* PCA Tooltip */
      // const cellsPCA = coordinatesPCA.map((_unused, i) => [
      //   pcaPreBcData[i],
      //   voronoiPCA.cellPolygon(i),
      // ]);

      // const cellAreas = coordinatesPCA.map((_unused, i) =>
      //   Math.abs(d3.polygonArea(voronoiPCA.cellPolygon(i)))
      // );

      // const cohortOneCells = cellsPCA.filter(
      //   ([d]) => d.cohort === uniqueCohortNames[0]
      // );
      // const sourceOneCells = cellsPCA.filter(
      //   ([d]) => d.ngsSource === uniqueSourceNames[0]
      // );

      // const cohortTwoCells = cellsPCA.filter(
      //   ([d]) => d.cohort === uniqueCohortNames[1]
      // );
      // const sourceTwoCells = cellsPCA.filter(
      //   ([d]) => d.ngsSource === uniqueSourceNames[1]
      // );

      // const cohortOneAreas = cellAreas.filter(
      //   (_unused, i) => pcaPreBcData[i].cohort === uniqueCohortNames[0]
      // );
      // const sourceOneAreas = cellAreas.filter(
      //   (_unused, i) => pcaPreBcData[i].ngsSource === uniqueSourceNames[0]
      // );
      //
      // const cohortTwoAreas = cellAreas.filter(
      //   (_unused, i) => pcaPreBcData[i].cohort === uniqueCohortNames[1]
      // );
      // const sourceTwoAreas = cellAreas.filter(
      //   (_unused, i) => pcaPreBcData[i].ngsSource === uniqueSourceNames[1]
      // );

      // const cohortOneLabelCell = d3.maxIndex(cohortOneAreas);
      // const sourceOneLabelCell = d3.maxIndex(sourceOneAreas);
      // const cohortTwoLabelCell = d3.maxIndex(cohortTwoAreas);
      // const sourceTwoLabelCell = d3.maxIndex(sourceTwoAreas);

      // const cellsPCA2 = coordinatesPCA2.map((_unused, i) => [
      //   pcaPostBcData[i],
      //   voronoiPCA2.cellPolygon(i),
      // ]);

      // const cellAreas2 = coordinatesPCA2.map((_unused, i) =>
      //   Math.abs(d3.polygonArea(voronoiPCA2.cellPolygon(i)))
      // );

      // const cohortOneCells2 = cellsPCA2.filter(
      //   ([d]) => d.cohort === uniqueCohortNames[0]
      // );
      // const sourceOneCells2 = cellsPCA2.filter(
      //   ([d]) => d.ngsSource === uniqueSourceNames[0]
      // );
      //
      // const cohortTwoCells2 = cellsPCA2.filter(
      //   ([d]) => d.cohort === uniqueCohortNames[1]
      // );
      // const sourceTwoCells2 = cellsPCA2.filter(
      //   ([d]) => d.ngsSource === uniqueSourceNames[1]
      // );

      // const cohortOneAreas2 = cellAreas2.filter(
      //   (_unused, i) => pcaPostBcData[i].cohort === uniqueCohortNames[0]
      // );
      // const sourceOneAreas2 = cellAreas.filter(
      //   (_unused, i) => pcaPostBcData[i].ngsSource === uniqueSourceNames[0]
      // );
      //
      // const cohortTwoAreas2 = cellAreas2.filter(
      //   (_unused, i) => pcaPostBcData[i].cohort === uniqueCohortNames[1]
      // );
      // const sourceTwoAreas2 = cellAreas2.filter(
      //   (_unused, i) => pcaPostBcData[i].ngsSource === uniqueSourceNames[1]
      // );

      // const cohortOneLabelCell2 = d3.maxIndex(cohortOneAreas2);
      // const sourceOneLabelCell2 = d3.maxIndex(sourceOneAreas2);
      // const cohortTwoLabelCell2 = d3.maxIndex(cohortTwoAreas2);
      // const sourceTwoLabelCell2 = d3.maxIndex(sourceTwoAreas2);

      // svg
      //   .selectAll("g.pca-labels-old")
      //   .selectAll("text")
      //   .data(
      //     options.coloring === "cohort"
      //       ? [
      //           cohortOneCells[cohortOneLabelCell],
      //           cohortTwoCells[cohortTwoLabelCell],
      //         ]
      //       : [
      //           sourceOneCells[sourceOneLabelCell],
      //           sourceTwoCells[sourceTwoLabelCell],
      //         ]
      //   )
      //   .join("text")
      //   .attr("x", ([_unused, cell]) => d3.polygonCentroid(cell)[0])
      //   .attr("y", ([_unused, cell]) => d3.polygonCentroid(cell)[1] + 2)
      //   .text(([d]) => (options.coloring === "cohort" ? d.cohort : d.ngsSource))
      //   .style("font-size", "10px")
      //   .style("color", (d) =>
      //     options.coloring === "cohort"
      //       ? d3.schemeDark2[uniqueCohortNames.indexOf(d.cohort)]
      //       : d3.schemeSet1[
      //           uniqueCohortNames.length +
      //             uniqueSourceNames.indexOf(d.ngsSource)
      //         ]
      //   );
      //
      // svg
      //   .selectAll("g.pca-lines-old")
      //   .attr("stroke", "gray")
      //   .selectAll("path")
      //   .data(
      //     options.coloring === "cohort"
      //       ? [
      //           cohortOneCells[cohortOneLabelCell],
      //           cohortTwoCells[cohortTwoLabelCell],
      //         ]
      //       : [
      //           sourceOneCells[sourceOneLabelCell],
      //           sourceTwoCells[sourceTwoLabelCell],
      //         ]
      //   )
      //   .join("path")
      //   .attr("d", ([d, cell]) =>
      //     line([d3.polygonCentroid(cell), [scalePCAX(d.PC1), scalePCAY(d.PC2)]])
      //   )
      //   .style("opacity", 0.4);

      // svg
      //   .selectAll("g.pca-labels-new")
      //   .selectAll("text")
      //   .data(
      //     options.coloring === "cohort"
      //       ? [
      //           cohortOneCells2[cohortOneLabelCell2],
      //           cohortTwoCells2[cohortTwoLabelCell2],
      //         ]
      //       : [
      //           sourceOneCells2[sourceOneLabelCell2],
      //           sourceTwoCells2[sourceTwoLabelCell2],
      //         ]
      //   )
      //   .join("text")
      //   .attr("x", ([_unused, cell]) => d3.polygonCentroid(cell)[0])
      //   .attr("y", ([_unused, cell]) => d3.polygonCentroid(cell)[1] + 2)
      //   .text(([d]) => (options.coloring === "cohort" ? d.cohort : d.ngsSource))
      //   .style("font-size", "10px")
      //   .style(
      //     "color",
      //     (d) =>
      //       d3.schemeDark2[
      //         options.coloring === "cohort"
      //           ? uniqueCohortNames.indexOf(d.cohort)
      //           : uniqueCohortNames.length +
      //             uniqueSourceNames.indexOf(d.ngsSource)
      //       ]
      //   );

      // svg
      //   .selectAll("g.pca-lines-new")
      //   .attr("stroke", "gray")
      //   .selectAll("path")
      //   .data(
      //     options.coloring === "cohort"
      //       ? [
      //           cohortOneCells2[cohortOneLabelCell2],
      //           cohortTwoCells2[cohortTwoLabelCell2],
      //         ]
      //       : [
      //           sourceOneCells2[sourceOneLabelCell2],
      //           sourceTwoCells2[sourceTwoLabelCell2],
      //         ]
      //   )
      //   .join("path")
      //   .attr("d", ([d, cell]) =>
      //     line([
      //       d3.polygonCentroid(cell),
      //       [scalePCAX2(d.PC1), scalePCAY2(d.PC2)],
      //     ])
      //   )
      //   .style("opacity", 0.4);
    }
  }, [data, geneList, options]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <svg ref={ref}>
        <g className="axis x-axis-pca-pre-deseq" />
        <g className="axis y-axis-pca-pre-deseq" />
        <g className="pca-pre-deseq" />
        <g className="pca-tooltip-pre-deseq" />
        <g className="axis x-axis-pca-pre-bc" />
        <g className="axis y-axis-pca-pre-bc" />
        <g className="pca-pre-bc" />
        <g className="axis x-axis-pca-post-bc" />
        <g className="axis y-axis-pca-post-bc" />
        <g className="pca-post-bc" />
        <g className="pca-plot-marks" />
        <g className="axis x-axis-heatmap" />
        <g className="axis y-axis-heatmap" />
        <g className="heatmap" />
        <g id="save-cohort-button">
          <rect />
          <text />
        </g>
      </svg>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  geneList: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  saveCohort: PropTypes.func.isRequired,
};

export default Chart;
