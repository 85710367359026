import React from "react";
import PropTypes from "prop-types";
import { ControlGroup, Tab, Tabs, Tag } from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";
import PotentialValuesTabPanel from "../PotentialValuesTabPanel";

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      selectedTabId,
      confidenceInterval,
      potentialValues,
      statistics,
      selectTabId,
    } = this.props;
    let noCohortsSelected = true;
    if (
      potentialValues !== undefined &&
      Object.entries(potentialValues).length !== 0
    ) {
      noCohortsSelected = false;
    }
    const { logrank } = statistics;
    return (
      <div className="statistics-container">
        <Tabs
          id="TabsExample"
          onChange={(newTabId) => selectTabId(newTabId)}
          selectedTabId={selectedTabId}
        >
          <Tab
            id="potential-values-tab"
            title="Potential Values"
            panel=<PotentialValuesTabPanel
              noCohortsSelected={noCohortsSelected}
              potentialValues={potentialValues}
            />
            panelClassName="potential-values-container"
          />
          <Tab
            id="statistics-tab"
            title="Statistics"
            panel={
              <div className="survival-statistics">
                {
                  // <LabelWithInfo title="Cox Regression Model" body={[]} />
                  // <ControlGroup fill>
                  //   <Tag>P-value</Tag>
                  //   <Tag>{coxModel.pValue !== -1 ? coxModel.pValue : "NA"}</Tag>
                  // </ControlGroup>
                  // <ControlGroup fill>
                  //   <Tag>Z</Tag>
                  //   <Tag>{coxModel.zValue !== -1 ? coxModel.zValue : "NA"}</Tag>
                  // </ControlGroup>
                  // <ControlGroup fill>
                  //   <Tag>Exp(coef)</Tag>
                  //   <Tag>{coxModel.expCoef !== -1 ? coxModel.expCoef : "NA"}</Tag>
                  // </ControlGroup>
                }
                <LabelWithInfo title="Logrank Test" body={[]} />
                <ControlGroup fill>
                  <Tag>P-value</Tag>
                  <Tag>{logrank.pValue !== -1 ? logrank.pValue : "NA"}</Tag>
                </ControlGroup>
                <ControlGroup fill>
                  <Tag>Test Statistic</Tag>
                  <Tag>
                    {logrank.testStatistic !== -1
                      ? logrank.testStatistic
                      : "NA"}
                  </Tag>
                </ControlGroup>
                <ControlGroup fill>
                  <Tag>Critical Value</Tag>
                  <Tag>
                    {logrank.criticalValues[confidenceInterval] !== -1
                      ? logrank.criticalValues[confidenceInterval]
                      : "NA"}
                  </Tag>
                </ControlGroup>
              </div>
            }
            panelClassName="statistics-panel-container"
          />
          <Tabs.Expander />
        </Tabs>
      </div>
    );
  }
}

Statistics.propTypes = {
  selectedTabId: PropTypes.string.isRequired,
  confidenceInterval: PropTypes.string.isRequired,
  potentialValues: PropTypes.object.isRequired,
  statistics: PropTypes.shape({
    coxModel: PropTypes.shape({
      expCoef: PropTypes.number.isRequired,
      pValue: PropTypes.number.isRequired,
      zValue: PropTypes.number.isRequired,
    }).isRequired,
    logrank: PropTypes.shape({
      criticalValues: PropTypes.object.isRequired,
      pValue: PropTypes.number.isRequired,
      testStatistic: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  selectTabId: PropTypes.func.isRequired,
};

export default Statistics;
