import { connect } from "react-redux";
import {
  addVolcanoGene,
  addMulitpleVolcanoGenes,
  removeVolcanoGene,
  clearVolcanoGenes,
  selectVolcanoGeneSet,
} from "../../actions";
import VolcanoGeneSelect from "../../components/GeneSelect";

const mapStateToProps = (state) => ({
  allGenes: state.dataFetch.allGenes.data,
  geneSets: state.dataFetch.geneSets.data,
  geneList: state.volcano.present.geneList,
  selectedGeneSet: state.volcano.present.geneSet,
});

const mapDispatchToProps = (dispatch) => ({
  addGene: (gene) => dispatch(addVolcanoGene(gene)),
  addMultipleGenes: (geneList) => dispatch(addMulitpleVolcanoGenes(geneList)),
  removeGene: (gene) => dispatch(removeVolcanoGene(gene)),
  clearGenes: () => dispatch(clearVolcanoGenes()),
  selectGeneSet: (geneSet) => dispatch(selectVolcanoGeneSet(geneSet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VolcanoGeneSelect);
