import { connect } from "react-redux";
import {
  addExpressionGene,
  addMulitpleExpressionGenes,
  removeExpressionGene,
  clearExpressionGenes,
  selectExpressionGeneSet,
} from "../../actions";
import ExpressionGeneSelect from "../../components/GeneSelect";

const mapStateToProps = (state) => ({
  allGenes: state.dataFetch.allGenes.data,
  geneSets: state.dataFetch.geneSets.data,
  geneList: state.expression.present.geneList,
  selectedGeneSet: state.expression.present.geneSet,
});

const mapDispatchToProps = (dispatch) => ({
  addGene: (gene) => dispatch(addExpressionGene(gene)),
  addMultipleGenes: (geneList) =>
    dispatch(addMulitpleExpressionGenes(geneList)),
  removeGene: (gene) => dispatch(removeExpressionGene(gene)),
  clearGenes: () => dispatch(clearExpressionGenes()),
  selectGeneSet: (geneSet) => dispatch(selectExpressionGeneSet(geneSet)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionGeneSelect);
