import { connect } from "react-redux";
import { openTutorialPanel, clearLandingPageKey } from "../actions/ui";
import { chooseTutorial } from "../actions";
import LandingPageDialog from "../components/LandingPageDialog";

const mapStateToProps = (state) => ({
  pageKey: state.ui.present.landingPageKey,
});

const mapDispatchToProps = (dispatch) => ({
  clearLandingPageKey: () => dispatch(clearLandingPageKey()),
  openTutorialPanel: () => dispatch(openTutorialPanel()),
  chooseTutorial: (tutorialIdx) => dispatch(chooseTutorial(tutorialIdx)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageDialog);
