import React from "react";
import PropTypes from "prop-types";
import { Icon, Slider, Text } from "@blueprintjs/core";
import { legendColors, legendSymbols } from "../constants";
import LabelWithInfo from "./LabelWithInfo";

const PotentialValuesTabPanel = ({ noCohortsSelected, potentialValues }) => (
  <div>
    <LabelWithInfo title="Potential Values" body={[]} />
    <div className="list">
      {!noCohortsSelected ? (
        Object.entries(potentialValues).map(([name, values]) => (
          <div key={name}>
            <Text className="cohort-label" ellipsize>
              <Icon icon="person" />
              {name.replace("<br>", " ")}
            </Text>
            {Array.isArray(values) ? (
              values.map(([typeLabel, v]) => (
                <div
                  className="cohort-container slider-container"
                  key={typeLabel}
                >
                  <Text>
                    <Icon
                      icon={legendSymbols[typeLabel]}
                      style={{ color: legendColors[typeLabel] }}
                    />
                    {typeLabel}
                  </Text>
                  <Slider
                    intent={v.potential === v.actual ? "success" : "warning"}
                    labelStepSize={v.potential !== 0 ? v.potential : 1}
                    min={0}
                    max={v.potential !== 0 ? v.potential : 1}
                    stepSize={v.potential !== 0 ? v.potential : 1}
                    value={v.actual}
                  />
                </div>
              ))
            ) : (
              <div />
            )}
          </div>
        ))
      ) : (
        <Text>Nothing to display.</Text>
      )}
    </div>
  </div>
);

PotentialValuesTabPanel.propTypes = {
  noCohortsSelected: PropTypes.bool.isRequired,
  potentialValues: PropTypes.object.isRequired,
};

export default PotentialValuesTabPanel;
