export const defaultDistribution = {
  source: "saved-cohorts-union",
  dataField: "",
  scale: "linear",
  ui: { selectedTabId: "legend-tab" },
};

export const defaultCorrelation = {
  source: "saved-cohorts-union",
  firstDataField: "",
  secondDataField: "",
  firstScale: "linear",
  secondScale: "linear",
  ui: {
    selectedTabId: "legend-tab",
  },
};

export const defaultMutation = {
  source: "saved-cohorts-union",
  geneList: [],
  geneSet: "",
  sortBy: "most-frequent-variant",
  selectedGene: { type: "" },
  selectedDataField: "",
  dataFields: [],
  dataFieldsWithLogScale: [],
  ui: { selectedTabId: "legend-tab" },
};

export const defaultExpression = {
  source: "saved-cohorts-union",
  geneList: [],
  geneSet: "",
  sortBy: "clustering",
  selectedGene: { type: "" },
  selectedDataField: "",
  dataFields: [],
  scale: "vst",
  normalize: "within-cohorts",
  dataFieldsWithLogScale: [],
  taskId: "",
  timerId: 0,
  ui: { selectedTabId: "legend-tab" },
};

export const defaultVolcano = {
  isDialogOpen: false,
  geneList: [],
  geneSet: "",
  doShrinkFoldChange: false,
  options: {
    coloring: "cohort",
    interaction: "lasso",
    pcaDomain: "individual",
  },
};

export const defaultHLA = {
  ui: {
    plotTabId: "zygosity-density",
    selectedTabId: "potential-values-tab",
  },
};

export const defaultSurvival = {
  useConfidenceInterval: false,
  confidenceInterval: "0.95",
  logrankWeighting: "wilcoxon",
  ui: { selectedTabId: "potential-values-tab" },
};

export const defaultUi = {
  version: "v2",
  isSelectionPanelOpen: false,
  isTutorialPanelOpen: false,
  whichMappingPopoverOpen: "",
  landingPageKey: "",
  pathname: "home",
  useRedirect: false,
};

export const defaultTask = {
  taskId: "",
  status: "",
  current: 0,
  total: 4,
  message: "",
  timerId: 0,
};

export const defaultTutorial = {
  version: "v1",
  currentTutorial: 0,
  didTutorialStart: false,
  restoreStateOnStop: true,
  currentStep: 0,
};
