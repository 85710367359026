import { connect } from "react-redux";
import {
  selectDistributionDataField,
  selectDistributionDataFieldScale,
} from "../../actions";
import DistributionDataFieldPicker from "../../components/distribution/DataFieldPicker";

const mapStateToProps = (state) => ({
  allFields: state.dataFetch.allDataFields.data,
  selectedField: state.distribution.present.dataField,
  scale: state.distribution.present.scale,
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.distributionDataFieldPicker
      : state.tutorialTooltips.saved.distributionDataFieldPicker,
});

const mapDispatchToProps = (dispatch) => ({
  selectDataField: (field) => dispatch(selectDistributionDataField(field)),
  selectScale: (scale) => dispatch(selectDistributionDataFieldScale(scale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionDataFieldPicker);
