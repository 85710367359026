export const toggleSelectionPanel = () => ({
  type: "TOGGLE_SELECTION_PANEL",
});

export const toggleTutorialPanel = () => ({
  type: "TOGGLE_TUTORIAL_PANEL",
});

export const openSelectionPanel = () => ({
  type: "OPEN_SELECTION_PANEL",
});

export const openTutorialPanel = () => ({
  type: "OPEN_TUTORIAL_PANEL",
});

export const togglePublishedSampleMappingPicker = (name) => ({
  type: "TOGGLE_SAMPLE_MAPPING_PICKER",
  name,
});

export const clearLandingPageKey = () => ({
  type: "CLEAR_LANDING_PAGE_KEY",
});

export const setPathname = (pathname) => ({
  type: "SET_PATHNAME",
  pathname,
});
