import { connect } from "react-redux";
import { saveCohort } from "../../actions/cohorts";
import VolcanoChart from "../../components/volcano/Chart";

const mapStateToProps = (state) => ({
  data: state.dataFetch.batchCorrectedExpression.data.data,
  geneList: state.volcano.present.geneList,
  options: state.volcano.present.options,
});

const mapDispatchToProps = (dispatch) => ({
  saveCohort: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VolcanoChart);
