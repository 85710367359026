import { combineReducers } from "redux";
import { TutorialList } from "../tutorial/tutorialList";
import cohort from "./cohort";
import correlation from "./correlation";
import dataFetch from "./dataFetch";
import distribution from "./distribution";
import expression from "./expression";
import hla from "./hla";
import mutation from "./mutation";
import session from "./session";
import survival from "./survival";
import task from "./task";
import toast from "./toast";
import tutorial from "./tutorial";
import tutorialTooltips from "./tutorialTooltips";
import volcano from "./volcano";
import ui from "./ui";

function tutorialize(reducer, selector) {
  // Call the reducer with empty action to populate the initial state
  const initialState = {
    past: [],
    present: reducer(undefined, {}),
    future: [],
    saved: reducer(undefined, {}),
    currentTutorial: 0,
    currentStep: 0,
  };

  // Return a reducer that handles undo and redo
  return (state = initialState, action) => {
    const {
      past,
      present,
      future,
      saved,
      currentTutorial,
      currentStep,
    } = state;
    let previous;
    let next;
    let newPast;
    let newPresent;
    let newFuture;
    let newSaved;
    let newCurrentTutorial = currentTutorial;
    let newCurrentStep = currentStep;
    if (action.type === "SET_TUTORIAL") {
      newCurrentTutorial = action.value.currentTutorial;
      newCurrentStep = action.value.currentStep;
    } else if (action.type === "CHOOSE_TUTORIAL") {
      newCurrentTutorial = action.tutorialIdx;
    }
    const states = TutorialList[newCurrentTutorial].states.map(
      (s) => s[selector]
    );

    switch (action.type) {
      case "SET_TUTORIAL":
        return {
          ...state,
          past: states.slice(0, newCurrentStep),
          future: states.slice(newCurrentStep + 1),
          currentTutorial: newCurrentTutorial,
          currentStep: newCurrentStep,
        };
      case "CHOOSE_TUTORIAL":
        return {
          ...state,
          currentTutorial: newCurrentTutorial,
          currentStep: 0,
        };
      case "START_TUTORIAL":
        newSaved = present;
        if (selector === "ui") {
          newSaved = {
            ...newSaved,
            useRedirect: true,
          };
        }
        return {
          ...state,
          past: states.slice(0, action.currentStep),
          present: states[action.currentStep],
          future: states.slice(action.currentStep + 1),
          saved: newSaved,
          currentStep,
        };
      case "STOP_TUTORIAL":
        return {
          ...state,
          past: [],
          present: action.restoreState ? saved : present,
          future: [],
          saved: reducer(undefined, {}),
        };
      case "RESET_TUTORIAL":
        return {
          ...state,
          past: [],
          present: states[0],
          future: states.slice(1),
          currentStep: 0,
        };
      case "RECEDE_TUTORIAL":
        previous = past[past.length - 1];
        newPast = past.slice(0, past.length - 1);
        return {
          ...state,
          past: newPast,
          present: previous,
          future: [present, ...future],
          currentStep: currentStep - 1,
        };
      case "ADVANCE_TUTORIAL":
        [next] = future;
        newFuture = future.slice(1);
        return {
          ...state,
          past: [...past, present],
          present: next,
          future: newFuture,
          currentStep: currentStep + 1,
        };
      case "SKIP_TO_END_OF_TUTORIAL":
        return {
          ...state,
          past: states.slice(0, states.length - 1),
          present: states[states.length - 1],
          future: [],
          currentStep: states.length - 1,
        };
      case "SET_PRESENT":
        if (selector !== action.selector) {
          return state;
        }
        newPresent = action.value;
        if (present === newPresent) {
          return state;
        }
        return {
          ...state,
          present: newPresent,
        };
      case "SET_SAVED":
        if (selector !== action.selector) {
          return state;
        }
        newSaved = action.value;
        if (saved === newSaved) {
          return state;
        }
        return {
          ...state,
          saved: newSaved,
        };
      case "SET_PRESENT_AND_SAVED":
        if (selector !== action.selector) {
          return state;
        }
        return {
          ...state,
          present: action.value.present,
          saved: action.value.saved,
        };
      case "RECEIVE_SESSION":
        if (selector === "cohort") {
          newPresent = reducer(present, {
            ...action,
            data: {
              ...action.data,
              cohort: action.data.cohort.present,
            },
          });
          newSaved = reducer(saved, {
            ...action,
            data: {
              ...action.data,
              cohort: action.data.cohort.saved,
            },
          });
          return {
            ...state,
            present: newPresent,
            saved: newSaved,
          };
        }
        if (
          selector === "distribution" ||
          selector === "correlation" ||
          selector === "mutation" ||
          selector === "expression" ||
          selector === "volcano" ||
          selector === "hla" ||
          selector === "survival"
        ) {
          return {
            ...state,
            present: action.data[selector].present,
            saved: action.data[selector].saved,
          };
        }
        return state;

      default:
        // Delegate handling the action to the passed reducer
        newPresent = reducer(present, action);
        if (present === newPresent) {
          return state;
        }
        return {
          ...state,
          present: newPresent,
        };
    }
  };
}

const combinedReducer = combineReducers({
  dataFetch,
  session,
  task,
  tutorial,
  toast,
  cohort: tutorialize(cohort, "cohort"),
  correlation: tutorialize(correlation, "correlation"),
  distribution: tutorialize(distribution, "distribution"),
  expression: tutorialize(expression, "expression"),
  hla: tutorialize(hla, "hla"),
  mutation: tutorialize(mutation, "mutation"),
  survival: tutorialize(survival, "survival"),
  volcano: tutorialize(volcano, "volcano"),
  ui: tutorialize(ui, "ui"),
  tutorialTooltips: tutorialize(tutorialTooltips, "tutorialTooltips"),
});

const crossSliceReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const rootReducer = (state, action) =>
  crossSliceReducer(combinedReducer(state, action), action);

export default rootReducer;
