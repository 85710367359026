import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Callout,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  MenuItem,
  TextArea,
  Tooltip,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

const defaultIntent = {
  id: "default",
  value: Intent.NONE,
  text: "Select an intent",
  icon: "help",
  placeholder: "Comment",
};

const commentIntents = [
  {
    id: "primary",
    intent: Intent.PRIMARY,
    text: "General comment",
    icon: "info-sign",
    placeholder: "I would like to say...",
  },
  {
    id: "success",
    intent: Intent.SUCCESS,
    text: "Things are going well",
    icon: "tick-circle",
    placeholder: "I am enjoying...",
  },
  {
    id: "warning",
    intent: Intent.WARNING,
    text: "Something may be wrong",
    icon: "warning-sign",
    placeholder: "I am not sure about...",
  },
  {
    id: "danger",
    intent: Intent.DANGER,
    text: "Something is definely wrong",
    icon: "error",
    placeholder: "Here is what is going wrong...",
  },
];

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: "",
      commentIntent: defaultIntent,
      isCommentDialogOpen: false,
    };
    this.toggleCommentDialog = this.toggleCommentDialog.bind(this);
    this.updateCommentText = this.updateCommentText.bind(this);
    this.updateCommentIntent = this.updateCommentIntent.bind(this);
  }

  toggleCommentDialog() {
    const { isCommentDialogOpen } = this.state;
    this.setState({
      isCommentDialogOpen: !isCommentDialogOpen,
    });
  }

  updateCommentText(text) {
    this.setState({
      commentText: text,
    });
  }

  updateCommentIntent(intent) {
    this.setState({
      commentIntent: intent,
    });
  }

  render() {
    const { tooltip, postComment } = this.props;
    const { commentText, commentIntent, isCommentDialogOpen } = this.state;
    return (
      <div>
        <div className="comment-tooltip-container">
          <Tooltip {...tooltip}>
            <div className="comment-button" />
          </Tooltip>
        </div>
        <div className="comment-container">
          <Button
            className="comment-button"
            icon="comment"
            large
            onClick={() => this.toggleCommentDialog()}
          />
          <Dialog
            icon="comment"
            isOpen={isCommentDialogOpen}
            onClose={() => this.toggleCommentDialog()}
            title="Comment"
            usePortal={false}
          >
            <div className={Classes.DIALOG_BODY}>
              <Callout
                icon="info-sign"
                intent="primary"
                title="Give Us Feedback"
              >
                We keep track of where you are in the app and what you can see
                so that we can improve your experience.
              </Callout>
              <div className="form">
                <FormGroup
                  intent={commentIntent.intent}
                  label="What is the nature of the feedback?"
                  labelFor="comment-intent-picker"
                >
                  <Select
                    filterable={false}
                    id="comment-intent-picker"
                    itemRenderer={(intent, { handleClick, modifiers }) => {
                      if (!modifiers.matchesPredicate) {
                        return null;
                      }
                      return (
                        <MenuItem
                          active={modifiers.active}
                          key={intent.id}
                          icon={intent.icon}
                          intent={intent.intent}
                          onClick={handleClick}
                          text={intent.text}
                        />
                      );
                    }}
                    items={commentIntents}
                    onItemSelect={(intent) => this.updateCommentIntent(intent)}
                    popoverProps={{ minimal: true, usePortal: false }}
                  >
                    <ButtonGroup fill>
                      <Button
                        icon={commentIntent.icon}
                        rightIcon="caret-down"
                        intent={commentIntent.intent}
                        text={commentIntent.text}
                      />
                    </ButtonGroup>
                  </Select>
                </FormGroup>
                <br />
                <FormGroup
                  intent={commentIntent.intent}
                  label="What is happening?"
                  labelFor="comment-message"
                >
                  <TextArea
                    className="comment-textarea"
                    fill
                    growVertically
                    large
                    id="comment-message"
                    intent={commentIntent.intent}
                    onChange={(e) => this.updateCommentText(e.target.value)}
                    placeholder={commentIntent.placeholder}
                    value={commentText}
                  />
                </FormGroup>
              </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  text="Cancel"
                  onClick={() => this.toggleCommentDialog()}
                />
                <Button
                  disabled={commentIntent === defaultIntent}
                  text="Post"
                  intent="primary"
                  onClick={() => {
                    postComment(commentIntent.id, commentText);
                    this.updateCommentText("");
                    this.updateCommentIntent(defaultIntent);
                    this.toggleCommentDialog();
                  }}
                />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

Comments.propTypes = {
  tooltip: PropTypes.object.isRequired,
  postComment: PropTypes.func.isRequired,
};

export default Comments;
