import { connect } from "react-redux";
import { selectLogrankWeighting } from "../../actions";
import LogrankWeightingPicker from "../../components/survival/LogrankWeightingPicker";

const mapStateToProps = (state) => ({
  weighting: state.survival.present.logrankWeighting,
  tooltip: {},
});

const mapDispatchToProps = (dispatch) => ({
  select: (v) => dispatch(selectLogrankWeighting(v)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogrankWeightingPicker);
