import { connect } from "react-redux";
import { selectSurvivalTabId } from "../../actions";
import SurvivalStatistics from "../../components/survival/Statistics";

const mapStateToProps = (state) => {
  const { data } = state.dataFetch.survival;
  return {
    selectedTabId: state.survival.present.ui.selectedTabId,
    confidenceInterval: state.survival.present.confidenceInterval,
    potentialValues: data.potentialValues,
    statistics: data.statistics,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectTabId: (id) => dispatch(selectSurvivalTabId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurvivalStatistics);
