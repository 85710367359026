import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Callout,
  Divider,
  Popover,
  ProgressBar,
  Switch,
} from "@blueprintjs/core";
import TutorialTree from "../../containers/tutorial/Tree";

const Container = ({
  allTutorials,
  didTutorialStart,
  restoreStateOnStop,
  currentTutorialIdx,
  currentStep,
  numSteps,
  description,
  startTutorial,
  stopTutorial,
  advanceTutorial,
  recedeTutorial,
  resetTutorial,
  skipToEndOfTutorial,
  toggleRestoreStateOnStop,
}) => (
  <div
    className={`${"tutorial-container"} ${
      currentStep === numSteps - 1 ? "finished" : ""
    }`}
  >
    {document.getElementById("instructions-list-0") !== null ? (
      <button
        type="button"
        style={{ display: "none" }}
        onClick={document
          .getElementById("instructions-list-0")
          .scrollIntoView(false)}
      >
        Scroll
      </button>
    ) : (
      <div />
    )}
    <div className="inner" elevation={0}>
      <Callout>Step through a tutorial to learn how to use IOExplorer.</Callout>
      <h4 className="bp3-heading">All Tutorials</h4>
      <TutorialTree />
      <Divider />
      <h3 className="bp3-heading">{allTutorials[currentTutorialIdx].name}</h3>
      <div className="row">
        <ButtonGroup className="start-stop-button" fill>
          <Button
            active={didTutorialStart}
            icon={
              didTutorialStart
                ? currentStep === numSteps - 1
                  ? "tick-circle"
                  : "stop"
                : "play"
            }
            intent={
              didTutorialStart
                ? currentStep === numSteps - 1
                  ? "success"
                  : "danger"
                : "primary"
            }
            onClick={() =>
              didTutorialStart
                ? stopTutorial(restoreStateOnStop)
                : startTutorial(currentStep)
            }
          >
            {didTutorialStart
              ? currentStep === numSteps - 1
                ? "Finish"
                : "Stop"
              : currentStep === 0
              ? "Start"
              : "Resume"}
          </Button>
        </ButtonGroup>
        <Popover
          content=<div className="settings-container">
            <Switch
              alignIndicator="right"
              checked={restoreStateOnStop}
              className="restore-state-switch"
              label="Restore State"
              large
              onChange={() => toggleRestoreStateOnStop()}
            />
            <Callout>
              When the tutorial is started, the state of the app (i.e. the
              user&apos;s selections) are stored and the tutorial takes over the
              management of that state. This option toggles whether or not the
              saved state is restored when the tutorial is stopped or completed.
            </Callout>
          </div>
          position="bottom-right"
          usePortal={false}
        >
          <ButtonGroup fill>
            <Button icon="cog" />
          </ButtonGroup>
        </Popover>
      </div>
      <ButtonGroup className="buttons" fill>
        <Button
          disabled={!didTutorialStart || currentStep === 0}
          icon="double-chevron-left"
          onClick={() => resetTutorial()}
        />
        <Button
          disabled={!didTutorialStart || currentStep === 0}
          icon="chevron-left"
          onClick={() => recedeTutorial()}
        />
        <Button
          disabled={!didTutorialStart || currentStep === numSteps - 1}
          icon="chevron-right"
          onClick={() => advanceTutorial()}
        />
        <Button
          disabled={!didTutorialStart || currentStep === numSteps - 1}
          icon="double-chevron-right"
          onClick={() => skipToEndOfTutorial(numSteps - 1)}
        />
      </ButtonGroup>
      <ProgressBar
        animate={false}
        intent={currentStep === numSteps - 1 ? "success" : "primary"}
        value={
          (currentStep === 0 ? 1 / 10 : currentStep + 1 / 10) / (numSteps - 1)
        }
        stripes={false}
      />
      <div className="instructions">
        <div className={!didTutorialStart ? "bp3-skeleton" : ""}>
          {description.map((d, i) => (
            <p id={`instructions-list-${i}`} key={d.slice(8)}>
              {d}
            </p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

Container.propTypes = {
  allTutorials: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  didTutorialStart: PropTypes.bool.isRequired,
  restoreStateOnStop: PropTypes.bool.isRequired,
  currentTutorialIdx: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  numSteps: PropTypes.number.isRequired,
  description: PropTypes.array.isRequired,
  startTutorial: PropTypes.func.isRequired,
  stopTutorial: PropTypes.func.isRequired,
  advanceTutorial: PropTypes.func.isRequired,
  recedeTutorial: PropTypes.func.isRequired,
  resetTutorial: PropTypes.func.isRequired,
  skipToEndOfTutorial: PropTypes.func.isRequired,
  toggleRestoreStateOnStop: PropTypes.func.isRequired,
};

export default Container;
