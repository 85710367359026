import { connect } from "react-redux";
import { selectVolcanoColoring } from "../../actions";
import VolcanoColoringRadio from "../../components/volcano/ColoringRadio";

const mapStateToProps = (state) => ({
  coloring: state.volcano.present.options.coloring,
});

const mapDispatchToProps = (dispatch) => ({
  selectColoring: (coloring) => dispatch(selectVolcanoColoring(coloring)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VolcanoColoringRadio);
