import { connect } from "react-redux";
import Status from "../components/Status";
import { cancelTask } from "../actions/async";

const mapStateToProps = (state) => ({
  taskStatus: state.task.status,
  currentStep: state.task.current,
  totalSteps: state.task.total,
  timerId: state.task.timerId,
});

const mapDispatchToProps = (dispatch) => ({
  cancelTask: () => dispatch(cancelTask()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
