import React from "react";
import { render } from "react-dom";
import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import fetchDataMiddleware from "./middleware/fetchData";
import plotLoadingMiddleware from "./middleware/plotLoading";
import sessionUpdateMiddleware from "./middleware/sessionUpdate";
import querySyncEnhancer from "./enhancers/querySync";
import ioexplorerApp from "./reducers";
import Root from "./components/Root";

const middlewareEnhancer = applyMiddleware(
  thunkMiddleware,
  fetchDataMiddleware,
  sessionUpdateMiddleware,
  plotLoadingMiddleware
);

const composedEnhancers = compose(middlewareEnhancer, querySyncEnhancer);

const store = createStore(ioexplorerApp, composedEnhancers);

render(
  React.createElement(Root, { store }, null),
  document.getElementById("root")
);
