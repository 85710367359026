import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Icon,
  Slider,
  Tab,
  Tabs,
  Text,
} from "@blueprintjs/core";
import { legendColors, legendSymbols } from "../../constants";
import FeatureUnderConstruction from "../FeatureUnderConstruction";
import LabelWithInfo from "../LabelWithInfo";
import DataFieldsLegendTabPanel from "../DataFieldsLegendTabPanel";
import LegendTabPanel from "../LegendTabPanel";
import PotentialValuesTabPanel from "../PotentialValuesTabPanel";

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      selectedTabId,
      potentialValues,
      quartiles,
      dataFieldsLegend,
      selectTabId,
      save,
    } = this.props;
    let noCohortsSelected = true;
    if (
      potentialValues !== undefined &&
      Object.entries(potentialValues).length !== 0
    ) {
      noCohortsSelected = false;
    }
    return (
      <div className="statistics-container">
        <Tabs
          id="TabsExample"
          onChange={(newTabId) => selectTabId(newTabId)}
          selectedTabId={selectedTabId}
        >
          <Tab
            id="legend-tab"
            title="Legend"
            panel=<LegendTabPanel includePatient={false}>
              <div className="expression-heatmap-legend">
                <LabelWithInfo title="Heatmap Z-Score Legend" body={[]} />
                <Slider
                  min={-6}
                  max={6}
                  labelStepSize={2}
                  showTrackFill={false}
                />
              </div>
            </LegendTabPanel>
            panelClassName="legend-container"
          />
          <Tab
            id="potential-values-tab"
            title="Potential Values"
            panel=<PotentialValuesTabPanel
              noCohortsSelected={noCohortsSelected}
              potentialValues={potentialValues}
            />
            panelClassName="potential-values-container"
          />
          <Tab
            id="data-fields-legend-tab"
            title="Data Fields Legend"
            panel=<DataFieldsLegendTabPanel
              noCohortsSelected={noCohortsSelected}
              dataFieldsLegend={dataFieldsLegend}
            />
            panelClassName="data-field-legend-container"
          />
          <Tab
            id="cohort-creation-tab"
            title="Quartiles"
            panel={
              <div>
                <LabelWithInfo title="Cohorts By Quartiles" body={[]} />
                <div className="list">
                  {quartiles !== undefined &&
                  Object.keys(quartiles).length !== 0 ? (
                    Object.entries(quartiles).map(
                      ([cohortName, quartilesLevelTwo]) => (
                        <div key={cohortName}>
                          <Text className="cohort-label" ellipsize>
                            <Icon icon="person" />
                            {cohortName.replace("<br>", " ")}
                          </Text>
                          {quartilesLevelTwo.map(
                            ([typeLabel, quartilesLevelThree]) => (
                              <div className="cohort-container" key={typeLabel}>
                                <Text>
                                  <Icon
                                    icon={legendSymbols[typeLabel]}
                                    style={{
                                      color: legendColors[typeLabel],
                                    }}
                                  />
                                  {typeLabel}
                                </Text>
                                {Object.entries(quartilesLevelThree).map(
                                  ([hugoSymbol, quartilesArray]) => (
                                    <div
                                      className="push-right"
                                      key={hugoSymbol}
                                    >
                                      <Text className="gene-label" ellipsize>
                                        <Icon icon="barcode" />
                                        {hugoSymbol}
                                      </Text>
                                      <ButtonGroup className="buttons" fill>
                                        <Button
                                          small
                                          text="Q1"
                                          onClick={() =>
                                            save(
                                              quartilesArray[0].map(
                                                (id) => id.split("+")[0]
                                              ),
                                              quartilesArray[0].map(
                                                (id) => id.split("+")[1]
                                              ),
                                              "Expression",
                                              [typeLabel, hugoSymbol, "Q1"]
                                            )
                                          }
                                        />
                                        <Button
                                          small
                                          text="Q2"
                                          onClick={() =>
                                            save(
                                              quartilesArray[1].map(
                                                (id) => id.split("+")[0]
                                              ),
                                              quartilesArray[1].map(
                                                (id) => id.split("+")[1]
                                              ),
                                              "Expression",
                                              [typeLabel, hugoSymbol, "Q2"]
                                            )
                                          }
                                        />
                                        <Button
                                          small
                                          text="Q3"
                                          onClick={() =>
                                            save(
                                              quartilesArray[2].map(
                                                (id) => id.split("+")[0]
                                              ),
                                              quartilesArray[2].map(
                                                (id) => id.split("+")[1]
                                              ),
                                              "Expression",
                                              [hugoSymbol, "Q3"]
                                            )
                                          }
                                        />
                                        <Button
                                          small
                                          text="Q4"
                                          onClick={() =>
                                            save(
                                              quartilesArray[3].map(
                                                (id) => id.split("+")[0]
                                              ),
                                              quartilesArray[3].map(
                                                (id) => id.split("+")[1]
                                              ),
                                              "Expression",
                                              [typeLabel, hugoSymbol, "Q4"]
                                            )
                                          }
                                        />
                                        <Button
                                          small
                                          icon="multi-select"
                                          onClick={() => {
                                            save(
                                              quartilesArray[0].map(
                                                (id) => id.split("+")[0]
                                              ),
                                              quartilesArray[0].map(
                                                (id) => id.split("+")[1]
                                              ),
                                              "Expression",
                                              [typeLabel, hugoSymbol, "Q1"]
                                            );
                                            save(
                                              quartilesArray[1].map(
                                                (id) => id.split("+")[0]
                                              ),
                                              quartilesArray[1].map(
                                                (id) => id.split("+")[1]
                                              ),
                                              "Expression",
                                              [typeLabel, hugoSymbol, "Q2"]
                                            );
                                            save(
                                              quartilesArray[2].map(
                                                (id) => id.split("+")[0]
                                              ),
                                              quartilesArray[2].map(
                                                (id) => id.split("+")[1]
                                              ),
                                              "Expression",
                                              [typeLabel, hugoSymbol, "Q3"]
                                            );
                                            save(
                                              quartilesArray[3].map(
                                                (id) => id.split("+")[0]
                                              ),
                                              quartilesArray[3].map(
                                                (id) => id.split("+")[1]
                                              ),
                                              "Expression",
                                              [typeLabel, hugoSymbol, "Q4"]
                                            );
                                          }}
                                        />
                                      </ButtonGroup>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}
                        </div>
                      )
                    )
                  ) : (
                    <Text>Nothing to display.</Text>
                  )}
                </div>
              </div>
            }
            panelClassName="quartiles-container"
          />
          <Tab
            id="statistics-tab"
            title="Statistics"
            panel={<FeatureUnderConstruction description="" />}
            panelClassName="statistics-panel-container"
          />
          <Tabs.Expander />
        </Tabs>
      </div>
    );
  }
}

Statistics.propTypes = {
  selectedTabId: PropTypes.string.isRequired,
  potentialValues: PropTypes.object.isRequired,
  quartiles: PropTypes.object.isRequired,
  dataFieldsLegend: PropTypes.object.isRequired,
  selectTabId: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default Statistics;
