import React from "react";
import PropTypes from "prop-types";
import Plotly from "plotly.js-cartesian-dist";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

class HEDMatrixPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frames: [],
      config: {},
      className: "ioexplorer-plot hla",
      style: { display: "block" },
      useResizeHandler: true,
    };
  }

  render() {
    const { data, layout, revision } = this.props;
    const { frames, config, className, style, useResizeHandler } = this.state;
    return (
      <Plot
        data={data}
        layout={layout}
        frames={frames}
        config={config}
        revision={revision}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
        className={className}
        style={style}
        useResizeHandler={useResizeHandler}
      />
    );
  }
}

HEDMatrixPlot.propTypes = {
  data: PropTypes.array.isRequired,
  layout: PropTypes.object.isRequired,
  revision: PropTypes.number.isRequired,
};

export default HEDMatrixPlot;
