import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Router, Route } from "react-router-dom";
import App from "../containers/App";
import history from "./myHistory";

const Root = ({ store }) => (
  <Provider store={store}>
    <Router history={history} forceRefresh={false}>
      <Route path="/:module?" component={App} />
    </Router>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
