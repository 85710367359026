import React from "react";
import PropTypes from "prop-types";
import { Radio, RadioGroup } from "@blueprintjs/core";

const PCADomainRadio = ({ pcaDomain, selectPcaDomain }) => (
  <div>
    <RadioGroup
      onChange={(e) => selectPcaDomain(e.target.value)}
      selectedValue={pcaDomain}
    >
      <Radio label="Individual" value="individual" />
      <Radio label="Matched" value="same" />
    </RadioGroup>
  </div>
);

PCADomainRadio.propTypes = {
  pcaDomain: PropTypes.string.isRequired,
  selectPcaDomain: PropTypes.func.isRequired,
};

export default PCADomainRadio;
