import { defaultTutorial } from "../defaultStates";

const tutorial = (state = defaultTutorial, action) => {
  switch (action.type) {
    case "SET_TUTORIAL":
      return action.value;
    case "CHOOSE_TUTORIAL":
      return {
        ...state,
        currentTutorial: action.tutorialIdx,
        didTutorialStart: false,
        currentStep: 0,
      };
    case "START_TUTORIAL":
      return {
        ...state,
        didTutorialStart: true,
      };
    case "STOP_TUTORIAL":
      return {
        ...state,
        didTutorialStart: false,
      };
    case "ADVANCE_TUTORIAL":
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case "RECEDE_TUTORIAL":
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case "RESET_TUTORIAL":
      return {
        ...state,
        currentStep: 0,
      };
    case "SKIP_TO_END_OF_TUTORIAL":
      return {
        ...state,
        currentStep: action.lastStep,
      };
    case "TOGGLE_RESTORE_STATE_ON_STOP":
      return {
        ...state,
        restoreStateOnStop: !state.restoreStateOnStop,
      };
    default:
      return state;
  }
};

export default tutorial;
