import React from "react";
import PropTypes from "prop-types";
import { Tree } from "@blueprintjs/core";

const changeOneNode = (nodes, id, prop, value) => {
  if (nodes !== undefined) {
    return nodes.map((node, _unused) => {
      if (node.id === id) {
        return {
          ...node,
          [prop]: value,
        };
      }
      return {
        ...node,
        childNodes: changeOneNode(node.childNodes, id, prop, value),
      };
    });
  }
  return undefined;
};

const changeAllNodes = (nodes, prop, value) => {
  if (nodes !== undefined) {
    return nodes.map((node, _unused) => ({
      ...node,
      [prop]: value,
      childNodes: changeAllNodes(node.childNodes, prop, value),
    }));
  }
  return undefined;
};

class TutorialTree extends React.Component {
  constructor(props) {
    super(props);
    const { tutorialTree } = this.props;
    this.state = {
      nodes: tutorialTree.map((t) => ({ ...t, isExpanded: true })),
    };
    this.handleNodeClick = this.handleNodeClick.bind(this);
    this.handleNodeExpand = this.handleNodeExpand.bind(this);
    this.handleNodeCollapse = this.handleNodeCollapse.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { nodes } = state;
    const { didTutorialStart } = props;
    return { nodes: changeAllNodes(nodes, "disabled", didTutorialStart) };
  }

  handleNodeClick(node) {
    const { currentTutorialIdx, chooseTutorial } = this.props;
    const { nodes } = this.state;
    const { childNodes, id } = node;
    let newNodes;
    if (childNodes === undefined && id !== currentTutorialIdx) {
      newNodes = changeAllNodes(nodes, "isSelected", false);
      newNodes = changeOneNode(newNodes, node.id, "isSelected", true);
      this.setState({ nodes: newNodes });
      chooseTutorial(id);
    }
  }

  handleNodeCollapse(node) {
    const { nodes } = this.state;
    const newNodes = changeOneNode(nodes, node.id, "isExpanded", false);
    this.setState({ nodes: newNodes });
  }

  handleNodeExpand(node) {
    const { nodes } = this.state;
    const newNodes = changeOneNode(nodes, node.id, "isExpanded", true);
    this.setState({ nodes: newNodes });
  }

  render() {
    const { nodes } = this.state;
    return (
      <Tree
        className="tutorial-list"
        contents={nodes}
        onNodeClick={(node) => this.handleNodeClick(node)}
        onNodeCollapse={(node) => this.handleNodeCollapse(node)}
        onNodeExpand={(node) => this.handleNodeExpand(node)}
      />
    );
  }
}

TutorialTree.propTypes = {
  tutorialTree: PropTypes.array.isRequired,
  currentTutorialIdx: PropTypes.number.isRequired,
  didTutorialStart: PropTypes.bool.isRequired,
  chooseTutorial: PropTypes.func.isRequired,
};

export default TutorialTree;
