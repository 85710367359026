import {
  NavigationMapping,
  defaultTreatmentTree,
  presetTreatmentTreeMapping,
} from "../constants";
import {
  defaultDistribution,
  defaultCorrelation,
  defaultMutation,
  defaultExpression,
  defaultHLA,
  defaultSurvival,
} from "../defaultStates";
import {
  valeroNSCLCIds,
  valeroNSCLCICITMBQ1Ids,
  valeroNSCLCICITMBQ2Ids,
  valeroNSCLCICITMBQ3Ids,
  valeroNSCLCICITMBQ1Q3Ids,
  valeroNSCLCICITMBQ4Ids,
  // valeroNSCLCNonICITMBQ1Ids,
  // valeroNSCLCNonICITMBQ2Ids,
  // valeroNSCLCNonICITMBQ3Ids,
  valeroNSCLCNonICITMBQ1Q3Ids,
  valeroNSCLCNonICITMBQ4Ids,
} from "./tutorialIds";

export const TutorialTreeStructure = [
  { tutorialIdx: 0 },
  // { tutorialIdx: 1 },
  {
    name: "Study",
    icon: "book",
    childNodes: [{ tutorialIdx: 2 }],
  },
  // {
  //   name: "Analysis",
  //   icon: "calculator",
  //   childNodes: [
  //     { tutorialIdx: 3 },
  //     { tutorialIdx: 4 },
  //     { tutorialIdx: 5 },
  //     { tutorialIdx: 6 },
  //     { tutorialIdx: 7 },
  //     { tutorialIdx: 8 },
  //   ],
  // },
];

const vanAllen = {
  id: "VanAllen-2015-Science",
  name: "VanAllen-2015-Science",
  patientIds: [],
  sampleIds: [],
  mapping: {
    applyToPatients: true,
    treatmentTree: presetTreatmentTreeMapping["VanAllen-2015-Science"],
    useFixedSamples: false,
  },
  source: "Published",
  tags: [],
  isSelected: false,
  isPublished: true,
  isHidden: false,
  isHighlighted: false,
};

const riaz = {
  id: "Riaz-2017-Cell",
  name: "Riaz-2017-Cell",
  patientIds: [],
  sampleIds: [],
  mapping: {
    applyToPatients: true,
    treatmentTree: presetTreatmentTreeMapping["Riaz-2017-Cell"],
    useFixedSamples: false,
  },
  source: "Published",
  tags: [],
  isSelected: false,
  isPublished: true,
  isHidden: false,
  isHighlighted: false,
};

const hugo = {
  id: "Hugo-2016-Cell",
  name: "Hugo-2016-Cell",
  mapping: {
    applyToPatients: true,
    treatmentTree: defaultTreatmentTree,
    useFixedSamples: false,
  },
  source: "Published",
  tags: [],
  isSelected: false,
  isPublished: true,
  isHidden: false,
  isHighlighted: false,
};

const rizvi = {
  id: "Rizvi-2015-Science",
  name: "Rizvi-2015-Science",
  mapping: {
    applyToPatients: true,
    treatmentTree: defaultTreatmentTree,
    useFixedSamples: false,
  },
  source: "Published",
  tags: [],
  isSelected: false,
  isPublished: true,
  isHidden: false,
  isHighlighted: false,
};

const snyder = {
  id: "Snyder-2014-NEJM",
  name: "Snyder-2014-NEJM",
  mapping: {
    applyToPatients: true,
    treatmentTree: defaultTreatmentTree,
    useFixedSamples: false,
  },
  source: "Published",
  tags: [],
  isSelected: false,
  isPublished: true,
  isHidden: false,
  isHighlighted: false,
};

const valero = {
  id: "Valero-2021-NatGen",
  name: "Valero-2021-NatGen",
  patientIds: [],
  sampleIds: [],
  mapping: {
    applyToPatients: true,
    treatmentTree: presetTreatmentTreeMapping["Valero-2021-NatGen"],
    useFixedSamples: false,
  },
  source: "Published",
  tags: [],
  isSelected: false,
  isPublished: true,
  isHidden: false,
  isHighlighted: false,
};

const defaultCohort = {
  nextId: 0,
  published: [vanAllen, riaz, valero],
  pending: [],
  saved: [],
};

const defaultUi = {
  version: "v2",
  isSelectionPanelOpen: false,
  isTutorialPanelOpen: true,
  whichMappingPopoverOpen: "",
  landingPageKey: "",
  pathname: NavigationMapping.HOME,
  useRedirect: true,
};

const defaultTutorialTooltips = {
  navigationBar: { isOpen: false, content: "", intent: "" },
  sessionButton: { isOpen: false, content: "", intent: "" },
  cohortPanelButton: { isOpen: false, content: "", intent: "" },
  samplesMappingButton: { isOpen: false, content: "", intent: "" },
  commentsButton: { isOpen: false, content: "", intent: "" },
  duplicateButton: { isOpen: false },
  unionButton: { isOpen: false },
  intersectionButton: { isOpen: false },
  distributionSourcePicker: { isOpen: false, content: "", intent: "" },
  distributionDataFieldPicker: { isOpen: false, content: "", intent: "" },
  correlationSourcePicker: { isOpen: false, content: "", intent: "" },
  correlationFirstDataFieldPicker: { isOpen: false, content: "", intent: "" },
  correlationSecondDataFieldPicker: {
    isOpen: false,
    content: "",
    intent: "",
  },
  correlationDataFieldSwapper: { isOpen: false, content: "", intent: "" },
  expressionSourcePicker: { isOpen: false, content: "", intent: "" },
  expressionTopTrackDataFieldPicker: {
    isOpen: false,
    content: "",
    intent: "",
  },
  survivalSourcePicker: { isOpen: false, content: "", intent: "" },
};

export const TutorialList = [
  {
    name: "Getting Started",
    icon: "home",
    descriptions: [
      [
        "Welcome to the Getting Started tutorial.",
        "This will teach you the basics of IOExplorer.",
        "First we will cover how to navigate the site. Then, we will show you how to work with cohorts. Finally, we will show you how to generate a plot.",
        "Use the player above to step through this tutorial. If you click outside of this panel, it will move out of view but the tutorial will still be running.",
        "To open or close the Tutorial panel, click on the Tutorial button in the upper right hand corner of the site. You can also use the `Shift + T` hotkey to toggle the panel.",
        "Click the `Forward` button on the player above to advance to the next step. Please note that the tutorial will complete any necessary tasks when you move between steps.",
      ],
      [
        "Hello again!",
        "We are glad we did not lose you. Congratulations, you have made it to the second step of the Getting Started tutorial and you are on your way to mastering IOExplorer.",
        "Let's learn how to navigate the site.",
        "At the top of the site there is a navigation bar that has links to each analysis module. These modules are where you can craft and view various plots. For now, we have six modules: Distribution, Correlation, Expression, Mutation, HLA Divergence and Survival.",
        "When moving between different analysis modules, have no fear of losing progress. The selections that you make in each module will be saved with your session.",
        "Furthermore, the state of your session is saved and stored so that when you reload this page, with the same URL, you are right back to where you left off. Feel free to try it now. See you on the other side.",
        "For more information about the current session, use the Session menu at the top right of the site.",
        "Now that we are more familiar with the layout of the site, let's learn about how to use this powerful analysis tool.",
      ],
      [
        "We will use the Cohorts panel (the one that just opened up on the left hand side of the site) when working with cohorts.",
        "You can open and close the Cohorts panel using the button labeled `Cohorts` in the upper right hand corner of the site. You can also use the `Shift + X` hotkey to toggle the panel.",
        "The cohorts panel houses your cohorts and the tools you will use to work with them. It is your workbench and your toolkit.",
        "Cohorts are a collection of patients and related samples. As the basic organizational unit of analysis, cohorts are used to populate both plots and statistical tests on IOExplorer.",
        "There are two main types of cohorts: published cohorts, and saved cohorts.",
        "Published cohorts are compiled with great care from immunotherapy studies and loaded automatically with each session.",
        "Saved cohorts are created by you, the user, as you move through an analysis flow. Starting with only published cohorts, you will be able to combine, filter, and partition them to fit your use case.",
        "To see what we can do with a cohort, let's select two published cohorts: VanAllen-2015-Science and Riaz-2017-Cell. (Remember that the tutorial will complete this task for you when you move to the next step.)",
      ],
      [
        "When a cohort is selected, the card turns green and all of its patients and pre-treatment samples are used as the source for the analysis at hand. For more information about patients, samples, and how they relate, use the Beginner Cohorts tutorial to learn more.",
        "We are ready to visualize our selected cohort. Let's move to an analysis module.",
      ],
      [
        "Welcome to the Distribution module.",
        "Here, we can plot the distributions of clinical, demographic, and pipeline-derived data fields.",
        "There are currently two parameters for the Distribution plot: cohort stratification and data field.",
        "Cohort stratification determines the how the pool of patients and samples, provided by the selected cohorts, are used in the plot. There are currently two options: a single union of all patients and samples or a variable number of mutually exclusive partitions based on cohort overlap. This field is available for all analysis modules.",
        "The data field option controls which data field will be plotted. There are separate data fields for patients and samples so pay close attention to what is selected here. The selection of one data field will give rise to a plot featuring patient data while the selection of another will lead to a plot with sample data.",
        "Let's keep the stratification as `Single Union` and choose the `Three-Type Response` data field. Briefly, this data field encodes the patients' response to ICI treatment as one of three values: NonResponder, Stable, or Responder.",
      ],
      [
        "We have arrived at out first plot.",
        "With the right combination of elements, i.e. selected cohorts, and valid plot parameters, the plot will populate automatically. Furthermore, upon any relevant changes or tweeks to the plot source or the plot parameters, the plot will reload to stay up-to-the-instant accurate.",
        "As we can see in the plot, though there are two cohorts selected, there is only one collection of patients and samples. Due to our choice of `Single Union,` the patients from both VanAllen-2015-Science and Raiz-2017-Cell are pooled as one collection and plotted as such.",
        "If we wanted to compare these two cohorts, we would need to select the `Mutually Exclusive Partitions` stratification option. It should be noted that any patients or samples that fall in more than one cohort are assigned to their own partition group (mutual exclusion). Thus, there may be more partition groups than selected cohorts featured on the plot.",
        "Let's compare the distribution of the current data field for these two cohorts by changing the cohort stratification parameter.",
      ],
      [
        "What has changed about this plot?",
        "Instead of one row, there are two: one for each of the mutually exclusive partitions. This is useful when comparing cohorts or when looking for patterns.",
      ],
      [
        "Congratulations!",
        "You have completed the Getting Started tutorial.",
        "Before we let you go, we should tell you about our feedback system. At the bottom right of the site, there is a feedback button. Use this to leave us messages, preferably both descriptive and constructive, and you can help direct us to what needs to be fixed or tell us about features that would be useful to you.",
        "Note that your feedback will be bundled with your session so we can pick up right where you left off.",
        "Learn more through other tutorials or head right into starting an analysis.",
      ],
    ],
    states: [
      {
        cohort: {
          ...defaultCohort,
          published: [vanAllen, riaz, valero],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [vanAllen, riaz, valero],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: {
          ...defaultTutorialTooltips,
          navigationBar: {
            isOpen: true,
            content: "Analysis Modules",
            intent: "primary",
          },
          sessionButton: {
            isOpen: true,
            content: "Session Menu",
            intent: "primary",
          },
        },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [vanAllen, riaz, valero],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
        },
        tutorialTooltips: {
          ...defaultTutorialTooltips,
          cohortPanelButton: {
            isOpen: true,
            content: "Cohort Panel Button",
            intent: "primary",
          },
        },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            riaz,
            valero,
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            riaz,
            valero,
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
          scrollIntoViewId: "distribution-data-field-picker",
        },
        tutorialTooltips: {
          ...defaultTutorialTooltips,
          distributionSourcePicker: {
            isOpen: true,
            content: "Stratification",
            intent: "primary",
            position: "top",
          },
          distributionDataFieldPicker: {
            isOpen: true,
            content: "Data Field",
            intent: "primary",
            position: "bottom",
          },
        },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            {
              ...riaz,
              isSelected: true,
            },
            valero,
          ],
        },
        distribution: {
          ...defaultDistribution,
          dataField: "three_type_response",
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            {
              ...riaz,
              isSelected: true,
            },
            valero,
          ],
        },
        distribution: {
          ...defaultDistribution,
          source: "saved-cohorts-intersection",
          dataField: "three_type_response",
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [vanAllen, riaz, valero],
        },
        correlation: { ...defaultCorrelation },
        distribution: { ...defaultDistribution },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: {
          ...defaultTutorialTooltips,
          commentsButton: {
            isOpen: true,
            content: "Feedback",
            intent: "primary",
            position: "left",
          },
        },
      },
    ],
  },
  {
    name: "Beginner Cohorts",
    icon: "person",
    descriptions: [
      [
        "Welcome to the Beginner Cohorts tutorial.",
        "This will teach you the basics of working with cohorts.",
        "We will learn how to save cohorts. Then, we will practice combining cohorts through set-like operations.",
        "Click the `Forward` button on the player above to advance to the next step. Please note that the tutorial will complete any necessary tasks when you move between steps.",
      ],
      [
        "Before we dive in, let's answer some basic questions you may have about cohorts.",
        "Q: What is a cohort?",
        "A: A cohort is a collection of patients. It is used throughout the IOExplorer system as the basic unit of analysis.",
        "Q: Where do cohorts come from?",
        "A: The IOExplorer system comes with pre-loaded cohorts called published cohorts. These are collections of patients compiled from immunotherapy studies that are pre-processed for intuitive analysis and seamless comparison.",
        "Q: How do I start working with cohorts?",
        "A: Each new session begins with published cohorts. Users can select these cohorts, move through modules, and generate plots and statistics. From there, users can save new cohorts using set-like operations (union and intersection), through established cohort partitioning (quartiles and value groups) or by interactions with plots (box-selections and lassos).",
        "Let's begin by selecting published cohorts for analysis. We will choose the VanAllen-2015-Science cohort.",
      ],
      [
        "The cohorts panel holds both the published cohorts and the user-saved cohorts.",
        "Each cohort is represented by a cohort card which displays relevant information about the cohort. The card contains the name of the cohort and how many patients and samples are contained in the cohort. If a cohort has a verified symbol on its card, it is a published cohort. As you may have already gleaned, cohort cards turn green when they are selected.",
        "Though there is much more to learn about cohorts and their cards, let's begin a simple analysis to get a feel for a cohort's use.",
        "Our goal in this analysis will be to compare the survival curves for patients with age and tumor mutation burden (TMB) in the fourth quartile against all others within the VanAllen-2015-Science published cohort.",
      ],
      [
        "We are now in the Distribution module. Here, we can plot the distributions of clinical, demographic, and pipeline-derived data fields. This will be helpful with partitioning our cohort to create `cohorts of interest`. In our case, there are two `cohorts of interest`: one containing the patients in the top quartile for both Age and TMB and another containing all of the other patients from the VanAllen-2015-Science cohort.",
        "Though we have already selected a cohort, we still need to choose a data field to create a plot. Let's start with the Age field.",
      ],
      [
        "Here, we see the distribution of the patients' ages in the VanAllen-2015-Science cohort.",
        "One way to save cohorts is to partition the selected data field along well established cut-offs. For numerical data fields, the partitioning is done at quartile cut-offs. By clicking on one of the buttons on the top right panel of the Distribution module, a new cohort will be saved based the labelled partition.",
        "For example, by clicking the `Q1` button, all of the patients whose Age value is in the first quartile will be saved as a new cohort. Further, by clicking the `All Quartiles` button, all of the patients will be saved to one of four new cohorts, one for each quartile of the Age data field.",
        "Let's choose the `All Quartiles` option. (Remember that the tutorial will carry out this task when you reach the next step.)",
      ],
      [
        "Whenever a new cohort is saved, it briefly goes through a pending stage. This is so that users can make changes to or cancel the cohort before it is confirmed.",
        "At this juncture, you have the option to change the cohort's name, to modulate it's patient-sample mapping, and to add or remove tags.",
        "Once we are satisfied with our pending cohort, we can confirm it.",
        "Since we are satisfied with all of these cohorts, we will confirm them all.",
      ],
      [
        "Now that these cohorts have been confirmed, they are placed with the rest of the cohorts in the cohort panel.",
        "To complete our goal, we must find a way to compare patients in the top quartile to those that are not in the top quartile for both Age and TMB.",
        "Though we have a cohort that contains the top quartile for age, we do not have one that contains all other quartiles together.",
        "To create such a cohort, we should use the `Union` operation. This will create a new cohort that combines the selected cohorts in a union-like fashion.",
        "To do this, we must select only the cohorts we would like to operate on and then click the `Union` button at the bottom of the cohort panel.",
      ],
      [
        "While our new cohort is pending, let's give it a name to help stay organized as we continue our analysis. We will use `Age Q1-Q3`.",
      ],
      [
        "If we ever need to change the name of a cohort that has already been saved, we can use the `expand` icon to open a more informative dialog. Let's rename `cohort3` to `Age Q4`.",
        "We can also use this dialog to remove a cohort. Let's do so with `cohort0`, `cohort1`, and `cohort2` since we do not need them for this analysis any longer.",
      ],
      [
        "We have created the appropriate cohorts for the Age data field but we are missing those for the TMB data field. We can create them the same way we did for the Age data field. (The tutorial will complete this task.)",
      ],
      [
        "Now we have four saved cohorts: `Age Q1-Q3`, `Age Q4`, `TMB Q1-Q3`, and `TMB Q4`. To create a cohort with patients in both `Age Q4` and `TMB Q4`, we must use the `Intersection` operation. Additionally, we can take the union of `Age Q1-Q3` and `TMB Q1-Q3` to create the `all other patients` cohort.",
      ],
      [
        "With our cohorts in place, we move to the Survival module, select our cohorts of interest, and view the resulting plot.",
        "Though our survival curves do not seem to diverage at all, this analysis is not in vain. We learned a great deal about the use of cohorts in the IOExplorer system.",
      ],
      [
        "Congratulations!",
        "You have completed the Beginner Cohorts tutorial.",
        "Learn more through other tutorials or use your newfound knowledge to start an analysis.",
      ],
    ],
    states: [
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            riaz,
            rizvi,
            hugo,
            snyder,
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            riaz,
            rizvi,
            hugo,
            snyder,
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
          scrollIntoViewId: "distribution-data-field-picker",
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            riaz,
            rizvi,
            hugo,
            snyder,
          ],
        },
        distribution: {
          ...defaultDistribution,
          dataField: "age",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
          scrollIntoViewId: "distribution-data-field-picker",
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            riaz,
            rizvi,
            hugo,
            snyder,
          ],
          pending: [
            {
              createdAt: 0,
              id: 0,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort0",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 1,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort1",
              patientIds: [
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q2"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 2,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort2",
              patientIds: [
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "9d2d2c28",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q3"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 3,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort3",
              patientIds: [
                "322093d8",
                "888d2e4d",
                "09e23207",
                "50423879",
                "3aa1dc46",
                "bd507a01",
                "fd80b1fd",
                "72080cb6",
                "1564e33f",
                "cd183fab",
                "d0cf78b8",
                "3b47a548",
                "ad11cb68",
                "dad3fde1",
                "40ad9df1",
                "368f3be3",
                "a7e66a6d",
                "d1cb8efb",
                "288b398a",
                "674ccd29",
                "17470ee2",
                "7e08af54",
                "78780a49",
                "25706040",
                "47c2ca91",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "4d4f8819",
                "9fcaa233",
                "afc0a9b7",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q4"],
              isPublished: false,
            },
          ],
        },
        distribution: {
          ...defaultDistribution,
          dataField: "age",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [
            {
              ...vanAllen,
              isSelected: true,
            },
            riaz,
            rizvi,
            hugo,
            snyder,
          ],
          saved: [
            {
              createdAt: 0,
              id: 0,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort0",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 1,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort1",
              patientIds: [
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q2"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 2,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort2",
              patientIds: [
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "9d2d2c28",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q3"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 3,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort3",
              patientIds: [
                "322093d8",
                "888d2e4d",
                "09e23207",
                "50423879",
                "3aa1dc46",
                "bd507a01",
                "fd80b1fd",
                "72080cb6",
                "1564e33f",
                "cd183fab",
                "d0cf78b8",
                "3b47a548",
                "ad11cb68",
                "dad3fde1",
                "40ad9df1",
                "368f3be3",
                "a7e66a6d",
                "d1cb8efb",
                "288b398a",
                "674ccd29",
                "17470ee2",
                "7e08af54",
                "78780a49",
                "25706040",
                "47c2ca91",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "4d4f8819",
                "9fcaa233",
                "afc0a9b7",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q4"],
              isPublished: false,
            },
          ],
        },
        distribution: {
          ...defaultDistribution,
          dataField: "age",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          pending: [
            {
              createdAt: 0,
              id: 4,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort4",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "9d2d2c28",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1", "Q2", "Q3"],
              isPublished: false,
            },
          ],
          saved: [
            {
              createdAt: 0,
              id: 0,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort0",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 1,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort1",
              patientIds: [
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q2"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 2,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort2",
              patientIds: [
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "9d2d2c28",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q3"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 3,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort3",
              patientIds: [
                "322093d8",
                "888d2e4d",
                "09e23207",
                "50423879",
                "3aa1dc46",
                "bd507a01",
                "fd80b1fd",
                "72080cb6",
                "1564e33f",
                "cd183fab",
                "d0cf78b8",
                "3b47a548",
                "ad11cb68",
                "dad3fde1",
                "40ad9df1",
                "368f3be3",
                "a7e66a6d",
                "d1cb8efb",
                "288b398a",
                "674ccd29",
                "17470ee2",
                "7e08af54",
                "78780a49",
                "25706040",
                "47c2ca91",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "4d4f8819",
                "9fcaa233",
                "afc0a9b7",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q4"],
              isPublished: false,
            },
          ],
          selectedIds: new Set([0, 1, 2]),
        },
        distribution: {
          ...defaultDistribution,
          dataField: "age",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          saved: [
            {
              createdAt: 0,
              id: 0,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort0",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 1,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort1",
              patientIds: [
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q2"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 2,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort2",
              patientIds: [
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "9d2d2c28",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q3"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 3,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "cohort3",
              patientIds: [
                "322093d8",
                "888d2e4d",
                "09e23207",
                "50423879",
                "3aa1dc46",
                "bd507a01",
                "fd80b1fd",
                "72080cb6",
                "1564e33f",
                "cd183fab",
                "d0cf78b8",
                "3b47a548",
                "ad11cb68",
                "dad3fde1",
                "40ad9df1",
                "368f3be3",
                "a7e66a6d",
                "d1cb8efb",
                "288b398a",
                "674ccd29",
                "17470ee2",
                "7e08af54",
                "78780a49",
                "25706040",
                "47c2ca91",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "4d4f8819",
                "9fcaa233",
                "afc0a9b7",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q4"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 4,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age Q1-Q3",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "9d2d2c28",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1", "Q2", "Q3"],
              isPublished: false,
            },
          ],
          highlightedId: 3,
        },
        distribution: {
          ...defaultDistribution,
          dataField: "age",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          saved: [
            {
              createdAt: 0,
              id: 3,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age Q4",
              patientIds: [
                "322093d8",
                "888d2e4d",
                "09e23207",
                "50423879",
                "3aa1dc46",
                "bd507a01",
                "fd80b1fd",
                "72080cb6",
                "1564e33f",
                "cd183fab",
                "d0cf78b8",
                "3b47a548",
                "ad11cb68",
                "dad3fde1",
                "40ad9df1",
                "368f3be3",
                "a7e66a6d",
                "d1cb8efb",
                "288b398a",
                "674ccd29",
                "17470ee2",
                "7e08af54",
                "78780a49",
                "25706040",
                "47c2ca91",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "4d4f8819",
                "9fcaa233",
                "afc0a9b7",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q4"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 4,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age Q1-Q3",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "9d2d2c28",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1", "Q2", "Q3"],
              isPublished: false,
            },
          ],
        },
        distribution: {
          ...defaultDistribution,
          dataField: "age",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          saved: [
            {
              createdAt: 0,
              id: 3,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age Q4",
              patientIds: [
                "322093d8",
                "888d2e4d",
                "09e23207",
                "50423879",
                "3aa1dc46",
                "bd507a01",
                "fd80b1fd",
                "72080cb6",
                "1564e33f",
                "cd183fab",
                "d0cf78b8",
                "3b47a548",
                "ad11cb68",
                "dad3fde1",
                "40ad9df1",
                "368f3be3",
                "a7e66a6d",
                "d1cb8efb",
                "288b398a",
                "674ccd29",
                "17470ee2",
                "7e08af54",
                "78780a49",
                "25706040",
                "47c2ca91",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "4d4f8819",
                "9fcaa233",
                "afc0a9b7",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q4"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 4,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age Q1-Q3",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "9d2d2c28",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1", "Q2", "Q3"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 5,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "TMB Q4",
              patientIds: [
                "55511279",
                "9285429e",
                "50423879",
                "3aa1dc46",
                "0d7c2f4f",
                "cd183fab",
                "d82d1d5c",
                "2e57ddc2",
                "3b47a548",
                "a3c9c9d6",
                "0e4f50bf",
                "6614115b",
                "d4bbd9fd",
                "dad3fde1",
                "9d2d2c28",
                "a7e66a6d",
                "288b398a",
                "6e6cea5d",
                "275b1d47",
                "9c1bef43",
                "25706040",
                "47c2ca91",
                "76924e55",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "ed0feabf",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["TMB", "Q4"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 6,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "TMB Q1-Q3",
              patientIds: [
                "09e23207",
                "7d9ca9cf",
                "ed28e365",
                "daa6c28e",
                "44aadd8e",
                "3e9f7a0e",
                "6ce132fe",
                "56752f76",
                "ad11cb68",
                "93e04187",
                "502ec04e",
                "201930e3",
                "7f336d3f",
                "3aac7682",
                "40ad9df1",
                "1123db23",
                "777f2910",
                "10f8a927",
                "5334bf1b",
                "674ccd29",
                "c804c6e7",
                "90b47ddb",
                "18b42678",
                "2b0157bc",
                "e7f18fa9",
                "425ac2a4",
                "e99e80e4",
                "7516063e",
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "5f70f748",
                "75830bc7",
                "4c5697e7",
                "34906448",
                "72080cb6",
                "1564e33f",
                "d0cf78b8",
                "720b047a",
                "57e47bb2",
                "2167c8a4",
                "b74793ff",
                "1bd66e0e",
                "d1cb8efb",
                "99f7ce61",
                "f3015318",
                "17470ee2",
                "8286e6a5",
                "7e08af54",
                "4d4f8819",
                "9fcaa233",
                "713bf3d0",
                "dfdd7cb5",
                "afc0a9b7",
                "fba52965",
                "76ba8aa5",
                "322093d8",
                "888d2e4d",
                "4a522c39",
                "578ba44a",
                "8cfcf074",
                "b2004556",
                "bd507a01",
                "fd80b1fd",
                "5dbd4d07",
                "c39a1a8a",
                "2521199b",
                "bde51f97",
                "63e8877f",
                "735765db",
                "f66dc5b8",
                "d74a49d1",
                "368f3be3",
                "7a7a6ec1",
                "88c8ebf6",
                "78780a49",
                "f17813e4",
                "6d039a46",
                "073d0f9f",
                "20a5fca0",
                "85fe3bb4",
                "1f0384a3",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["TMB", "Q1", "Q2", "Q3"],
              isPublished: false,
            },
          ],
        },
        distribution: {
          ...defaultDistribution,
          dataField: "age",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.DISTRIBUTION,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          saved: [
            {
              createdAt: 0,
              id: 3,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age Q4",
              patientIds: [
                "322093d8",
                "888d2e4d",
                "09e23207",
                "50423879",
                "3aa1dc46",
                "bd507a01",
                "fd80b1fd",
                "72080cb6",
                "1564e33f",
                "cd183fab",
                "d0cf78b8",
                "3b47a548",
                "ad11cb68",
                "dad3fde1",
                "40ad9df1",
                "368f3be3",
                "a7e66a6d",
                "d1cb8efb",
                "288b398a",
                "674ccd29",
                "17470ee2",
                "7e08af54",
                "78780a49",
                "25706040",
                "47c2ca91",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "4d4f8819",
                "9fcaa233",
                "afc0a9b7",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q4"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 4,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age Q1-Q3",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "9d2d2c28",
                "85fe3bb4",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "Q1", "Q2", "Q3"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 5,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "TMB Q4",
              patientIds: [
                "55511279",
                "9285429e",
                "50423879",
                "3aa1dc46",
                "0d7c2f4f",
                "cd183fab",
                "d82d1d5c",
                "2e57ddc2",
                "3b47a548",
                "a3c9c9d6",
                "0e4f50bf",
                "6614115b",
                "d4bbd9fd",
                "dad3fde1",
                "9d2d2c28",
                "a7e66a6d",
                "288b398a",
                "6e6cea5d",
                "275b1d47",
                "9c1bef43",
                "25706040",
                "47c2ca91",
                "76924e55",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
                "ed0feabf",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["TMB", "Q4"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 6,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "TMB Q1-Q3",
              patientIds: [
                "09e23207",
                "7d9ca9cf",
                "ed28e365",
                "daa6c28e",
                "44aadd8e",
                "3e9f7a0e",
                "6ce132fe",
                "56752f76",
                "ad11cb68",
                "93e04187",
                "502ec04e",
                "201930e3",
                "7f336d3f",
                "3aac7682",
                "40ad9df1",
                "1123db23",
                "777f2910",
                "10f8a927",
                "5334bf1b",
                "674ccd29",
                "c804c6e7",
                "90b47ddb",
                "18b42678",
                "2b0157bc",
                "e7f18fa9",
                "425ac2a4",
                "e99e80e4",
                "7516063e",
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "5f70f748",
                "75830bc7",
                "4c5697e7",
                "34906448",
                "72080cb6",
                "1564e33f",
                "d0cf78b8",
                "720b047a",
                "57e47bb2",
                "2167c8a4",
                "b74793ff",
                "1bd66e0e",
                "d1cb8efb",
                "99f7ce61",
                "f3015318",
                "17470ee2",
                "8286e6a5",
                "7e08af54",
                "4d4f8819",
                "9fcaa233",
                "713bf3d0",
                "dfdd7cb5",
                "afc0a9b7",
                "fba52965",
                "76ba8aa5",
                "322093d8",
                "888d2e4d",
                "4a522c39",
                "578ba44a",
                "8cfcf074",
                "b2004556",
                "bd507a01",
                "fd80b1fd",
                "5dbd4d07",
                "c39a1a8a",
                "2521199b",
                "bde51f97",
                "63e8877f",
                "735765db",
                "f66dc5b8",
                "d74a49d1",
                "368f3be3",
                "7a7a6ec1",
                "88c8ebf6",
                "78780a49",
                "f17813e4",
                "6d039a46",
                "073d0f9f",
                "20a5fca0",
                "85fe3bb4",
                "1f0384a3",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["TMB", "Q1", "Q2", "Q3"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 7,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age/TMB Q4",
              patientIds: [
                "50423879",
                "3aa1dc46",
                "cd183fab",
                "3b47a548",
                "dad3fde1",
                "a7e66a6d",
                "288b398a",
                "25706040",
                "47c2ca91",
                "9c0ed99f",
                "cbb7bc7a",
                "8f507918",
                "2b4d0b67",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "TMB", "Q4"],
              isPublished: false,
            },
            {
              createdAt: 0,
              id: 8,
              mapping: {
                type: "treatment",
                treatment: ["pre"],
                time: ["first"],
                fixed: ["fixed"],
                applyToPatients: true,
              },
              name: "Age/TMB not Q4",
              patientIds: [
                "7c64e556",
                "b23683a1",
                "014fd9a5",
                "7d9ca9cf",
                "5f70f748",
                "ed28e365",
                "daa6c28e",
                "75830bc7",
                "44aadd8e",
                "4c5697e7",
                "3e9f7a0e",
                "34906448",
                "6ce132fe",
                "720b047a",
                "d82d1d5c",
                "2521199b",
                "bde51f97",
                "502ec04e",
                "735765db",
                "6e6cea5d",
                "88c8ebf6",
                "9c1bef43",
                "f17813e4",
                "073d0f9f",
                "18b42678",
                "2b0157bc",
                "20a5fca0",
                "e7f18fa9",
                "76ba8aa5",
                "b2004556",
                "0d7c2f4f",
                "2e57ddc2",
                "56752f76",
                "0e4f50bf",
                "93e04187",
                "2167c8a4",
                "b74793ff",
                "201930e3",
                "f66dc5b8",
                "d4bbd9fd",
                "3aac7682",
                "d74a49d1",
                "777f2910",
                "10f8a927",
                "f3015318",
                "8286e6a5",
                "76924e55",
                "713bf3d0",
                "dfdd7cb5",
                "1f0384a3",
                "425ac2a4",
                "ed0feabf",
                "fba52965",
                "e99e80e4",
                "7516063e",
                "55511279",
                "4a522c39",
                "578ba44a",
                "9285429e",
                "8cfcf074",
                "5dbd4d07",
                "c39a1a8a",
                "a3c9c9d6",
                "57e47bb2",
                "6614115b",
                "63e8877f",
                "1bd66e0e",
                "7f336d3f",
                "1123db23",
                "99f7ce61",
                "5334bf1b",
                "7a7a6ec1",
                "275b1d47",
                "c804c6e7",
                "90b47ddb",
                "6d039a46",
                "9d2d2c28",
                "85fe3bb4",
                "09e23207",
                "ad11cb68",
                "40ad9df1",
                "674ccd29",
                "72080cb6",
                "1564e33f",
                "d0cf78b8",
                "d1cb8efb",
                "17470ee2",
                "7e08af54",
                "4d4f8819",
                "9fcaa233",
                "afc0a9b7",
                "322093d8",
                "888d2e4d",
                "bd507a01",
                "fd80b1fd",
                "368f3be3",
                "78780a49",
              ],
              sampleIds: [],
              fixedSampleIds: [],
              source: "Distribution",
              tags: ["Age", "TMB", "Q1", "Q2", "Q3"],
              isPublished: false,
            },
          ],
          selectedIds: new Set([7, 8]),
        },
        distribution: {
          ...defaultDistribution,
          dataField: "age",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          pathname: NavigationMapping.SURVIVAL,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
    ],
  },
  {
    name: "Valero-2021-NatGen",
    icon: "symbol-triangle-up",
    descriptions: [
      [
        "Within the context of cancer immune checkpoint inhibitor (ICI) treatment, the association between high tumor mutation burden (TMB) and improved survival is well documented. Outside of ICI, however, any association has been poorly understood.",
        "In a paper recently published in Nature Genetics (2021), Valero et al. examine more than ten thousand patients to disentangle the association between TMB and survival not only within ICI-treated patients, but also among patients prior to, or never treated with immunotherapy.",
        "The results show that, conversely to the ICI context, the survival of high TMB, non-ICI patients was not only not improved, but for many cancer types was significantly diminished.",
        "In this tutorial, we explore the results of Valero et al. using the IOExplorer platform. Although the detailed model results cannot be reproduced by the current version of IOExplorer, we are able to approximate them as follows. We walk through two comparisons within non-small cell lung cancer (NSCLC). First, we show overall survival (OS) of high- (top quartile) vs. low-TMB (bottom three quartiles) samples within the ICI-treated context. Second, we show OS between high- vs. low-TMB samples in non-ICI.",
        "We move step-by-step through the creation of these cohorts of interest, finally reaching the two OS comparisons. The steps shown here can be used as the basis for using IOExplorer to examine the effect of other attributes of interest, such as other cancer types, MSI, etc., on survival.",
      ],
      [
        "To understand this paper, it is critical to grasp how overall survival (OS) is measured in this cohort.",
        "The table shown here gives the treatment breakdown of patients: ICI-treated (n=2022), both pre-treatment and on/post-treatment and non-ICI-treated (n=8211) or ICI naive.",
        "For the patients that are categorized as non-ICI-treated, the survival time is the time from diagnosis to death/censor. In contrast, the patients that are categorized as ICI-treated, the survival time is the time from ICI treatment to death/censor. Furthermore, ICI-treated patients’ survival time does not include the time from diagnosis to ICI treatment start and ICI-treated patients may have had other lines of therapy that pre-date the ICI treatment.",
      ],
      [
        "Now that we are familiar with the cohort, we can begin our analysis.",
        "Because survival times differ among various cancer types, OS in the study’s model was stratified by cancer type. For this analysis, we will only use patients diagnosed with NSCLC, though it can be repeated with any cancer type shown here.",
        "The IOExplorer platform uses cohorts as the basic unit of analysis. Cohorts selected by the user populate the plots in each module. They can be created, combined, and partitioned so that they contain the exact patients and samples that the user desires.",
        "At this moment, we are interested in creating a new cohort that contains all of the patients with NSCLC in the Valero-2021-NatGen study. With this study already highlighted, we can click on the desired cancer type to create such a cohort.",
        "(The tutorial will complete this task as you move to the next step.)",
      ],
      [
        "While this cohort is pending, we can give it a descriptive name and confirm it.",
        "(Again, the tutorial will complete this task as you move to the next step.)",
      ],
      [
        "Now within the NSCLC cohort, we want to separate the ICI-treated patients from the non-ICI-treated patients.",
        "We will create two cohorts, one for each ICI treatment context.",
        "To change the composition of a cohort by treatment context, open up the Rx panel. From there, switch off the 'Use Fixed Samples' setting and pick a helpful preset. Finally, add or remove any rules to keep the samples of interest and filter out the rest.",
        "In this case, we will use the Valero-2021-NatGen preset, and remove patients/samples that have not had any ICI treatment (ICI Naive).",
      ],
      [
        "You can see now that this cohort is only 'showing' 737 patients and samples.",
        "Now that we have our ICI-treated cohort, we need to create out non-ICI cohort.",
        "Here, we will use the Duplicate feature and follow the same steps as before, but this time, we will keep only the patients/samples naive to ICI.",
      ],
      [
        "With a cohort for each treatment context on hand, ICI-treated and non-ICI-treated, it is time to partition each treatment-context cohort into high- and low-TMB cohorts.",
        "The authors of the paper chose to call the cohort with the top 20% TMB as high-TMB. Due to the constraints of the IOExplorer platform, we will use the top quartile (Q4) as high-TMB.",
        "To start this partition, we can create a cohort for each TMB quartile.",
      ],
      [
        "To combine the lower three TMB quartile cohorts into one, we can use the Union operator. This operator treats cohorts as sets and creates a new cohort by taking a union of all of the selected cohorts’ patients.",
        "Once this is complete, we can remove the cohorts that we do not need any longer for analysis. Then, we will repeat this process with the non-ICI-treated cohort.",
      ],
      [
        "With our cohorts complete, we can begin to compare the survival curves.",
        "First, we will compare the two ICI-treated cohorts: high- vs low-TMB.",
        "We can see that patients with a high-TMB have better OS than patients with a low-TMB.",
        "This finding matches that of this study and many previous studies.",
      ],
      [
        "Next, we can will compare the two non-ICI-treated cohorts.",
        "Here, we see that patients with a high TMB have worse OS than patients with a TMB below the high TMB threshold.",
        "This finding matches that of this study and demonstrates a novel finding for this study.",
      ],
      [
        "The analysis in this tutorial is quite limited compared to the comprehensive analysis performed in the Valero et al. study.",
        "We only looked at one cancer type and did not control for microsatellite instability (MSI), previous treatments, mutations to the DNA damage repair pathway, or significant mutational signatures, as done in the study.",
        "You can choose to complete the tutorial here and return to your saved state, or to use this state as a jumping off point to perform further analysis of this study.",
        "Thank you for joining us!",
      ],
    ],
    states: [
      {
        cohort: {
          ...defaultCohort,
          published: [vanAllen, riaz, valero],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "",
          pathname: NavigationMapping.HOME,
          useRedirect: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [vanAllen, riaz, valero],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "Valero-2021-NatGen",
          pathname: NavigationMapping.HOME,
          useRedirect: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          published: [vanAllen, riaz, { ...valero, isHighlighted: true }],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "",
          pathname: NavigationMapping.HOME,
          useRedirect: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          nextId: 1,
          published: [vanAllen, riaz, valero],
          pending: [
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_0",
              name: "Saved Cohort 0",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: true,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "",
          pathname: NavigationMapping.HOME,
          useRedirect: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          nextId: 1,
          published: [vanAllen, riaz, valero],
          saved: [
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_0",
              name: "NSCLC",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: true,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "Saved_Cohort_0",
          pathname: NavigationMapping.HOME,
          useRedirect: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          nextId: 1,
          published: [vanAllen, riaz, valero],
          saved: [
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_0",
              name: "NSCLC",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 1,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "Pre Unknown ICI",
                        nodeData: {
                          status: "PRE",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 2,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "On/Post Unknown ICI",
                        nodeData: {
                          status: "AFTER_START",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "Saved_Cohort_0",
          pathname: NavigationMapping.HOME,
          useRedirect: true,
        },
        tutorialTooltips: {
          ...defaultTutorialTooltips,
          duplicateButton: { isOpen: true },
        },
      },
      {
        cohort: {
          ...defaultCohort,
          nextId: 2,
          published: [vanAllen, riaz, valero],
          saved: [
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_0",
              name: "NSCLC ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 1,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "Pre Unknown ICI",
                        nodeData: {
                          status: "PRE",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 2,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "On/Post Unknown ICI",
                        nodeData: {
                          status: "AFTER_START",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_1",
              name: "NSCLC non-ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 3,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "ICI Naive",
                        nodeData: { status: "NAIVE", target: "NOT_APPLICABLE" },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
          ],
        },
        distribution: {
          source: "saved-cohorts-intersection",
          dataField: "tmb",
          scale: "log",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "",
          pathname: NavigationMapping.DISTRIBUTION,
          useRedirect: true,
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          nextId: 6,
          published: [vanAllen, riaz, valero],
          saved: [
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_0",
              name: "NSCLC ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 1,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "Pre Unknown ICI",
                        nodeData: {
                          status: "PRE",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 2,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "On/Post Unknown ICI",
                        nodeData: {
                          status: "AFTER_START",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_1",
              name: "NSCLC non-ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 3,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "ICI Naive",
                        nodeData: { status: "NAIVE", target: "NOT_APPLICABLE" },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_2",
              name: "NSCLC ICI TMB Q1",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ1Ids,
              sampleIds: valeroNSCLCICITMBQ1Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q1"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_3",
              name: "NSCLC ICI TMB Q2",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ2Ids,
              sampleIds: valeroNSCLCICITMBQ2Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q2"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_4",
              name: "NSCLC ICI TMB Q3",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ3Ids,
              sampleIds: valeroNSCLCICITMBQ3Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q3"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_5",
              name: "NSCLC ICI TMB Q4",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ4Ids,
              sampleIds: valeroNSCLCICITMBQ4Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q4"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
          ],
        },
        distribution: {
          source: "saved-cohorts-intersection",
          dataField: "tmb",
          scale: "log",
          ui: { selectedTabId: "cohort-creation-tab" },
        },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: true,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "",
          pathname: NavigationMapping.DISTRIBUTION,
          useRedirect: true,
          // scrollIntoViewCohortId: "Saved_Cohort_5",
        },
        tutorialTooltips: {
          ...defaultTutorialTooltips,
          unionButton: { isOpen: true },
        },
      },
      {
        cohort: {
          ...defaultCohort,
          nextId: 9,
          published: [vanAllen, riaz, valero],
          saved: [
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_0",
              name: "NSCLC ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 1,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "Pre Unknown ICI",
                        nodeData: {
                          status: "PRE",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 2,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "On/Post Unknown ICI",
                        nodeData: {
                          status: "AFTER_START",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_1",
              name: "NSCLC non-ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 3,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "ICI Naive",
                        nodeData: { status: "NAIVE", target: "NOT_APPLICABLE" },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_6",
              name: "NSCLC ICI TMB Q1-Q3",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ1Q3Ids,
              sampleIds: valeroNSCLCICITMBQ1Q3Ids,
              source: "Union",
              tags: ["NSCLC", "TMB", "Q1", "Q2", "Q3"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_5",
              name: "NSCLC ICI TMB Q4",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ4Ids,
              sampleIds: valeroNSCLCICITMBQ4Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q4"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_7",
              name: "NSCLC non-ICI TMB Q1-Q3",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCNonICITMBQ1Q3Ids,
              sampleIds: valeroNSCLCNonICITMBQ1Q3Ids,
              source: "Union",
              tags: ["NSCLC", "TMB", "Q1", "Q2", "Q3"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_8",
              name: "NSCLC non-ICI TMB Q4",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCNonICITMBQ1Q3Ids,
              sampleIds: valeroNSCLCNonICITMBQ4Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q4"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: {
          useConfidenceInterval: false,
          confidenceInterval: "0.95",
          logrankWeighting: "wilcoxon",
          ui: { selectedTabId: "statistics-tab" },
        },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: false,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "",
          pathname: NavigationMapping.SURVIVAL,
          useRedirect: true,
          // scrollIntoViewCohortId: "Saved_Cohort_8",
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          nextId: 9,
          published: [vanAllen, riaz, valero],
          saved: [
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_0",
              name: "NSCLC ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 1,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "Pre Unknown ICI",
                        nodeData: {
                          status: "PRE",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 2,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "On/Post Unknown ICI",
                        nodeData: {
                          status: "AFTER_START",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_1",
              name: "NSCLC non-ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 3,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "ICI Naive",
                        nodeData: { status: "NAIVE", target: "NOT_APPLICABLE" },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_6",
              name: "NSCLC ICI TMB Q1-Q3",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ3Ids,
              sampleIds: valeroNSCLCICITMBQ3Ids,
              source: "Union",
              tags: ["NSCLC", "TMB", "Q1", "Q2", "Q3"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_5",
              name: "NSCLC ICI TMB Q4",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ4Ids,
              sampleIds: valeroNSCLCICITMBQ4Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q4"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_7",
              name: "NSCLC non-ICI TMB Q1-Q3",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCNonICITMBQ1Q3Ids,
              sampleIds: valeroNSCLCNonICITMBQ1Q3Ids,
              source: "Union",
              tags: ["NSCLC", "TMB", "Q1", "Q2", "Q3"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_8",
              name: "NSCLC non-ICI TMB Q4",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCNonICITMBQ4Ids,
              sampleIds: valeroNSCLCNonICITMBQ4Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q4"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: {
          useConfidenceInterval: false,
          confidenceInterval: "0.95",
          logrankWeighting: "wilcoxon",
          ui: { selectedTabId: "statistics-tab" },
        },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: false,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "",
          pathname: NavigationMapping.SURVIVAL,
          useRedirect: true,
          // scrollIntoViewCohortId: "Saved_Cohort_8",
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: {
          ...defaultCohort,
          nextId: 9,
          published: [vanAllen, riaz, valero],
          saved: [
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_0",
              name: "NSCLC ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 1,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "Pre Unknown ICI",
                        nodeData: {
                          status: "PRE",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 2,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "On/Post Unknown ICI",
                        nodeData: {
                          status: "AFTER_START",
                          target: "UNKNOWN_ICI_TARGET",
                        },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_1",
              name: "NSCLC non-ICI",
              mapping: {
                applyToPatients: true,
                treatmentTree: [
                  {
                    disabled: false,
                    icon: "resolve",
                    id: 0,
                    hasCaret: true,
                    isExpanded: true,
                    isSelected: true,
                    label: "Logical OR",
                    nodeData: "op",
                    childNodes: [
                      {
                        disabled: false,
                        icon: "prescription",
                        id: 3,
                        hasCaret: false,
                        isExpanded: false,
                        isSelected: false,
                        label: "ICI Naive",
                        nodeData: { status: "NAIVE", target: "NOT_APPLICABLE" },
                        childNodes: [],
                      },
                    ],
                  },
                ],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCIds,
              sampleIds: valeroNSCLCIds,
              source: "Cancer Type",
              tags: ["NSCLC"],
              isSelected: false,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_6",
              name: "NSCLC ICI TMB Q1-Q3",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ1Q3Ids,
              sampleIds: valeroNSCLCICITMBQ1Q3Ids,
              source: "Union",
              tags: ["NSCLC", "TMB", "Q1", "Q2", "Q3"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_5",
              name: "NSCLC ICI TMB Q4",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCICITMBQ4Ids,
              sampleIds: valeroNSCLCICITMBQ4Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q4"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_7",
              name: "NSCLC non-ICI TMB Q1-Q3",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCNonICITMBQ1Q3Ids,
              sampleIds: valeroNSCLCNonICITMBQ1Q3Ids,
              source: "Union",
              tags: ["NSCLC", "TMB", "Q1", "Q2", "Q3"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
            {
              createdAt: 1605302403582,
              id: "Saved_Cohort_8",
              name: "NSCLC non-ICI TMB Q4",
              mapping: {
                applyToPatients: true,
                treatmentTree: [],
                useFixedSamples: false,
              },
              patientIds: valeroNSCLCNonICITMBQ4Ids,
              sampleIds: valeroNSCLCNonICITMBQ4Ids,
              source: "Distribution",
              tags: ["NSCLC", "TMB", "Q4"],
              isSelected: true,
              isPublished: false,
              isHidden: false,
              isHighlighted: false,
            },
          ],
        },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: {
          ...defaultUi,
          isSelectionPanelOpen: false,
          isTutorialPanelOpen: true,
          whichMappingPopoverOpen: "",
          pathname: NavigationMapping.SURVIVAL,
          useRedirect: true,
          // scrollIntoViewCohortId: "Saved_Cohort_8",
        },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
    ],
  },
  {
    name: "Intro to Distribution",
    icon: "timeline-bar-chart",
    descriptions: [["first"], ["second"], ["third"]],
    states: [
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
    ],
  },
  {
    name: "Intro to Correlation",
    icon: "scatter-plot",
    descriptions: [["first"], ["second"], ["third"]],
    states: [
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
    ],
  },
  {
    name: "Intro to Mutation",
    icon: "heat-grid",
    descriptions: [["first"], ["second"], ["third"]],
    states: [
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
    ],
  },
  {
    name: "Intro to Expression",
    icon: "grid",
    descriptions: [["first"], ["second"], ["third"]],
    states: [
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
    ],
  },
  {
    name: "Intro to HLA Divergence",
    icon: "heatmap",
    descriptions: [["first"], ["second"], ["third"]],
    states: [
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
    ],
  },
  {
    name: "Intro to Survival",
    icon: "lifesaver",
    descriptions: [["first"], ["second"], ["third"]],
    states: [
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
      {
        cohort: { ...defaultCohort },
        distribution: { ...defaultDistribution },
        correlation: { ...defaultCorrelation },
        mutation: { ...defaultMutation },
        expression: { ...defaultExpression },
        hla: { ...defaultHLA },
        survival: { ...defaultSurvival },
        ui: { ...defaultUi },
        tutorialTooltips: { ...defaultTutorialTooltips },
      },
    ],
  },
];

export const fillNode = (node, state) => {
  let t;
  const { tutorialIdx, name } = node;
  if (tutorialIdx !== undefined) {
    t = {
      ...TutorialList[tutorialIdx],
      id: tutorialIdx,
    };
  } else {
    t = {
      ...node,
      id: name,
    };
  }
  return {
    childNodes:
      t.childNodes === undefined
        ? undefined
        : t.childNodes.map((n) => fillNode(n, state)),
    disabled: state.tutorial.didTutorialStart,
    icon: t.icon,
    id: t.id,
    isSelected: t.id === state.tutorial.currentTutorial,
    label: t.name,
  };
};
