import { connect } from "react-redux";
import { selectCorrelationSource } from "../../actions";
import SourcePicker from "../../components/SourcePicker";

const mapStateToProps = (state) => ({
  source: state.correlation.present.source,
  sourceOptions: ["saved-cohorts-union", "saved-cohorts-intersection"],
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.correlationSourcePicker
      : state.tutorialTooltips.saved.correlationSourcePicker,
});

const mapDispatchToProps = (dispatch) => ({
  selectSource: (source) => dispatch(selectCorrelationSource(source)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SourcePicker);
