import { connect } from "react-redux";
import SourcePicker from "../../components/SourcePicker";

const mapStateToProps = (state) => ({
  source: "saved-cohorts-union",
  sourceOptions: ["saved-cohorts-union"],
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.hlaSourcePicker
      : state.tutorialTooltips.saved.hlaSourcePicker,
});

const mapDispatchToProps = () => ({
  selectSource: () => ({}),
});

export default connect(mapStateToProps, mapDispatchToProps)(SourcePicker);
