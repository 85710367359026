const tutorialTooltips = (
  state = {
    navigationBar: { isOpen: false, content: "", intent: "" },
    sessionButton: { isOpen: false, content: "", intent: "" },
    cohortPanelButton: { isOpen: false, content: "", intent: "" },
    samplesMappingButton: { isOpen: false, content: "", intent: "" },
    commentsButton: { isOpen: false, content: "", intent: "" },
    duplicateButton: { isOpen: false },
    unionButton: { isOpen: false },
    intersectionButton: { isOpen: false },
    distributionSourcePicker: { isOpen: false, content: "", intent: "" },
    distributionDataFieldPicker: { isOpen: false, content: "", intent: "" },
    correlationSourcePicker: { isOpen: false, content: "", intent: "" },
    correlationFirstDataFieldPicker: { isOpen: false, content: "", intent: "" },
    correlationSecondDataFieldPicker: {
      isOpen: false,
      content: "",
      intent: "",
    },
    correlationDataFieldSwapper: { isOpen: false, content: "", intent: "" },
    mutationSourcePicker: { isOpen: false, content: "", intent: "" },
    mutationTopTrackDataFieldPicker: {
      isOpen: false,
      content: "",
      intent: "",
    },
    expressionSourcePicker: { isOpen: false, content: "", intent: "" },
    expressionTopTrackDataFieldPicker: {
      isOpen: false,
      content: "",
      intent: "",
    },
    hlaSourcePicker: { isOpen: false, content: "", intent: "" },
    survivalSourcePicker: { isOpen: false, content: "", intent: "" },
  },
  _unused
) => state;

export default tutorialTooltips;
