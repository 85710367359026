import { updateSession } from "../actions/async";

const sessionUpdateMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case "START_TUTORIAL": // TUTORIAL
    case "STOP_TUTORIAL":
    case "TOGGLE_PUBLISHED": // PUBLISHED COHORTS
    case "SELECT_ALL_PUBLISHED_COHORTS":
    case "SAVE_COHORT": // SAVED COHORTS
    case "CONFIRM_COHORTS":
    case "RENAME_COHORT":
    case "REMOVE_COHORT":
    case "TOGGLE_COHORT":
    case "TOGGLE_USE_FIXED_SAMPLES":
    case "REMOVE_PENDING_COHORTS": // PENDING COHORTS
    case "RENAME_PENDING_COHORT":
    case "INSERT_TREATMENT_TREE_NODE": // ALL COHORTS
    case "REPLACE_TREATMENT_TREE_NODES":
    case "REMOVE_TREATMENT_TREE_NODE":
    case "CLEAR_TREATMENT_TREE_NODES":
    case "TOGGLE_APPLY_TO_PATIENTS":
    case "SELECT_ALL_SAVED_COHORTS":
    case "CLEAR_COHORT_SELECTION":
    case "HIGHLIGHT_COHORT":
    case "CLEAR_HIGHLIGHTED_COHORT":
    case "SELECT_DISTRIBUTION_SOURCE": // DISTRIBUTION
    case "SELECT_DISTRIBUTION_DATA_FIELD":
    case "SELECT_DISTRIBUTION_DATA_FIELD_SCALE":
    case "SELECT_DISTRIBUTION_TAB_ID":
    case "SELECT_CORRELATION_SOURCE": // CORRELATION
    case "SELECT_CORRELATION_FIRST_DATA_FIELD":
    case "SELECT_CORRELATION_SECOND_DATA_FIELD":
    case "SELECT_CORRELATION_FIRST_DATA_FIELD_SCALE":
    case "SELECT_CORRELATION_SECOND_DATA_FIELD_SCALE":
    case "SWAP_CORRELATION_DATA_FIELDS":
    case "SELECT_CORRELATION_TAB_ID":
    case "SELECT_MUTATION_SOURCE": // MUTATION
    case "ADD_MUTATION_GENE":
    case "ADD_MULTIPLE_MUTATION_GENES":
    case "SELECT_MUTATION_GENE_SET":
    case "REMOVE_MUTATION_GENE":
    case "CLEAR_MUTATION_GENES":
    case "SELECT_MUTATION_SORT_BY":
    case "SELECT_MUTATION_SORT_BY_GENE":
    case "ADD_MUTATION_DATA_FIELD":
    case "REMOVE_MUTATION_DATA_FIELD":
    case "CLEAR_MUTATION_DATA_FIELDS":
    case "SELECT_MUTATION_SORT_BY_DATA_FIELD":
    case "TOGGLE_MUTATION_DATA_FIELDS_WITH_LOG_SCALE":
    case "SELECT_MUTATION_TAB_ID":
    case "SELECT_EXPRESSION_SOURCE": // EXPRESSION
    case "ADD_EXPRESSION_GENE":
    case "ADD_MULTIPLE_EXPRESSION_GENES":
    case "SELECT_EXPRESSION_GENE_SET":
    case "REMOVE_EXPRESSION_GENE":
    case "CLEAR_EXPRESSION_GENES":
    case "SELECT_EXPRESSION_SORT_BY":
    case "SELECT_EXPRESSION_SORT_BY_GENE":
    case "ADD_EXPRESSION_DATA_FIELD":
    case "REMOVE_EXPRESSION_DATA_FIELD":
    case "CLEAR_EXPRESSION_DATA_FIELDS":
    case "SELECT_EXPRESSION_SORT_BY_DATA_FIELD":
    case "SELECT_EXPRESSION_SCALE":
    case "SELECT_EXPRESSION_NORMALIZE":
    case "TOGGLE_EXPRESSION_DATA_FIELDS_WITH_LOG_SCALE":
    case "SELECT_EXPRESSION_TAB_ID":
    case "TOGGLE_VOLCANO_DIALOG": // VOLCANO
    case "ADD_VOLCANO_GENE":
    case "ADD_MULTIPLE_VOLCANO_GENES":
    case "SELECT_VOLCANO_GENE_SET":
    case "REMOVE_VOLCANO_GENE":
    case "CLEAR_VOLCANO_GENES":
    case "TOGGLE_VOLCANO_DO_SHRINK_FOLD_CHANGE":
    case "SELECT_VOLCANO_COLORING":
    case "SELECT_VOLCANO_INTERACTION":
    case "SELECT_VOLCANO_PCA_DOMAIN":
    case "SELECT_HLA_PLOT_TAB_ID": // HLA
    case "SELECT_HLA_TAB_ID":
    case "TOGGLE_CONFIDENCE_INTERVAL": // SURVIVAL
    case "SELECT_CONFIDENCE_INTERVAL":
    case "SELECT_LOGRANK_WEIGHTING":
    case "SELECT_SURVIVAL_TAB_ID":
      store.dispatch(updateSession());
      break;
    default:
      break;
  }
  return result;
};

export default sessionUpdateMiddleware;
