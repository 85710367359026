import React from "react";
// import PropTypes from "prop-types";
import Chart from "../../containers/volcano/Chart";
import VolcanoChart from "../../containers/volcano/VolcanoChart";
import VolcanoTable from "../../containers/volcano/Table";

const ChartContainer = () => (
  <div id="volcano-chart-container">
    <div className="row" style={{ height: "30%" }}>
      <VolcanoChart />
      <VolcanoTable />
    </div>
    <div className="row" style={{ height: "70%" }}>
      <Chart />
    </div>
  </div>
);

ChartContainer.propTypes = {};

export default ChartContainer;
