import * as R from "ramda";
import { connect } from "react-redux";
import {
  getZeroIdx,
  getWithDefault,
  matchId,
  getDataAsTableTemplate,
} from "../../util";
import { saveCohort, clearHighlightedCohort } from "../../actions/cohorts";
import HighlightedPublished from "../../components/cohorts/HighlightedPublished";

const mapStateToProps = (state) => {
  const publishedCohorts = getWithDefault(
    ["cohort", "present", "published"],
    state,
    []
  );

  const anyTrue = R.any((e) => e);
  const someHighlighted = anyTrue(
    R.filter((c) => c.isHighlighted, publishedCohorts)
  );

  // Nothing highlighted, nothing to pass into component
  if (!someHighlighted) {
    return {};
  }

  let highlightedCohort = getZeroIdx(
    R.filter((c) => c.isHighlighted, publishedCohorts)
  );

  const { id } = highlightedCohort;

  highlightedCohort = R.mergeLeft(
    highlightedCohort,
    matchId(id, state.dataFetch.datasets.data)
  );

  const { name, title, authors, abstract, url } = highlightedCohort;

  const getDataAsTable = getDataAsTableTemplate(id, state);

  const cancerTypesTable = getDataAsTable("cancerTypes");

  const byCancerType = R.groupBy((r) => r.cancerType);
  const extractIds = (arr, key, _unused) => {
    const cancerTypeName = key;
    const allPatientIds = R.pluck("patientId", arr);
    const allSampleIds = R.pluck("sampleId", arr);
    const patientIds = R.uniq(allPatientIds);
    const sampleIds = R.uniq(allSampleIds);
    return { cancerTypeName, patientIds, sampleIds };
  };

  const cancerTypes = R.values(
    R.mapObjIndexed(extractIds, byCancerType(cancerTypesTable))
  );

  return {
    id,
    name,
    title,
    authors,
    abstract,
    cancerTypes,
    url,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(clearHighlightedCohort()),
  saveCohort: (pIds, sIds, s, t) => dispatch(saveCohort(pIds, sIds, s, t)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HighlightedPublished);
