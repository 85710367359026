import { connect } from "react-redux";
import { TutorialList } from "../tutorial/tutorialList";
import {
  setPathname,
  toggleSelectionPanel,
  toggleTutorialPanel,
  openSelectionPanel,
} from "../actions/ui";
import { removeToast, clearToast, chooseTutorial } from "../actions";
import { fetchSession, fetchData } from "../actions/async";
import App from "../components/App";

const mapStateToProps = (state, ownProps) => ({
  isTutorialActive: state.tutorial.didTutorialStart,
  isTutorialFinished:
    state.tutorial.currentStep ===
    TutorialList[state.tutorial.currentTutorial].states.length - 1,
  isSelectionPanelOpen: state.ui.present.isSelectionPanelOpen,
  isTutorialPanelOpen: state.ui.present.isTutorialPanelOpen,
  landingPageDialog: true,
  redirectPathname: state.ui.present.pathname,
  useRedirect: state.ui.present.useRedirect,
  currentPage: ownProps.location.pathname.substring(1),
  currentSearch: ownProps.location.search,
  toast: state.toast.toast,
  tooltips:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present
      : state.tutorialTooltips.saved,
});

const mapDispatchToProps = (dispatch) => ({
  setPathname: (pathname) => dispatch(setPathname(pathname)),
  toggleSelectionPanelTwo: () => dispatch(toggleSelectionPanel()),
  toggleTutorialPanelTwo: () => dispatch(toggleTutorialPanel()),
  openSelectionPanel: () => dispatch(openSelectionPanel()),
  removeToast: (id) => dispatch(removeToast(id)),
  clearToast: () => dispatch(clearToast()),
  chooseGettingStartedTutorial: () => dispatch(chooseTutorial(0)),
  fetchSession: () => dispatch(fetchSession()),
  fetchData: (endpoints) => dispatch(fetchData(endpoints)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
