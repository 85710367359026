import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import history from "./myHistory";

const ModuleLink = ({ pathname, children, superscript }) => (
  <NavLink
    exact
    to={{ pathname: `/${pathname}`, search: history.location.search }}
    activeStyle={{
      textDecoration: "none",
      color: "black",
    }}
  >
    {children}
    {superscript !== "" ? (
      <span
        style={{
          position: "relative",
          top: "-6px",
          left: "3px",
          color: "green",
          fontSize: "10px",
        }}
      >
        {superscript}
      </span>
    ) : (
      <div />
    )}
  </NavLink>
);

ModuleLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  superscript: PropTypes.string,
};

ModuleLink.defaultProps = {
  superscript: "",
};

export default ModuleLink;
