import { connect } from "react-redux";
import {
  addMutationGene,
  addMulitpleMutationGenes,
  removeMutationGene,
  clearMutationGenes,
  selectMutationGeneSet,
} from "../../actions";
import MutationGeneSelect from "../../components/GeneSelect";

const mapStateToProps = (state) => ({
  allGenes: state.dataFetch.allGenes.data.filter(
    (gene) => gene.type !== "ensembl"
  ),
  geneSets: state.dataFetch.geneSets.data,
  geneList: state.mutation.present.geneList,
  selectedGeneSet: state.mutation.present.geneSet,
});

const mapDispatchToProps = (dispatch) => ({
  addGene: (gene) => dispatch(addMutationGene(gene)),
  addMultipleGenes: (geneList) => dispatch(addMulitpleMutationGenes(geneList)),
  removeGene: (gene) => dispatch(removeMutationGene(gene)),
  clearGenes: () => dispatch(clearMutationGenes()),
  selectGeneSet: (geneSet) => dispatch(selectMutationGeneSet(geneSet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MutationGeneSelect);
