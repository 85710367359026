import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

const Chart = ({ data, options }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (data && options && ref.current) {
      let svg = d3.select(ref.current); // eslint-disable-line

      const originaldiffExprData = data[4];
      const cohortNames = data[7];
      const userSpecifiedGenesArray = data[8];

      // Extract user-specified genes, unique cohort names, unique source names
      const userSpecifiedGenes = new Set(userSpecifiedGenesArray);

      /* Set margins and dimentions */
      const height = 300;
      const width = 300;

      svg
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("viewBox", `0 0 ${width} ${height}`);

      /* Set margins and dimentions */
      const margin = { top: 15, right: 40, bottom: 40, left: 40 };
      const dim = {
        volcano: { x: 0, y: 0, h: height, w: width },
      };

      /* Volcano */

      // Switch -log10PAdj to positive value
      const diffExprData = originaldiffExprData.map((d) => ({
        ...d,
        negLog10PAdj: -d["-log10PAdj"],
      }));

      // Get log fold change furthest from 0
      const furthestLogFoldChange = d3.max([
        Math.abs(d3.min(diffExprData.map((d) => d.log2FoldChange))),
        Math.abs(d3.max(diffExprData.map((d) => d.log2FoldChange))),
      ]);

      const topNegLog10PAdj = d3.max(diffExprData.map((d) => d.negLog10PAdj));

      const scaleVolcanoX = d3
        .scaleLinear()
        .domain(
          furthestLogFoldChange !== undefined
            ? [-furthestLogFoldChange, furthestLogFoldChange]
            : [-5, 5]
        )
        .range([
          dim.volcano.x + margin.left,
          dim.volcano.x + dim.volcano.w - margin.right,
        ]);

      const scaleVolcanoY = d3
        .scaleLinear()
        .domain(topNegLog10PAdj !== undefined ? [0, topNegLog10PAdj] : [0, 10])
        .range([
          dim.volcano.y + dim.volcano.h - margin.bottom,
          dim.volcano.y + margin.top,
        ]);

      const xAxisLogFoldChange = (g) =>
        g
          .attr(
            "transform",
            `translate(0,${dim.volcano.y + dim.volcano.h - margin.bottom})`
          )
          .call(d3.axisBottom(scaleVolcanoX).ticks(5).tickSizeOuter(0));

      const yAxisNegLogPValue = (g) =>
        g
          .attr(
            "transform",
            `translate(${dim.volcano.x + margin.left - 2},${dim.volcano.y})`
          )
          .call(d3.axisLeft(scaleVolcanoY).ticks(5).tickSizeOuter(0));

      svg
        .selectAll("g.volcano")
        .selectAll("circle")
        .data(diffExprData)
        .join("circle")
        .attr("fill", (d) =>
          userSpecifiedGenes.has(d.symbol) ? "orange" : "gray"
        )
        .attr("fill-opacity", (d) =>
          userSpecifiedGenes.has(d.symbol) ? 0.7 : 0.3
        )
        .attr("r", 5)
        .attr("cx", (d) => scaleVolcanoX(d.log2FoldChange))
        .attr("cy", (d) => scaleVolcanoY(d.negLog10PAdj));

      // Volcano labels
      const coordinates = diffExprData.map((d) => [
        scaleVolcanoX(d.log2FoldChange),
        scaleVolcanoY(d.negLog10PAdj),
      ]);
      const delaunay = d3.Delaunay.from(coordinates);
      const voronoi = delaunay.voronoi([
        margin.left,
        margin.top,
        dim.volcano.w - margin.right,
        dim.volcano.h - margin.bottom,
      ]);
      const cells = coordinates
        .map((d, i) => [d, voronoi.cellPolygon(i), diffExprData[i].symbol])
        .filter(
          ([_unused, cell]) =>
            cell !== null && Math.abs(d3.polygonArea(cell)) > 400
        );

      svg
        .selectAll("g.volcano-voronoi-lines")
        .attr("stroke", "rosybrown")
        .selectAll("path")
        .data(cells)
        .join("path")
        .attr("d", ([d, cell]) => `M${d3.polygonCentroid(cell)}L${d}`)
        .style("opacity", 0.4);

      svg
        .selectAll("g.volcano-voronoi-labels")
        .selectAll("text")
        .data(cells)
        .join("text")
        .attr(
          "x",
          ([_unused, cell, symbol]) =>
            d3.polygonCentroid(cell)[0] - 3 * symbol.length
        )
        .attr("y", ([_unused, cell]) => d3.polygonCentroid(cell)[1] + 2)
        .text((d) => d[2])
        .style("font-size", "8px");

      svg
        .selectAll("g.volcano-plot-marks")
        .selectAll("text.volcano-title")
        .remove();
      if (cohortNames.length === 2) {
        svg
          .selectAll("g.volcano-plot-marks")
          .append("text")
          .data([`${cohortNames[0]} vs ${cohortNames[1]}`])
          .join("text")
          .attr("class", "volcano-title")
          .attr("x", dim.volcano.x + dim.volcano.w / 2)
          .attr("y", dim.volcano.y + 4)
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "central")
          .text((d) => d)
          .style("font-size", "12px");
      }

      svg
        .selectAll("g.volcano-plot-marks")
        .selectAll("text.volcano-x-axis-title")
        .remove();
      svg
        .selectAll("g.volcano-plot-marks")
        .append("text")
        .data(["log2 Fold Change"])
        .join("text")
        .attr("class", "volcano-x-axis-title")
        .attr("x", dim.volcano.x + dim.volcano.w / 2)
        .attr("y", dim.volcano.y + dim.volcano.h - margin.bottom / 2.75)
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "central")
        .text((d) => d)
        .style("font-size", "8px");

      svg
        .selectAll("g.volcano-plot-marks")
        .selectAll("text.volcano-y-axis-title")
        .remove();
      svg
        .selectAll("g.volcano-plot-marks")
        .append("text")
        .data(["-log10 p value"])
        .join("text")
        .attr("class", "volcano-y-axis-title")
        .attr("x", dim.volcano.x + margin.left / 2.75)
        .attr("y", dim.volcano.y + dim.volcano.h / 2.2)
        .attr(
          "transform",
          `rotate(-90, ${dim.volcano.x + margin.left / 2.75}, ${
            dim.volcano.y + dim.volcano.h / 2.2
          })`
        )
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "central")
        .text((d) => d)
        .style("font-size", "8px");

      if (diffExprData.length > 0) {
        svg
          .selectAll("g.volcano-lines")
          .selectAll("line")
          .data([
            { x1: -2, y1: 0, x2: -2, y2: topNegLog10PAdj },
            { x1: 2, y1: 0, x2: 2, y2: topNegLog10PAdj },
            {
              x1: -furthestLogFoldChange,
              y1: 2,
              x2: furthestLogFoldChange,
              y2: 2,
            },
          ])
          .join("line")
          .attr("x1", (d) => scaleVolcanoX(d.x1))
          .attr("y1", (d) => scaleVolcanoY(d.y1))
          .attr("x2", (d) => scaleVolcanoX(d.x2))
          .attr("y2", (d) => scaleVolcanoY(d.y2))
          .attr("stroke", "black")
          .attr("stroke-width", 0.5)
          .attr("stroke-dasharray", "6 4");
      }
      svg.selectAll("g.x-axis-volcano").call(xAxisLogFoldChange);
      svg.selectAll("g.y-axis-volcano").call(yAxisNegLogPValue);
    }
  }, [data, options]);

  return (
    <div style={{ height: "100%", width: "30%" }}>
      <svg ref={ref}>
        <g className="axis x-axis-volcano" />
        <g className="axis y-axis-volcano" />
        <g className="volcano-lines" />
        <g className="volcano-voronoi-lines" />
        <g className="volcano-voronoi-labels" />
        <g className="volcano-plot-marks" />
        <g className="volcano" />
      </svg>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
};

export default Chart;
