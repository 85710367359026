import React from "react";
import PropTypes from "prop-types";
import { NonIdealState } from "@blueprintjs/core";

const FeatureUnderConstruction = ({ description }) => (
  <NonIdealState
    icon="build"
    title="Feature Under Construction"
    description={description}
  />
);

FeatureUnderConstruction.propTypes = {
  description: PropTypes.string.isRequired,
};

export default FeatureUnderConstruction;
