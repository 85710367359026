import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Icon, Tab, Tabs, Text } from "@blueprintjs/core";
import { legendColors, legendSymbols } from "../../constants";
import FeatureUnderConstruction from "../FeatureUnderConstruction";
import LabelWithInfo from "../LabelWithInfo";
import DataFieldsLegendTabPanel from "../DataFieldsLegendTabPanel";
import LegendTabPanel from "../LegendTabPanel";
import PotentialValuesTabPanel from "../PotentialValuesTabPanel";

const oncoprintLegend = [
  [
    ["Multiple Variants", "rgb(0, 0, 0)"],
    ["", "white"],
  ],
  [
    ["Frame Shift Insertion", "rgb(90, 60, 133)"],
    ["Frame Shift Deletion", "rgb(156, 138, 181)"],
  ],
  [
    ["Nonsense Mutation", "rgb(52, 119, 180)"],
    ["Missense Mutation", "rgb(153, 187, 217)"],
  ],
  [
    ["In Frame Insertion", "rgb(22, 160, 9)"],
    ["In Frame Deletion", "rgb(115, 198, 107)"],
  ],
  [
    ["Splice Site", "rgb(240, 224, 130)"],
    ["", "white"],
  ],
  [
    ["No Variant", "rgb(204, 204, 204)"],
    ["", "white"],
  ],
];

const variantColorMap = {
  "Frame Shift Insertion": "rgb(90, 60, 133)",
  "Frame Shift Deletion": "rgb(156, 138, 181)",
  "Nonsense Mutation": "rgb(52, 119, 180)",
  "Missense Mutation": "rgb(153, 187, 217)",
  "In Frame Insertion": "rgb(22, 160, 9)",
  "In Frame Deletion": "rgb(115, 198, 107)",
  "Splice Site": "rgb(240, 224, 130)",
};

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      selectedTabId,
      potentialValues,
      variantGroups,
      dataFieldsLegend,
      selectTabId,
      save,
    } = this.props;
    let noCohortsSelected = true;
    if (
      potentialValues !== undefined &&
      Object.entries(potentialValues).length !== 0
    ) {
      noCohortsSelected = false;
    }
    return (
      <div className="statistics-container">
        <Tabs
          id="TabsExample"
          onChange={(newTabId) => selectTabId(newTabId)}
          selectedTabId={selectedTabId}
        >
          <Tab
            id="legend-tab"
            title="Legend"
            panel=<LegendTabPanel includePatient={false}>
              <div>
                <LabelWithInfo title="OncoPrint Legend" body={[]} />
                <div className="oncoprint-legend">
                  {oncoprintLegend.map((row) => (
                    <div className="column" key={row[0][0]}>
                      <Text>
                        <Icon icon="layer" style={{ color: row[0][1] }} />
                        {row[0][0]}
                      </Text>
                      <Text>
                        <Icon icon="layer" style={{ color: row[1][1] }} />
                        {row[1][0]}
                      </Text>
                    </div>
                  ))}
                </div>
              </div>
            </LegendTabPanel>
            panelClassName="legend-container"
          />
          <Tab
            id="potential-values-tab"
            title="Potential Values"
            panel=<PotentialValuesTabPanel
              noCohortsSelected={noCohortsSelected}
              potentialValues={potentialValues}
            />
            panelClassName="potential-values-container"
          />
          <Tab
            id="data-fields-legend-tab"
            title="Data Fields Legend"
            panel=<DataFieldsLegendTabPanel
              noCohortsSelected={noCohortsSelected}
              dataFieldsLegend={dataFieldsLegend}
            />
            panelClassName="data-field-legend-container"
          />
          <Tab
            id="cohort-creation-tab"
            title="Variants"
            panel={
              <div>
                <LabelWithInfo title="Cohorts By Variants" body={[]} />
                <div className="list">
                  {variantGroups !== undefined &&
                  Object.keys(variantGroups).length !== 0 ? (
                    Object.entries(variantGroups).map(
                      ([cohortName, variantGroupsLevelTwo]) => (
                        <div key={cohortName}>
                          <Text className="cohort-label" ellipsize>
                            <Icon icon="person" />
                            {cohortName.replace("<br>", " ")}
                          </Text>
                          {variantGroupsLevelTwo.map(
                            ([typeLabel, variantGroupsLevelThree]) => (
                              <div className="cohort-container" key={typeLabel}>
                                <Text>
                                  <Icon
                                    icon={legendSymbols[typeLabel]}
                                    style={{
                                      color: legendColors[typeLabel],
                                    }}
                                  />
                                  {typeLabel}
                                </Text>
                                {Object.entries(variantGroupsLevelThree).map(
                                  ([hugoSymbol, variantGroupsLevelFour]) => (
                                    <div
                                      className="push-right"
                                      key={hugoSymbol}
                                    >
                                      <Text className="gene-label" ellipsize>
                                        <Icon icon="barcode" />
                                        {hugoSymbol}
                                      </Text>
                                      <ButtonGroup className="buttons" vertical>
                                        {Object.entries(variantGroupsLevelFour)
                                          .sort(
                                            (firstEl, secondEl) =>
                                              secondEl[1].length -
                                              firstEl[1].length
                                          )
                                          .map(([variantName, ids]) => (
                                            <Button
                                              fill
                                              icon=<Icon
                                                icon="layer"
                                                style={{
                                                  color:
                                                    variantColorMap[
                                                      variantName
                                                    ],
                                                }}
                                              />
                                              key={variantName}
                                              onClick={() =>
                                                save(
                                                  ids.map(
                                                    (id) => id.split("+")[0]
                                                  ),
                                                  ids.map(
                                                    (id) => id.split("+")[1]
                                                  ),
                                                  "Mutation",
                                                  [
                                                    typeLabel,
                                                    hugoSymbol,
                                                    variantName,
                                                  ]
                                                )
                                              }
                                              small
                                              text={
                                                `${variantName} ` +
                                                `(${ids.length})`
                                              }
                                            />
                                          ))}
                                        <Button
                                          fill
                                          icon=<Icon
                                            icon="layers"
                                            iconSize="14"
                                            style={{ color: "gray" }}
                                          />
                                          onClick={() =>
                                            save(
                                              Object.entries(
                                                variantGroupsLevelFour
                                              )
                                                .map(([_unused, ids]) =>
                                                  ids.map(
                                                    (id) => id.split("+")[0]
                                                  )
                                                )
                                                .flat(),
                                              Object.entries(
                                                variantGroupsLevelFour
                                              )
                                                .map(([_unused, ids]) =>
                                                  ids.map(
                                                    (id) => id.split("+")[1]
                                                  )
                                                )
                                                .flat(),
                                              "Mutation",
                                              [typeLabel, hugoSymbol].concat(
                                                Object.entries(
                                                  variantGroupsLevelFour
                                                ).map(
                                                  ([variantName, _unused]) =>
                                                    variantName
                                                )
                                              )
                                            )
                                          }
                                          small
                                          text="Any Variant"
                                        />
                                      </ButtonGroup>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}
                        </div>
                      )
                    )
                  ) : (
                    <Text>Nothing to display.</Text>
                  )}
                </div>
              </div>
            }
            panelClassName="variants-container"
          />
          <Tab
            id="statistics-tab"
            title="Statistics"
            panel={<FeatureUnderConstruction description="" />}
            panelClassName="statistics-panel-container"
          />
          <Tabs.Expander />
        </Tabs>
      </div>
    );
  }
}

Statistics.propTypes = {
  selectedTabId: PropTypes.string.isRequired,
  potentialValues: PropTypes.object.isRequired,
  variantGroups: PropTypes.object.isRequired,
  dataFieldsLegend: PropTypes.object.isRequired,
  selectTabId: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default Statistics;
