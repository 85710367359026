import { connect } from "react-redux";
import VolcanoTable from "../../components/volcano/Table";

const mapStateToProps = (state) => ({
  data: state.dataFetch.batchCorrectedExpression.data.data,
  options: state.volcano.present.options,
});

const mapDispatchToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(VolcanoTable);
