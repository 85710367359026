import { connect } from "react-redux";
import {
  selectCorrelationSecondDataField,
  selectCorrelationSecondDataFieldScale,
} from "../../actions";
import CorrelationDataFieldPicker from "../../components/correlation/DataFieldPicker";

const mapStateToProps = (state) => ({
  firstOrSecond: "second",
  allFields: state.dataFetch.allDataFields.data,
  selectedField: state.correlation.present.secondDataField,
  scale: state.correlation.present.secondScale,
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.correlationSecondDataFieldPicker
      : state.tutorialTooltips.saved.correlationSecondDataFieldPicker,
});

const mapDispatchToProps = (dispatch) => ({
  selectDataField: (field) => dispatch(selectCorrelationSecondDataField(field)),
  selectScale: (scale) =>
    dispatch(selectCorrelationSecondDataFieldScale(scale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrelationDataFieldPicker);
