import { connect } from "react-redux";
import { selectExpressionNormalize } from "../../actions";
import ExpressionNormalizeSelect from "../../components/expression/NormalizeSelect";

const mapStateToProps = (state) => ({
  normalize: state.expression.present.normalize,
});

const mapDispatchToProps = (dispatch) => ({
  select: (normalize) => dispatch(selectExpressionNormalize(normalize)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionNormalizeSelect);
