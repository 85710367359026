import { connect } from "react-redux";
import { selectExpressionSource } from "../../actions";
import SourcePicker from "../../components/SourcePicker";

const mapStateToProps = (state) => ({
  source: state.expression.present.source,
  sourceOptions: ["saved-cohorts-union", "saved-cohorts-intersection"],
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.expressionSourcePicker
      : state.tutorialTooltips.saved.expressionSourcePicker,
});

const mapDispatchToProps = (dispatch) => ({
  selectSource: (source) => dispatch(selectExpressionSource(source)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SourcePicker);
