import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, ProgressBar, Tag, Text } from "@blueprintjs/core";
import MappingPopover from "../../containers/cohorts/MappingPopover";

const Saved = ({
  id,
  name,
  isSelected,
  counts,
  isFetchingTreatment,
  isFetchingDNACounts,
  isFetchingRNACounts,
  toggle,
  highlight,
  hide,
}) => (
  <div id={id} className="published-cohort">
    <div className={isSelected ? "element selected" : "element"}>
      <div className="buttons">
        <ButtonGroup minimal>
          <Button
            icon={isSelected ? "selection" : "circle"}
            onClick={() => toggle(id)}
            small
          />
        </ButtonGroup>
      </div>
      <div className="cohort-body">
        <div className="row title">
          <div className="column-10-12">
            <Text ellipsize>{name}</Text>
          </div>
        </div>
        <div className="row large">
          <div className="column-9-12">
            <Tag
              className={
                isFetchingTreatment ? "id-count bp3-skeleton" : "id-count"
              }
              fill
              large
              minimal
              icon="person"
            >
              {counts.visiblePatients}
            </Tag>
            <Tag
              className={
                isFetchingTreatment ? "id-count bp3-skeleton" : "id-count"
              }
              fill
              large
              minimal
              icon="lab-test"
            >
              {counts.visibleSamples}
            </Tag>
          </div>
          <div className="column-3-12">
            <ProgressBar
              animate={isFetchingDNACounts}
              stripes={isFetchingDNACounts}
              value={
                isFetchingDNACounts
                  ? 1 / 6
                  : counts.dnaSamples / counts.visibleSamples
              }
            />
            <ProgressBar
              animate={isFetchingRNACounts}
              stripes={isFetchingRNACounts}
              value={
                isFetchingRNACounts
                  ? 1 / 6
                  : counts.rnaSamples / counts.visibleSamples
              }
            />
          </div>
        </div>
      </div>
      <div className="buttons">
        <ButtonGroup minimal vertical>
          <Button icon="maximize" onClick={() => highlight(id)} small />
          <Button
            icon="minimize"
            onClick={() => hide(id)}
            small
            style={{ display: "none" }}
          />
          <MappingPopover id={id} />
        </ButtonGroup>
      </div>
    </div>
  </div>
);

Saved.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  counts: PropTypes.shape({
    visiblePatients: PropTypes.number.isRequired,
    visibleSamples: PropTypes.number.isRequired,
    allPatients: PropTypes.number.isRequired,
    allSamples: PropTypes.number.isRequired,
    dnaSamples: PropTypes.number.isRequired,
    rnaSamples: PropTypes.number.isRequired,
  }).isRequired,
  isFetchingTreatment: PropTypes.bool.isRequired,
  isFetchingDNACounts: PropTypes.bool.isRequired,
  isFetchingRNACounts: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  highlight: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

export default Saved;
