import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";

const DataFieldSwapper = ({
  firstDataField,
  secondDataField,
  tooltip,
  swap,
}) => (
  <div className="data-field-swapper-container">
    <Tooltip {...tooltip}>
      <div>
        <LabelWithInfo title="Swap Data Fields" body={[]} />
        <Button
          disabled={firstDataField === "" || secondDataField === ""}
          fill
          icon="swap-vertical"
          onClick={() => swap()}
          text="Swap"
        />
      </div>
    </Tooltip>
  </div>
);

DataFieldSwapper.propTypes = {
  firstDataField: PropTypes.string.isRequired,
  secondDataField: PropTypes.string.isRequired,
  tooltip: PropTypes.object.isRequired,
  swap: PropTypes.func.isRequired,
};

export default DataFieldSwapper;
