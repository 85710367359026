import { connect } from "react-redux";
import { selectCorrelationTabId } from "../../actions";
import { saveCohort } from "../../actions/cohorts";
import CorrelationStatistics from "../../components/correlation/Statistics";

const mapStateToProps = (state) => {
  const { data } = state.dataFetch.correlation;
  let firstSelectedField = {
    field: "",
    table: "",
    group: "",
    class: "",
    description: "",
  };
  let secondSelectedField = {
    field: "",
    table: "",
    group: "",
    class: "",
    description: "",
  };
  state.dataFetch.allDataFields.data.forEach((field) => {
    if (field.field === state.correlation.present.firstDataField) {
      firstSelectedField = field;
    }
  });
  state.dataFetch.allDataFields.data.forEach((field) => {
    if (field.field === state.correlation.present.secondDataField) {
      secondSelectedField = field;
    }
  });
  return {
    selectedTabId: state.correlation.present.ui.selectedTabId,
    firstField: firstSelectedField,
    secondField: secondSelectedField,
    potentialValues: data.potentialValues,
    quartilesContainer: data.quartiles,
    valueGroupsContainer: data.valueGroups,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectTabId: (id) => dispatch(selectCorrelationTabId(id)),
  save: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrelationStatistics);
