import { connect } from "react-redux";
import {
  selectExpressionSortBy,
  selectExpressionSortByGene,
  selectExpressionSortByDataField,
} from "../../actions";
import ExpressionSortBySelect from "../../components/expression/SortBySelect";

const mapStateToProps = (state) => ({
  geneList: state.expression.present.geneList,
  dataFields: state.expression.present.dataFields,
  sortBy: state.expression.present.sortBy,
  selectedGene: state.expression.present.selectedGene,
  allFields: state.dataFetch.allDataFields.data,
  selectedDataField: state.expression.present.selectedDataField,
});

const mapDispatchToProps = (dispatch) => ({
  selectGene: (gene) => dispatch(selectExpressionSortByGene(gene)),
  selectDataField: (dataField) =>
    dispatch(selectExpressionSortByDataField(dataField)),
  selectSortBy: (sortBy) => dispatch(selectExpressionSortBy(sortBy)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionSortBySelect);
