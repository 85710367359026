import React from "react";
import PropTypes from "prop-types";
import { Icon, Text } from "@blueprintjs/core";
import LabelWithInfo from "./LabelWithInfo";

const DataFieldsLegendTabPanel = ({ noCohortsSelected, dataFieldsLegend }) => (
  <div>
    <LabelWithInfo title="Data Fields Legend" body={[]} />
    <div className="list">
      {!noCohortsSelected ? (
        <div>
          {dataFieldsLegend.numerical.map((item) => (
            <Text className="numerical-data-field" key={item.name}>
              <Icon icon="full-circle" style={{ color: item.color }} />
              {item.name}
            </Text>
          ))}
          {dataFieldsLegend.categorical.map((item) => (
            <div key={item.name}>
              <Text className="categorical-data-field">
                <Icon icon="layers" />
                {item.name}
              </Text>
              {item.values.map((value) => (
                <Text className="categorical-value" key={value.name}>
                  <Icon icon="full-circle" style={{ color: value.color }} />
                  {value.name}
                </Text>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <Text>Nothing to display.</Text>
      )}
    </div>
  </div>
);

DataFieldsLegendTabPanel.propTypes = {
  noCohortsSelected: PropTypes.bool.isRequired,
  dataFieldsLegend: PropTypes.object.isRequired,
};

export default DataFieldsLegendTabPanel;
