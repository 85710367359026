import { connect } from "react-redux";
import { selectMutationTabId } from "../../actions";
import { saveCohort } from "../../actions/cohorts";
import MutationStatistics from "../../components/mutation/Statistics";

const mapStateToProps = (state) => {
  const { data } = state.dataFetch.mutation;
  return {
    selectedTabId: state.mutation.present.ui.selectedTabId,
    potentialValues: data.potentialValues,
    variantGroups: data.variantGroups,
    dataFieldsLegend: data.dataFieldsLegend,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectTabId: (id) => dispatch(selectMutationTabId(id)),
  save: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MutationStatistics);
