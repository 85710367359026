import { EndpointMapping } from "../constants";
import { fetchData as asyncFetchData } from "../actions/async";

function areGeneListsIdentical(prev, cur) {
  if (prev.length !== cur.length) {
    return false;
  }
  const res = prev.filter((e, i) => e !== cur[i]);
  if (res.length > 0) {
    return false;
  }
  return true;
}

const fetchDataMiddleware = (store) => (next) => (action) => {
  const prevState = store.getState();
  const result = next(action);
  /* istanbul ignore next */
  const nextState =
    process.env.JEST_WORKER_ID === undefined
      ? store.getState()
      : store.getNextState();
  /* istanbul ignore next */
  const fetchData =
    process.env.JEST_WORKER_ID === undefined ? asyncFetchData : store.fetchData;
  switch (action.type) {
    case "TOGGLE_PUBLISHED":
    case "SELECT_ALL_PUBLISHED_COHORTS":
    case "REMOVE_COHORT":
    case "RENAME_COHORT":
    case "TOGGLE_COHORT":
    case "TOGGLE_USE_FIXED_SAMPLES":
    case "SELECT_ALL_SAVED_COHORTS":
    case "INSERT_TREATMENT_TREE_NODE":
    case "REPLACE_TREATMENT_TREE_NODES":
    case "REMOVE_TREATMENT_TREE_NODE":
    case "CLEAR_TREATMENT_TREE_NODES":
    case "TOGGLE_APPLY_TO_PATIENTS":
    case "CLEAR_COHORT_SELECTION":
      store.dispatch(
        fetchData([
          EndpointMapping.DISTRIBUTION,
          EndpointMapping.CORRELATION,
          EndpointMapping.MUTATION,
          EndpointMapping.EXPRESSION,
          EndpointMapping.HLA_ZYGOSITY_DENSITY,
          EndpointMapping.HLA_ALLELE_PAIR_MATRIX,
          EndpointMapping.SURVIVAL,
        ])
      );
      break;
    case "START_TUTORIAL":
    case "STOP_TUTORIAL":
    case "RECEDE_TUTORIAL":
    case "ADVANCE_TUTORIAL":
    case "RESET_TUTORIAL":
    case "SKIP_TO_END_OF_TUTORIAL":
      store.dispatch(
        fetchData([
          EndpointMapping.DISTRIBUTION,
          EndpointMapping.CORRELATION,
          EndpointMapping.MUTATION,
          EndpointMapping.EXPRESSION,
          EndpointMapping.HLA_ZYGOSITY_DENSITY,
          EndpointMapping.HLA_ALLELE_PAIR_MATRIX,
          EndpointMapping.SURVIVAL,
        ])
      );
      break;
    case "SELECT_DISTRIBUTION_SOURCE":
    case "SELECT_DISTRIBUTION_DATA_FIELD":
    case "SELECT_DISTRIBUTION_DATA_FIELD_SCALE":
      store.dispatch(fetchData([EndpointMapping.DISTRIBUTION]));
      break;
    case "SELECT_CORRELATION_SOURCE":
    case "SELECT_CORRELATION_FIRST_DATA_FIELD":
    case "SELECT_CORRELATION_SECOND_DATA_FIELD":
    case "SELECT_CORRELATION_FIRST_DATA_FIELD_SCALE":
    case "SELECT_CORRELATION_SECOND_DATA_FIELD_SCALE":
    case "SWAP_CORRELATION_DATA_FIELDS":
      store.dispatch(fetchData([EndpointMapping.CORRELATION]));
      break;
    case "SELECT_MUTATION_SOURCE":
      store.dispatch(fetchData([EndpointMapping.MUTATION]));
      break;
    case "ADD_MUTATION_GENE":
    case "ADD_MULTIPLE_MUTATION_GENES":
    case "REMOVE_MUTATION_GENE":
    case "CLEAR_MUTATION_GENES":
      if (
        !areGeneListsIdentical(
          prevState.mutation.present.geneList,
          nextState.mutation.present.geneList
        )
      ) {
        store.dispatch(fetchData([EndpointMapping.MUTATION]));
      }
      break;
    case "SELECT_MUTATION_SORT_BY_GENE":
      if (nextState.mutation.present.sortBy === "gene") {
        store.dispatch(fetchData([EndpointMapping.MUTATION]));
      }
      break;
    case "SELECT_MUTATION_SORT_BY_DATA_FIELD":
      if (nextState.mutation.present.sortBy === "dataField") {
        store.dispatch(fetchData([EndpointMapping.MUTATION]));
      }
      break;
    case "SELECT_MUTATION_SORT_BY":
    case "ADD_MUTATION_DATA_FIELD":
    case "REMOVE_MUTATION_DATA_FIELD":
    case "CLEAR_MUTATION_DATA_FIELDS":
    case "TOGGLE_MUTATION_DATA_FIELDS_WITH_LOG_SCALE":
      store.dispatch(fetchData([EndpointMapping.MUTATION]));
      break;
    case "SELECT_EXPRESSION_SOURCE":
      store.dispatch(fetchData([EndpointMapping.EXPRESSION]));
      break;
    case "ADD_EXPRESSION_GENE":
    case "ADD_MULTIPLE_EXPRESSION_GENES":
    case "REMOVE_EXPRESSION_GENE":
    case "CLEAR_EXPRESSION_GENES":
      if (
        !areGeneListsIdentical(
          prevState.expression.present.geneList,
          nextState.expression.present.geneList
        )
      ) {
        store.dispatch(fetchData([EndpointMapping.EXPRESSION]));
      }
      break;
    case "SELECT_EXPRESSION_SORT_BY_GENE":
      if (nextState.expression.present.sortBy === "gene") {
        store.dispatch(fetchData([EndpointMapping.EXPRESSION]));
      }
      break;
    case "SELECT_EXPRESSION_SORT_BY_DATA_FIELD":
      if (nextState.expression.present.sortBy === "dataField") {
        store.dispatch(fetchData([EndpointMapping.EXPRESSION]));
      }
      break;
    case "SELECT_EXPRESSION_SORT_BY":
    case "ADD_EXPRESSION_DATA_FIELD":
    case "REMOVE_EXPRESSION_DATA_FIELD":
    case "CLEAR_EXPRESSION_DATA_FIELDS":
    case "SELECT_EXPRESSION_SCALE":
    case "SELECT_EXPRESSION_NORMALIZE":
    case "TOGGLE_EXPRESSION_DATA_FIELDS_WITH_LOG_SCALE":
      store.dispatch(fetchData([EndpointMapping.EXPRESSION]));
      break;
    case "TOGGLE_CONFIDENCE_INTERVAL":
    case "SELECT_CONFIDENCE_INTERVAL":
    case "SELECT_LOGRANK_WEIGHTING":
      store.dispatch(fetchData([EndpointMapping.SURVIVAL]));
      break;
    default:
      break;
  }
  return result;
};

export default fetchDataMiddleware;
