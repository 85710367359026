import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Classes,
  Collapse,
  Dialog,
  Divider,
  Tag,
  Text,
} from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";

class HighlightedPublished extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isAbstractOpen: false,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.toggleAbstract = this.toggleAbstract.bind(this);
  }

  static getDerivedStateFromProps(props, _unused) {
    const { id } = props;
    return {
      isOpen: id !== "id",
    };
  }

  toggleDialog() {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  toggleAbstract() {
    const { isAbstractOpen } = this.state;
    this.setState({
      isAbstractOpen: !isAbstractOpen,
    });
  }

  render() {
    const {
      name,
      title,
      authors,
      abstract,
      url,
      cancerTypes,
      clear,
      saveCohort,
    } = this.props;
    const { isOpen, isAbstractOpen } = this.state;
    return (
      <Dialog
        className="highlighted-published-cohort-dialog"
        icon="person"
        isOpen={isOpen}
        onClose={() => clear()}
        title={name}
        usePortal={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="heading-container">
            <h5 className="bp3-heading">{title}</h5>
            <div className="authors-container">
              <div className="first-authors">
                <Text ellipsize>
                  {authors.slice(0, -1).map((a) => `${a}, `)}
                  {authors[authors.length - 1]}
                </Text>
              </div>
              <div className="last-authors">
                <Text>{authors[authors.length - 1]}</Text>
              </div>
            </div>
            <div className="abstract-container">
              <Button
                active={isAbstractOpen}
                fill
                small
                onClick={() => this.toggleAbstract()}
              >
                Abstract
              </Button>
              <Collapse isOpen={isAbstractOpen}>
                <Text>{abstract}</Text>
              </Collapse>
            </div>
            <div className="link-container">
              <Text>
                {"DOI: "}
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="heading-icon"
                >
                  {url.slice(16)}
                </a>
              </Text>
            </div>
          </div>
          <Divider />
          <LabelWithInfo
            title="Cancer Types"
            body={[
              "A list of cancer types included in this study. The number of samples with each type of cancer is listed next to the cancer type.",
              "Save a new cohort for a specific type of cancer by clicking the desired cancer type.",
            ]}
          />
          <div className="cancer-type-container">
            {cancerTypes.map(({ cancerTypeName, patientIds, sampleIds }) => (
              <Button
                key={cancerTypeName}
                onClick={() => {
                  saveCohort(patientIds, sampleIds, "Cancer Type", [
                    name,
                    cancerTypeName,
                  ]);
                  clear();
                }}
              >
                {cancerTypeName}{" "}
                {patientIds.length === sampleIds.length ? (
                  <Tag>{patientIds.length}</Tag>
                ) : (
                  <span>
                    <Tag>{patientIds.length}</Tag> <Tag>{sampleIds.length}</Tag>
                  </span>
                )}
              </Button>
            ))}
          </div>
        </div>
      </Dialog>
    );
  }
}

HighlightedPublished.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  authors: PropTypes.arrayOf(PropTypes.string),
  abstract: PropTypes.string,
  cancerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      cancerTypeName: PropTypes.string.isRequired,
      patientIds: PropTypes.array.isRequired,
      sampleIds: PropTypes.array.isRequired,
    })
  ),
  clear: PropTypes.func.isRequired,
  saveCohort: PropTypes.func.isRequired,
};

HighlightedPublished.defaultProps = {
  id: "id",
  name: "PublishedCohrt",
  title: "Title",
  authors: [],
  abstract: "",
  cancerTypes: [],
  url: "www.google.com",
};

export default HighlightedPublished;
