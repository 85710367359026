import React from "react";
import PropTypes from "prop-types";
import Plotly from "plotly.js-cartesian-dist";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

class ExpressionPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frames: [],
      config: {},
      className: "ioexplorer-plot",
      style: { display: "block" },
      useResizeHandler: true,
    };
    this.onSelected = this.onSelected.bind(this);
  }

  onSelected(eventData) {
    if (eventData === undefined || eventData.points.length === 0) {
      return;
    }
    const { sortBy, selectedGene, saveCohort } = this.props;
    const points = eventData.points.filter((point) => point.text !== undefined);
    const tags = [];
    if (sortBy === "clustering") {
      tags.push("Clustering");
    } else if (selectedGene.type !== undefined) {
      tags.push(selectedGene[selectedGene.type]);
    }
    if (eventData.lassoPoints !== undefined) {
      tags.push("Lasso");
    } else if (eventData.range !== undefined) {
      tags.push(
        `${Math.min(...eventData.range.x).toFixed(3)} - ${Math.max(
          ...eventData.range.x
        ).toFixed(3)}`
      );
    }
    saveCohort(
      points.map((point) => point.text.split("+")[0]),
      points.map((point) => point.text.split("+")[1]),
      "Expression",
      tags
    );
  }

  render() {
    const { data, layout, revision } = this.props;
    const { frames, config, className, style, useResizeHandler } = this.state;
    return (
      <Plot
        data={data}
        layout={layout}
        frames={frames}
        config={config}
        revision={revision}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
        onSelected={(figure) => this.onSelected(figure)}
        className={className}
        style={style}
        useResizeHandler={useResizeHandler}
      />
    );
  }
}

ExpressionPlot.propTypes = {
  data: PropTypes.array.isRequired,
  layout: PropTypes.object.isRequired,
  revision: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  selectedGene: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  saveCohort: PropTypes.func.isRequired,
};

export default ExpressionPlot;
