import React from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@blueprintjs/core";
import { Cell, Column, Table } from "@blueprintjs/table";

const COLUMNS_MAPPING = [
  "symbol",
  "entrez",
  "ensembl",
  "baseMean",
  "log2FoldChange",
  "padj",
];

class VolcanoTable extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    const diffExprData = data[4];
    diffExprData.sort((a, b) => a.padj - b.padj);
    this.state = { diffExprData };
    this.cellRenderer = this.cellRenderer.bind(this);
    this.menuRenderer = this.menuRenderer.bind(this);
  }

  static getDerivedStateFromProps(props, _unusedState) {
    const { data } = props;
    const diffExprData = data[4];
    diffExprData.sort((a, b) => a.padj - b.padj);
    return { diffExprData };
  }

  cellRenderer(rowIndex, columnIndex) {
    const { diffExprData } = this.state;
    const isUserSpecified = diffExprData[rowIndex].userSpecified;
    const column = COLUMNS_MAPPING[columnIndex];
    if (column === "symbol") {
      return (
        <Cell intent={isUserSpecified ? "warning" : ""}>
          {diffExprData[rowIndex].symbol}
        </Cell>
      );
    }
    if (column === "entrez") {
      return (
        <Cell intent={isUserSpecified ? "warning" : ""}>
          {diffExprData[rowIndex].entrez}
        </Cell>
      );
    }
    if (column === "ensembl") {
      return (
        <Cell intent={isUserSpecified ? "warning" : ""}>
          {diffExprData[rowIndex].ensembl}
        </Cell>
      );
    }
    if (column === "baseMean") {
      return (
        <Cell style={{ textAlign: "right" }}>
          {diffExprData[rowIndex].baseMean.toFixed(3)}
        </Cell>
      );
    }
    if (column === "log2FoldChange") {
      return (
        <Cell
          intent={
            diffExprData[rowIndex].log2FoldChange > 0 ? "success" : "danger"
          }
        >
          {diffExprData[rowIndex].log2FoldChange.toFixed(4)}
        </Cell>
      );
    }
    if (column === "padj") {
      if (diffExprData[rowIndex].padj <= 10e-10) {
        return <Cell key={`key${rowIndex}`}>{`<${(10e-10).toFixed(9)}`}</Cell>;
      }
      return (
        <Cell key={`key${rowIndex}`}>
          {diffExprData[rowIndex].padj.toFixed(10)}
        </Cell>
      );
    }
    return <Cell />;
  }

  menuRenderer(context) {
    const { diffExprData } = this.state;
    const selectedRegion = context.selectedRegions[0];
    const uniqueCols = new Set(selectedRegion.cols);
    const uniqueRows = new Set(selectedRegion.rows);
    if (uniqueCols.size === 1 && uniqueRows.size === 1) {
      const [row, col] = [selectedRegion.rows[0], selectedRegion.cols[0]];
      return (
        <Menu>
          <MenuItem
            text="Copy"
            onClick={() =>
              navigator.clipboard.writeText(
                diffExprData[row][COLUMNS_MAPPING[col]]
              )
            }
          />
        </Menu>
      );
    }
    if (uniqueCols.size === 1) {
      return (
        <Menu>
          <li className="bp3-menu-header">
            <h6 className="bp3-heading">Copy Cells</h6>
          </li>
          <MenuItem
            text="List"
            onClick={() =>
              navigator.clipboard.writeText(
                diffExprData
                  .filter(
                    (_unused, i) =>
                      i >= selectedRegion.rows[0] && i <= selectedRegion.rows[1]
                  )
                  .map((d) => d[COLUMNS_MAPPING[selectedRegion.cols[0]]])
                  .join(" ")
              )
            }
          />
          <MenuItem
            text="JSON Array"
            onClick={() =>
              navigator.clipboard.writeText(
                JSON.stringify(
                  diffExprData
                    .filter(
                      (_unused, i) =>
                        i >= selectedRegion.rows[0] &&
                        i <= selectedRegion.rows[1]
                    )
                    .map((d) => d[COLUMNS_MAPPING[selectedRegion.cols[0]]])
                )
              )
            }
          />
        </Menu>
      );
    }
    return (
      <Menu>
        <li className="bp3-menu-header">
          <h6 className="bp3-heading">Copy Cells</h6>
        </li>
        <MenuItem
          text="JSON Array of JSON Objects"
          onClick={() =>
            navigator.clipboard.writeText(
              JSON.stringify(
                diffExprData.filter(
                  (_unused, i) =>
                    i >= selectedRegion.rows[0] && i <= selectedRegion.rows[1]
                )
              )
            )
          }
        />
        <MenuItem text="Comma-Separated List" disabled />
      </Menu>
    );
  }

  render() {
    const { diffExprData } = this.state;
    return (
      <div
        id="volcano-table"
        style={{
          height: "calc(100% - 10px)",
          width: "70%",
          marginBottom: "10px",
        }}
      >
        <Table
          bodyContextMenuRenderer={this.menuRenderer}
          enableColumnResizing={false}
          enableRowResizing={false}
          numFrozenColumns={1}
          numRows={diffExprData.length}
        >
          <Column id="1" name="Symbol" cellRenderer={this.cellRenderer} />
          <Column id="2" name="Entrez" cellRenderer={this.cellRenderer} />
          <Column id="3" name="Ensembl" cellRenderer={this.cellRenderer} />
          <Column id="4" name="Base Mean" cellRenderer={this.cellRenderer} />
          <Column
            id="5"
            name="Log2 Fold Change"
            cellRenderer={this.cellRenderer}
          />
          <Column
            id="6"
            name="Adjusted P value"
            cellRenderer={this.cellRenderer}
          />
        </Table>
      </div>
    );
  }
}

VolcanoTable.propTypes = {
  data: PropTypes.array.isRequired,
  // options: PropTypes.object.isRequired,
};

export default VolcanoTable;
