function updateData(state, action) {
  switch (action.type) {
    case "REQUEST_DATA":
      return { ...state, isFetching: true };
    case "RECEIVE_DATA":
      return {
        ...state,
        data: action.data,
        isFetching: false,
        revision: state.revision + 1,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

const dataFetch = (
  state = {
    datasets: {
      data: [],
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    treatments: {
      data: [],
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    cancerTypes: {
      data: [],
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    dnaCounts: {
      data: [],
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    rnaCounts: {
      data: [],
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    allDataFields: {
      data: [],
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    distribution: {
      data: {
        traces: [],
        layout: {},
        potentialValues: {},
        quartiles: {},
        valueGroups: {},
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    correlation: {
      data: {
        traces: [],
        layout: {},
        potentialValues: {},
        quartiles: {},
        valueGroups: {},
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    mutation: {
      data: {
        traces: [],
        layout: {},
        potentialValues: {},
        variantGroups: {},
        dataFieldsLegend: {},
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    batchCorrectedExpression: {
      data: {
        taskId: "",
        status: "",
        current: 0,
        total: 0,
        message: "",
        data: [[], [], [], [], [], [], [], [], []],
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    expression: {
      data: {
        traces: [],
        layout: {},
        potentialValues: {},
        quartiles: {},
        dataFieldsLegend: {},
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    hlaZygosityDensity: {
      data: {
        traces: [],
        layout: {},
        potentialValues: {},
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    hlaAllelePairMatrix: {
      data: {
        traces: [],
        layout: {},
        highestCount: -1,
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    staticHEDMatrix: {
      data: {
        hlaA: {
          traces: [],
          layout: {},
        },
        hlaB: {
          traces: [],
          layout: {},
        },
        hlaC: {
          traces: [],
          layout: {},
        },
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    survival: {
      data: {
        traces: [],
        layout: [],
        potentialValues: {},
        statistics: {
          coxModel: {
            expCoef: -1,
            pValue: -1,
            zValue: -1,
          },
          logrank: {
            criticalValues: {
              "0.95": -1,
              "0.99": -1,
              "0.995": -1,
              "0.999": -1,
            },
            pValue: -1,
            testStatistic: -1,
          },
        },
      },
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    allGenes: {
      data: [],
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
    geneSets: {
      data: {},
      isFetching: false,
      revision: 0,
      lastUpdated: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case "RECEIVE_DATA":
    case "REQUEST_DATA":
      return {
        ...state,
        [action.dataType]: updateData(state[action.dataType], action),
      };
    default:
      return state;
  }
};

export default dataFetch;
