import { connect } from "react-redux";
import { selectVolcanoPcaDomain } from "../../actions";
import VolcanoPCADomainRadio from "../../components/volcano/PCADomainRadio";

const mapStateToProps = (state) => ({
  pcaDomain: state.volcano.present.options.pcaDomain,
});

const mapDispatchToProps = (dispatch) => ({
  selectPcaDomain: (pcaDomain) => dispatch(selectVolcanoPcaDomain(pcaDomain)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VolcanoPCADomainRadio);
