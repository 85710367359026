import React from "react";
import PropTypes from "prop-types";
import { Alignment, Icon, Radio, RadioGroup } from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";

const ScaleSelect = ({ scale, selectScale }) => (
  <div className="expression-scale-container">
    <LabelWithInfo title="Scale" body={[]} />
    <RadioGroup
      className="scale-container"
      onChange={(e) => selectScale(e.target.value)}
      selectedValue={scale}
    >
      <Radio
        disabled
        label=<div>
          <Icon icon="ninja" />
          Batch-Corrected - (Coming Soon)
        </div>
        value="bc"
        alignIndicator={Alignment.RIGHT}
      />
      <Radio
        label=<div>
          <Icon icon="symbol-circle" />
          VST
        </div>
        value="vst"
        alignIndicator={Alignment.RIGHT}
      />
      <Radio
        label=<div>
          <Icon icon="symbol-cross" />
          TPM
        </div>
        value="tpm"
        alignIndicator={Alignment.RIGHT}
      />
      <Radio
        label=<div>
          <Icon icon="symbol-square" />
          CPM
        </div>
        value="cpm"
        alignIndicator={Alignment.RIGHT}
      />
      <Radio
        label=<div>
          <Icon icon="symbol-diamond" />
          Raw Counts
        </div>
        value="raw"
        alignIndicator={Alignment.RIGHT}
      />
    </RadioGroup>
  </div>
);

ScaleSelect.propTypes = {
  scale: PropTypes.string.isRequired,
  selectScale: PropTypes.func.isRequired,
};

export default ScaleSelect;
