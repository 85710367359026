import * as R from "ramda";
import { defaultUi, defaultTutorial } from "../defaultStates";

/*
 * URL PARAMETER ENCODE, DECODE FUNCTIONS
 *
 * Decode functions take a piece of the URL and translate to a JS object.
 * The resulting JS object depends on the version of the given URL piece.
 * Old versions must be mapped to the latest version. When mapping past
 * versions to the latest versions, one must have some judgement calls on
 * the best mapping.
 *
 * Encode functions take a JS object and translate to a URL safe string.
 * All encode functions will use the current version in the result.
 */

export const currentURLParamVersion = "v2";
export const pastURLParamVersions = ["v1"];

export const decodeUi = (str) => {
  // Make sure str is string and is not empty
  if (!R.is(String, str) || str.length === 0) {
    return {
      present: defaultUi,
      saved: defaultUi,
    };
  }
  let start;
  let isSelectionPanelOpen;
  let isTutorialPanelOpen;
  let whichMappingPopoverOpen;
  let landingPageKey;
  let pathname;
  let useRedirect;
  let present;
  let saved;
  const args = R.split(" ", str);
  const version = args[0];
  if (version === "v1") {
    if (args.length !== 12) {
      return {
        present: defaultUi,
        saved: defaultUi,
      };
    }
    start = 0;
    isSelectionPanelOpen = args[start + 1] === "t";
    isTutorialPanelOpen = args[start + 2] === "t";
    whichMappingPopoverOpen = args[start + 3];
    pathname = args[start + 4];
    useRedirect = args[start + 5] === "t";
    present = {
      version: "v2",
      isSelectionPanelOpen,
      isTutorialPanelOpen,
      whichMappingPopoverOpen,
      landingPageKey: "",
      pathname,
      useRedirect,
    };
    start = 6;
    isSelectionPanelOpen = args[start + 1] === "t";
    isTutorialPanelOpen = args[start + 2] === "t";
    whichMappingPopoverOpen = args[start + 3];
    pathname = args[start + 4];
    useRedirect = args[start + 5] === "t";
    saved = {
      version: "v2",
      isSelectionPanelOpen,
      isTutorialPanelOpen,
      whichMappingPopoverOpen,
      landingPageKey: "",
      pathname,
      useRedirect,
    };
    return {
      present,
      saved,
    };
  }
  if (version === "v2") {
    if (args.length !== 14) {
      return {
        present: defaultUi,
        saved: defaultUi,
      };
    }
    start = 0;
    isSelectionPanelOpen = args[start + 1] === "t";
    isTutorialPanelOpen = args[start + 2] === "t";
    whichMappingPopoverOpen = args[start + 3];
    landingPageKey = args[start + 4];
    pathname = args[start + 5];
    useRedirect = args[start + 6] === "t";
    present = {
      version,
      isSelectionPanelOpen,
      isTutorialPanelOpen,
      whichMappingPopoverOpen,
      landingPageKey,
      pathname,
      useRedirect,
    };
    start = 7;
    isSelectionPanelOpen = args[start + 1] === "t";
    isTutorialPanelOpen = args[start + 2] === "t";
    whichMappingPopoverOpen = args[start + 3];
    landingPageKey = args[start + 4];
    pathname = args[start + 5];
    useRedirect = args[start + 6] === "t";
    saved = {
      version,
      isSelectionPanelOpen,
      isTutorialPanelOpen,
      whichMappingPopoverOpen,
      landingPageKey,
      pathname,
      useRedirect,
    };
    return {
      present,
      saved,
    };
  }
  // version is invalid, default is returned
  return {
    present: defaultUi,
    saved: defaultUi,
  };
};

export const encodeUi = (v) => {
  let value;
  if (!R.is(Object, v)) {
    value = {
      present: defaultUi,
      saved: defaultUi,
    };
  } else {
    value = R.mergeDeepRight(
      {
        present: defaultUi,
        saved: defaultUi,
      },
      v
    );
  }
  if (value.present === undefined) {
    value.present = defaultUi;
  }
  if (value.saved === undefined) {
    value.saved = defaultUi;
  }
  let base = "";
  base += `${value.present.version} `;
  base += `${value.present.isSelectionPanelOpen.toString()[0]} `;
  base += `${value.present.isTutorialPanelOpen.toString()[0]} `;
  base += `${value.present.whichMappingPopoverOpen.toString()} `;
  base += `${value.present.landingPageKey.toString()} `;
  base += `${value.present.pathname.toString()} `;
  base += `${value.present.useRedirect.toString()[0]} `;
  base += `${value.saved.version} `;
  base += `${value.saved.isSelectionPanelOpen.toString()[0]} `;
  base += `${value.saved.isTutorialPanelOpen.toString()[0]} `;
  base += `${value.saved.whichMappingPopoverOpen.toString()} `;
  base += `${value.saved.landingPageKey.toString()} `;
  base += `${value.saved.pathname.toString()} `;
  base += value.saved.useRedirect.toString()[0];
  return base;
};

// source: https://mzl.la/39INdgC
export const filterInt = (value) => {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  }
  return 0;
};

export const decodeTutorial = (str) => {
  // Make sure str is string and is not empty
  if (!R.is(String, str) || str.length === 0) {
    return defaultTutorial;
  }
  let currentTutorial;
  let didTutorialStart;
  let restoreStateOnStop;
  let currentStep;
  const args = R.split(" ", str);
  const version = args[0];
  if (version === "v1") {
    if (args.length !== 5) {
      return defaultTutorial;
    }
    currentTutorial = filterInt(args[1]);
    didTutorialStart = args[2] === "t";
    restoreStateOnStop = args[3] === "t";
    currentStep = filterInt(args[4]);
    return {
      version,
      currentTutorial,
      didTutorialStart,
      restoreStateOnStop,
      currentStep,
    };
  }
  return defaultTutorial;
};

export const encodeTutorial = (v) => {
  let value;
  if (!R.is(Object, v)) {
    value = defaultTutorial;
  } else {
    value = R.mergeDeepRight(defaultTutorial, v);
  }
  let base = "";
  base += `${value.version} `;
  base += `${value.currentTutorial.toString()} `;
  base += `${value.didTutorialStart.toString()[0]} `;
  base += `${value.restoreStateOnStop.toString()[0]} `;
  base += value.currentStep.toString();
  return base;
};
