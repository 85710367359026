import { connect } from "react-redux";
import { selectExpressionScale } from "../../actions";
import ExpressionScaleSelect from "../../components/expression/ScaleSelect";

const mapStateToProps = (state) => ({
  scale: state.expression.present.scale,
});

const mapDispatchToProps = (dispatch) => ({
  selectScale: (scale) => dispatch(selectExpressionScale(scale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionScaleSelect);
