import { connect } from "react-redux";
import {
  addMutationDataField,
  removeMutationDataField,
  clearMutationDataFields,
} from "../../actions";
import MutationTopTrackDataFieldPicker from "../../components/TopTrackDataFieldPicker";

const mapStateToProps = (state) => ({
  allFields: state.dataFetch.allDataFields.data,
  dataFields: state.mutation.present.dataFields,
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.mutationTopTrackDataFieldPicker
      : state.tutorialTooltips.saved.mutationTopTrackDataFieldPicker,
});

const mapDispatchToProps = (dispatch) => ({
  add: (dataField) => dispatch(addMutationDataField(dataField)),
  remove: (dataField) => dispatch(removeMutationDataField(dataField)),
  clear: () => dispatch(clearMutationDataFields()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MutationTopTrackDataFieldPicker);
