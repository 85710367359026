import { connect } from "react-redux";
import { swapCorrelationDataFields } from "../../actions";
import CorrelationDataFieldSwapper from "../../components/correlation/DataFieldSwapper";

const mapStateToProps = (state) => ({
  firstDataField: state.correlation.present.firstDataField,
  secondDataField: state.correlation.present.firstDataField,
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.correlationDataFieldSwapper
      : state.tutorialTooltips.saved.correlationDataFieldSwapper,
});

const mapDispatchToProps = (dispatch) => ({
  swap: () => dispatch(swapCorrelationDataFields()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrelationDataFieldSwapper);
