import * as R from "ramda";
import { connect } from "react-redux";
import {
  getWithDefault,
  getSelectedCohortsWithIds,
  matchPatientIds,
} from "../../util";
import {
  saveCohort,
  selectAllPublishedCohorts,
  selectAllSavedCohorts,
  clearCohortSelection,
} from "../../actions/cohorts";
import CohortsSavedContainer from "../../components/cohorts/Container";

const mapStateToProps = (state) => {
  const getIsFetching = (t) => R.path(["dataFetch", t, "isFetching"], state);
  const selectedCohorts = getSelectedCohortsWithIds(state);
  return {
    publishedCohortIds: state.cohort.present.published.map((p) => p.id),
    savedCohortIds: state.cohort.present.saved.map((c) => c.id),
    isFetchingPublishedCohorts: getIsFetching("datasets"),
    numSelectedIds: selectedCohorts.length,
    selectedCohorts,
    scrollIntoViewCohortId: getWithDefault(
      ["ui", "present", "scrollIntoViewCohortId"],
      state,
      "none"
    ),
    matchPatientIds: (sampleIds) =>
      matchPatientIds(
        sampleIds,
        getWithDefault(["dataFetch", "treatments", "data"], state, [])
      ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveCohort: (pIds, sIds, s, t) => dispatch(saveCohort(pIds, sIds, s, t)),
  selectAllPublished: () => dispatch(selectAllPublishedCohorts()),
  selectAllSaved: () => dispatch(selectAllSavedCohorts()),
  clearCohortSelection: () => dispatch(clearCohortSelection()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CohortsSavedContainer);
