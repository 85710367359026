import * as R from "ramda";
import { connect } from "react-redux";
import { saveCohort } from "../../actions/cohorts";
import {
  getZeroIdx,
  getWithDefault,
  getDataByDatasetAsTableTemplate,
  assignIdsToCohort,
} from "../../util";
import CohortsSetOpMenu from "../../components/cohorts/SetOpMenu";

const mapStateToProps = (state) => {
  const getDataByDatasetAsTable = getDataByDatasetAsTableTemplate(state);
  const treatmentTable = getWithDefault(
    ["dataFetch", "treatments", "data"],
    state,
    []
  );
  const extractBaselinePatientIds = (cohorts) =>
    R.pluck(
      "patientIds",
      R.map(
        (c) =>
          assignIdsToCohort(
            { ...c, mapping: { ...c.mapping, applyToPatients: false } },
            treatmentTable
          ),
        cohorts
      )
    );

  const extractBaselineSampleIds = (cohorts) =>
    R.pluck(
      "sampleIds",
      R.map(
        (c) =>
          assignIdsToCohort(
            { ...c, mapping: { ...c.mapping, useFixedSamples: true } },
            treatmentTable
          ),
        cohorts
      )
    );

  // Find cohorts that are selected
  const publishedCohorts = state.cohort.present.published;
  const savedCohorts = state.cohort.present.saved;
  const confirmedCohorts = R.concat(publishedCohorts, savedCohorts);
  const selectedCohorts = R.filter((c) => c.isSelected, confirmedCohorts);

  // Enable operations
  const isUnionEnabled = selectedCohorts.length > 1;
  const isIntersectionEnabled = selectedCohorts.length > 1;
  const isDuplicateEnabled = selectedCohorts.length === 1;

  // Set union args
  let unionPatientIds = [];
  let unionSampleIds = [];
  let unionSource = "";
  let unionTags = [];
  if (isUnionEnabled) {
    unionSource = "Union";
    unionTags = R.flatten(R.pluck("tags", selectedCohorts));
    const [firstPatientIds, ...restOfPatientIds] = extractBaselinePatientIds(
      selectedCohorts
    );
    unionPatientIds = R.reduce(R.union, firstPatientIds, restOfPatientIds);
    const anyFixed = R.any(
      R.propSatisfies((ids) => ids.length > 0, "sampleIds"),
      selectedCohorts
    );
    if (anyFixed) {
      const [firstSampleIds, ...restOfSampleIds] = extractBaselineSampleIds(
        selectedCohorts
      );
      unionSampleIds = R.reduce(R.union, firstSampleIds, restOfSampleIds);
    }
  }

  // Set intersection args
  let intersectionPatientIds = [];
  let intersectionSampleIds = [];
  let intersectionSource = "";
  let intersectionTags = [];
  if (isUnionEnabled) {
    intersectionSource = "Intersection";
    intersectionTags = R.flatten(R.pluck("tags", selectedCohorts));
    const [firstPatientIds, ...restOfPatientIds] = extractBaselinePatientIds(
      selectedCohorts
    );
    intersectionPatientIds = R.reduce(
      R.intersection,
      firstPatientIds,
      restOfPatientIds
    );
    const anyFixed = R.any(
      R.propSatisfies((ids) => ids.length > 0, "sampleIds"),
      selectedCohorts
    );
    if (anyFixed) {
      const [firstSampleIds, ...restOfSampleIds] = extractBaselineSampleIds(
        selectedCohorts
      );
      intersectionSampleIds = R.reduce(
        R.intersection,
        firstSampleIds,
        restOfSampleIds
      );
    }
  }

  // Set duplicate args
  let duplicatePatientIds = [];
  let duplicateSampleIds = [];
  let duplicateSource = "";
  let duplicateTags = [];
  if (isDuplicateEnabled) {
    const selectedCohort = getZeroIdx(selectedCohorts);
    duplicateSource = `${selectedCohort.name} dup`;
    duplicateTags = selectedCohort.tags;
    if (selectedCohort.isPublished) {
      const filteredTreatmentTable = getDataByDatasetAsTable(
        selectedCohort.name,
        "treatments"
      );
      duplicatePatientIds = R.uniq(
        R.pluck("patientId", filteredTreatmentTable)
      );
    } else {
      duplicatePatientIds = selectedCohort.patientIds;
      duplicateSampleIds = selectedCohort.sampleIds;
    }
  }

  return {
    unionPatientIds,
    unionSampleIds,
    unionSource,
    unionTags,
    intersectionPatientIds,
    intersectionSampleIds,
    intersectionSource,
    intersectionTags,
    duplicatePatientIds,
    duplicateSampleIds,
    duplicateSource,
    duplicateTags,
    isUnionEnabled,
    isIntersectionEnabled,
    isDuplicateEnabled,
    duplicateButtonTooltip: state.tutorialTooltips.present.duplicateButton,
    unionButtonTooltip: state.tutorialTooltips.present.unionButton,
    intersectionButtonTooltip:
      state.tutorialTooltips.present.intersectionButton,
  };
};

const mapDispatchToProps = (dispatch) => ({
  union: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
  intersection: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
  duplicate: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CohortsSetOpMenu);
