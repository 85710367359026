import React from "react";
import PropTypes from "prop-types";
import Plotly from "plotly.js-cartesian-dist";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

class CorrelationPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frames: [],
      config: {},
      className: "ioexplorer-plot",
      style: { display: "block" },
      useResizeHandler: true,
    };
    this.onSelected = this.onSelected.bind(this);
  }

  onSelected(eventData) {
    if (eventData === undefined || eventData.points.length === 0) {
      return;
    }
    const { plotType, firstField, secondField, saveCohort } = this.props;
    let sampleIds = [];
    let selectedValues = [];
    let { minValue, maxValue } = 0;
    let uniqueValues;
    const tags = [];
    const points = eventData.points.filter((point) => point.text !== undefined);
    if (plotType === "sample") {
      sampleIds = points.map((point) => point.text.split("+")[1]);
    }
    /* SCATTER PLOT */
    if (
      (firstField.class === "int" || firstField.class === "float") &&
      (secondField.class === "int" || secondField.class === "float")
    ) {
      if (points[0].data.customdata === "margin") {
        if (points[0].data.marker.symbol === "line-ns-open") {
          /* FIRST FIELD (NUMERICAL) */
          tags.push(firstField.name);
          if (eventData.lassoPoints !== undefined) {
            tags.push("Lasso");
          } else if (eventData.range !== undefined) {
            tags.push(
              `${Math.min(...eventData.range[points[0].data.xaxis]).toFixed(
                3
              )} - ${Math.max(...eventData.range[points[0].data.xaxis]).toFixed(
                3
              )}`
            );
          }
          selectedValues = eventData.points.map((p) => p.x);
          minValue = Math.min(...selectedValues);
          maxValue = Math.max(...selectedValues);
          tags.push(
            firstField.class === "int" ? minValue : minValue.toFixed(3)
          );
          tags.push(
            firstField.class === "int" ? maxValue : maxValue.toFixed(3)
          );
        } else {
          tags.push(secondField.name);
          if (eventData.lassoPoints !== undefined) {
            tags.push("Lasso");
          } else if (eventData.range !== undefined) {
            tags.push(
              `${Math.min(...eventData.range[points[0].data.yaxis]).toFixed(
                3
              )} - ${Math.max(...eventData.range[points[0].data.yaxis]).toFixed(
                3
              )}`
            );
          }
          selectedValues = eventData.points.map((p) => p.y);
          minValue = Math.min(...selectedValues);
          maxValue = Math.max(...selectedValues);
          tags.push(
            secondField.class === "int" ? minValue : minValue.toFixed(3)
          );
          tags.push(
            secondField.class === "int" ? maxValue : maxValue.toFixed(3)
          );
        }
      } else {
        /* FIRST FIELD (NUMERICAL) */
        tags.push(firstField.name);
        if (eventData.lassoPoints !== undefined) {
          tags.push("Lasso");
        } else if (eventData.range !== undefined) {
          tags.push(
            `${Math.min(...eventData.range[points[0].data.xaxis]).toFixed(
              3
            )} - ${Math.max(...eventData.range[points[0].data.xaxis]).toFixed(
              3
            )}`
          );
        }
        selectedValues = eventData.points.map((p) => p.x);
        minValue = Math.min(...selectedValues);
        maxValue = Math.max(...selectedValues);
        tags.push(firstField.class === "int" ? minValue : minValue.toFixed(3));
        tags.push(firstField.class === "int" ? maxValue : maxValue.toFixed(3));
        /* SECOND FIELD (NUMERICAL) */
        tags.push(secondField.name);
        if (eventData.lassoPoints !== undefined) {
          tags.push("Lasso");
        } else if (eventData.range !== undefined) {
          tags.push(
            `${Math.min(...eventData.range[points[0].data.yaxis]).toFixed(
              3
            )} - ${Math.max(...eventData.range[points[0].data.yaxis]).toFixed(
              3
            )}`
          );
        }
        selectedValues = eventData.points.map((p) => p.y);
        minValue = Math.min(...selectedValues);
        maxValue = Math.max(...selectedValues);
        tags.push(secondField.class === "int" ? minValue : minValue.toFixed(3));
        tags.push(secondField.class === "int" ? maxValue : maxValue.toFixed(3));
      }
      /* VERTICAL BOX PLOT */
    } else if (
      (firstField.class === "enum" || firstField.class === "str") &&
      (secondField.class === "int" || secondField.class === "float")
    ) {
      /* FIRST FIELD (CATEGORIAL) */
      tags.push(firstField.name);
      uniqueValues = [
        ...new Set(eventData.points.map((p) => p.data.customdata)),
      ];
      if (uniqueValues.length === 1) {
        tags.push(uniqueValues[0]);
      } else {
        tags.push("Multiple Values");
      }
      /* SECOND FIELD (NUMERICAL) */
      tags.push(secondField.name);
      if (eventData.lassoPoints !== undefined) {
        tags.push("Lasso");
      } else if (eventData.range !== undefined) {
        tags.push(
          `${Math.min(...eventData.range[points[0].data.yaxis]).toFixed(
            3
          )} - ${Math.max(...eventData.range[points[0].data.yaxis]).toFixed(3)}`
        );
      }
      selectedValues = eventData.points.map((p) => p.y);
      minValue = Math.min(...selectedValues);
      maxValue = Math.max(...selectedValues);
      tags.push(secondField.class === "int" ? minValue : minValue.toFixed(3));
      tags.push(secondField.class === "int" ? maxValue : maxValue.toFixed(3));
      /* HORIZONTAL BOX PLOT */
    } else if (
      (firstField.class === "int" || firstField.class === "float") &&
      (secondField.class === "enum" || secondField.class === "str")
    ) {
      /* FIRST FIELD (NUMERICAL) */
      tags.push(firstField.name);
      if (eventData.lassoPoints !== undefined) {
        tags.push("Lasso");
      } else if (eventData.range !== undefined) {
        tags.push(
          `${Math.min(...eventData.range[points[0].data.xaxis]).toFixed(
            3
          )} - ${Math.max(...eventData.range[points[0].data.xaxis]).toFixed(3)}`
        );
      }
      selectedValues = eventData.points.map((p) => p.x);
      minValue = Math.min(...selectedValues);
      maxValue = Math.max(...selectedValues);
      tags.push(firstField.class === "int" ? minValue : minValue.toFixed(3));
      tags.push(firstField.class === "int" ? maxValue : maxValue.toFixed(3));
      /* SECOND FIELD (CATEGORIAL) */
      tags.push(secondField.name);
      uniqueValues = [
        ...new Set(eventData.points.map((p) => p.data.customdata)),
      ];
      if (uniqueValues.length === 1) {
        tags.push(uniqueValues[0]);
      } else {
        tags.push("Multiple Values");
      }
    }
    saveCohort(
      points.map((point) => point.text.split("+")[0]),
      sampleIds,
      "Correlation",
      tags
    );
  }

  render() {
    const { data, layout, revision } = this.props;
    const { frames, config, className, style, useResizeHandler } = this.state;
    return (
      <Plot
        data={data}
        layout={layout}
        frames={frames}
        config={config}
        revision={revision}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
        onSelected={(figure) => this.onSelected(figure)}
        className={className}
        style={style}
        useResizeHandler={useResizeHandler}
      />
    );
  }
}

CorrelationPlot.propTypes = {
  data: PropTypes.array.isRequired,
  layout: PropTypes.object.isRequired,
  revision: PropTypes.number.isRequired,
  plotType: PropTypes.oneOf(["patient", "sample"]).isRequired,
  firstField: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
    table: PropTypes.string,
    group: PropTypes.string,
    class: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  secondField: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
    table: PropTypes.string,
    group: PropTypes.string,
    class: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  saveCohort: PropTypes.func.isRequired,
};

export default CorrelationPlot;
