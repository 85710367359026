import { connect } from "react-redux";
import SourcePicker from "../../components/SourcePicker";

const mapStateToProps = (state) => ({
  source: "saved-cohorts-intersection",
  sourceOptions: ["saved-cohorts-intersection"],
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.survivalSourcePicker
      : state.tutorialTooltips.saved.survivalSourcePicker,
});

const mapDispatchToProps = () => ({
  selectSource: () => ({}),
});

export default connect(mapStateToProps, mapDispatchToProps)(SourcePicker);
