const session = (state = { id: "" }, action) => {
  switch (action.type) {
    case "INPUT_SESSION":
      return action.value;
    case "RECEIVE_SESSION":
    case "RECEIVE_SESSION_ID":
      return { id: action.data.sessionId };
    default:
      return state;
  }
};

export default session;
