import { defaultTask } from "../defaultStates";

const task = (state = defaultTask, action) => {
  switch (action.type) {
    case "RECEIVE_SESSION":
      return action.data.task;
    case "RECEIVE_DATA":
      if (action.dataType === "batchCorrectedExpression") {
        const { taskId, status, current, total, message } = action.data;
        return {
          ...state,
          taskId,
          status,
          current,
          total,
          message,
        };
      }
      return state;
    case "SET_TIMER_ID":
      return { ...state, timerId: action.timerId };
    default:
      return state;
  }
};

export default task;
