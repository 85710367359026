import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Icon, Tab, Tabs, Text } from "@blueprintjs/core";
import { legendColors, legendSymbols } from "../../constants";
import FeatureUnderConstruction from "../FeatureUnderConstruction";
import LabelWithInfo from "../LabelWithInfo";
import LegendTabPanel from "../LegendTabPanel";
import PotentialValuesTabPanel from "../PotentialValuesTabPanel";

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      selectedTabId,
      firstField,
      secondField,
      potentialValues,
      quartilesContainer,
      valueGroupsContainer,
      selectTabId,
      save,
    } = this.props;
    let noCohortsSelected = true;
    if (
      potentialValues !== undefined &&
      Object.entries(potentialValues).length !== 0
    ) {
      noCohortsSelected = false;
    }

    return (
      <div className="statistics-container">
        <Tabs
          id="TabsExample"
          onChange={(newTabId) => selectTabId(newTabId)}
          selectedTabId={selectedTabId}
        >
          <Tab
            id="legend-tab"
            title="Legend"
            panel=<LegendTabPanel />
            panelClassName="legend-container"
          />
          <Tab
            id="potential-values-tab"
            title="Potential Values"
            panel=<PotentialValuesTabPanel
              noCohortsSelected={noCohortsSelected}
              potentialValues={potentialValues}
            />
            panelClassName="potential-values-container"
          />
          <Tab
            id="cohort-creation-tab-quartiles"
            title="Quartiles"
            panel={
              <div>
                <LabelWithInfo title="Cohorts By Quartiles" body={[]} />
                <div className="list">
                  {quartilesContainer !== undefined &&
                  Object.keys(quartilesContainer).length !== 0 ? (
                    Object.entries(quartilesContainer).map(
                      ([cohortName, fields]) =>
                        Object.keys(fields).length !== 0 ? (
                          <div key={cohortName}>
                            <Text className="cohort-label" ellipsize>
                              <Icon icon="person" />
                              {cohortName.replace("<br>", " ")}
                            </Text>
                            <div>
                              {firstField.field in fields &&
                              (firstField.class === "int" ||
                                firstField.class === "float") ? (
                                <div className="push-right">
                                  <Text>
                                    <Icon icon="variable" />
                                    {firstField.name}
                                  </Text>
                                  {fields[firstField.field].map(
                                    ([typeLabel, quartilesArray]) => (
                                      <div
                                        className="cohort-container"
                                        key={typeLabel}
                                      >
                                        <Text>
                                          <Icon
                                            icon={legendSymbols[typeLabel]}
                                            style={{
                                              color: legendColors[typeLabel],
                                            }}
                                          />
                                          {typeLabel}
                                        </Text>
                                        <ButtonGroup className="buttons" fill>
                                          <Button
                                            small
                                            text="Q1"
                                            onClick={() =>
                                              save(
                                                quartilesArray[0].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                firstField.table === "Samples"
                                                  ? quartilesArray[0].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  firstField.name,
                                                  typeLabel,
                                                  "Q1",
                                                ]
                                              )
                                            }
                                          />
                                          <Button
                                            small
                                            text="Q2"
                                            onClick={() =>
                                              save(
                                                quartilesArray[1].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                firstField.table === "Samples"
                                                  ? quartilesArray[1].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  firstField.name,
                                                  typeLabel,
                                                  "Q2",
                                                ]
                                              )
                                            }
                                          />
                                          <Button
                                            small
                                            text="Q3"
                                            onClick={() =>
                                              save(
                                                quartilesArray[2].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                firstField.table === "Samples"
                                                  ? quartilesArray[2].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  firstField.name,
                                                  typeLabel,
                                                  "Q3",
                                                ]
                                              )
                                            }
                                          />
                                          <Button
                                            small
                                            text="Q4"
                                            onClick={() =>
                                              save(
                                                quartilesArray[3].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                firstField.table === "Samples"
                                                  ? quartilesArray[3].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  firstField.name,
                                                  typeLabel,
                                                  "Q4",
                                                ]
                                              )
                                            }
                                          />
                                          <Button
                                            small
                                            icon="multi-select"
                                            onClick={() => {
                                              save(
                                                quartilesArray[0].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                firstField.table === "Samples"
                                                  ? quartilesArray[0].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  firstField.name,
                                                  typeLabel,
                                                  "Q1",
                                                ]
                                              );
                                              save(
                                                quartilesArray[1].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                firstField.table === "Samples"
                                                  ? quartilesArray[1].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  firstField.name,
                                                  typeLabel,
                                                  "Q2",
                                                ]
                                              );
                                              save(
                                                quartilesArray[2].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                firstField.table === "Samples"
                                                  ? quartilesArray[2].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  firstField.name,
                                                  typeLabel,
                                                  "Q3",
                                                ]
                                              );
                                              save(
                                                quartilesArray[3].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                firstField.table === "Samples"
                                                  ? quartilesArray[3].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  firstField.name,
                                                  typeLabel,
                                                  "Q4",
                                                ]
                                              );
                                            }}
                                          />
                                        </ButtonGroup>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div />
                              )}
                              {secondField.field in fields &&
                              secondField.field !== firstField.field &&
                              (secondField.class === "int" ||
                                secondField.class === "float") ? (
                                <div className="push-right">
                                  <Text>
                                    <Icon icon="variable" />
                                    {secondField.name}
                                  </Text>
                                  {fields[secondField.field].map(
                                    ([typeLabel, quartilesArray]) => (
                                      <div
                                        className="cohort-container"
                                        key={typeLabel}
                                      >
                                        <Text>
                                          <Icon
                                            icon={legendSymbols[typeLabel]}
                                            style={{
                                              color: legendColors[typeLabel],
                                            }}
                                          />
                                          {typeLabel}
                                        </Text>
                                        <ButtonGroup className="buttons" fill>
                                          <Button
                                            small
                                            text="Q1"
                                            onClick={() =>
                                              save(
                                                quartilesArray[0].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                secondField.table === "Samples"
                                                  ? quartilesArray[0].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  secondField.name,
                                                  typeLabel,
                                                  "Q1",
                                                ]
                                              )
                                            }
                                          />
                                          <Button
                                            small
                                            text="Q2"
                                            onClick={() =>
                                              save(
                                                quartilesArray[1].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                secondField.table === "Samples"
                                                  ? quartilesArray[1].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  secondField.name,
                                                  typeLabel,
                                                  "Q2",
                                                ]
                                              )
                                            }
                                          />
                                          <Button
                                            small
                                            text="Q3"
                                            onClick={() =>
                                              save(
                                                quartilesArray[2].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                secondField.table === "Samples"
                                                  ? quartilesArray[2].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  secondField.name,
                                                  typeLabel,
                                                  "Q3",
                                                ]
                                              )
                                            }
                                          />
                                          <Button
                                            small
                                            text="Q4"
                                            onClick={() =>
                                              save(
                                                quartilesArray[3].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                secondField.table === "Samples"
                                                  ? quartilesArray[3].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  secondField.name,
                                                  typeLabel,
                                                  "Q4",
                                                ]
                                              )
                                            }
                                          />
                                          <Button
                                            small
                                            icon="multi-select"
                                            onClick={() => {
                                              save(
                                                quartilesArray[0].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                secondField.table === "Samples"
                                                  ? quartilesArray[0].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  secondField.name,
                                                  typeLabel,
                                                  "Q1",
                                                ]
                                              );
                                              save(
                                                quartilesArray[1].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                secondField.table === "Samples"
                                                  ? quartilesArray[1].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  secondField.name,
                                                  typeLabel,
                                                  "Q2",
                                                ]
                                              );
                                              save(
                                                quartilesArray[2].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                secondField.table === "Samples"
                                                  ? quartilesArray[2].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  secondField.name,
                                                  typeLabel,
                                                  "Q3",
                                                ]
                                              );
                                              save(
                                                quartilesArray[3].map(
                                                  (id) => id.split("+")[0]
                                                ),
                                                secondField.table === "Samples"
                                                  ? quartilesArray[3].map(
                                                      (id) => id.split("+")[1]
                                                    )
                                                  : [],
                                                "Correlation",
                                                [
                                                  secondField.name,
                                                  typeLabel,
                                                  "Q4",
                                                ]
                                              );
                                            }}
                                          />
                                        </ButtonGroup>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div key={cohortName} />
                        )
                    )
                  ) : (
                    <Text>Nothing to display.</Text>
                  )}
                </div>
              </div>
            }
            panelClassName="quartiles-container"
          />
          <Tab
            id="cohort-creation-tab-values"
            title="Values"
            panel={
              <div>
                <LabelWithInfo title="Cohorts By Value" body={[]} />
                <div className="list">
                  {valueGroupsContainer !== undefined &&
                  Object.keys(valueGroupsContainer).length !== 0 ? (
                    Object.entries(valueGroupsContainer).map(
                      ([cohortName, fields]) =>
                        Object.keys(fields).length !== 0 ? (
                          <div key={cohortName}>
                            <Text className="cohort-label" ellipsize>
                              <Icon icon="person" />
                              {cohortName.replace("<br>", " ")}
                            </Text>
                            <div className="push-right">
                              {firstField.field in fields &&
                              (firstField.class === "enum" ||
                                firstField.class === "str") ? (
                                <div>
                                  <Text>
                                    <Icon icon="variable" />
                                    {firstField.name}
                                  </Text>
                                  {fields[firstField.field].map(
                                    ([typeLabel, valueGroupsMini]) => (
                                      <div
                                        className="cohort-container"
                                        key={typeLabel}
                                      >
                                        <Text>
                                          <Icon
                                            icon={legendSymbols[typeLabel]}
                                            style={{
                                              color: legendColors[typeLabel],
                                            }}
                                          />
                                          {typeLabel}
                                        </Text>
                                        <ButtonGroup
                                          className="buttons"
                                          fill
                                          style={{ width: "100%" }}
                                          vertical
                                        >
                                          {Object.entries(valueGroupsMini).map(
                                            ([valueName, ids]) => (
                                              <Button
                                                key={valueName}
                                                onClick={() =>
                                                  save(
                                                    ids.map(
                                                      (id) => id.split("+")[0]
                                                    ),
                                                    firstField.table ===
                                                      "Samples"
                                                      ? ids.map(
                                                          (id) =>
                                                            id.split("+")[1]
                                                        )
                                                      : [],
                                                    "Correlation",
                                                    [
                                                      firstField.name,
                                                      typeLabel,
                                                      valueName,
                                                    ]
                                                  )
                                                }
                                                small
                                                text={valueName}
                                              />
                                            )
                                          )}
                                          <Button
                                            onClick={() => {
                                              Object.entries(
                                                valueGroupsMini
                                              ).forEach(([valueName, ids]) =>
                                                save(
                                                  ids.map(
                                                    (id) => id.split("+")[0]
                                                  ),
                                                  firstField.table === "Samples"
                                                    ? ids.map(
                                                        (id) => id.split("+")[1]
                                                      )
                                                    : [],
                                                  "Correlation",
                                                  [
                                                    firstField.name,
                                                    typeLabel,
                                                    valueName,
                                                  ]
                                                )
                                              );
                                            }}
                                            small
                                            icon="multi-select"
                                          />
                                        </ButtonGroup>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div />
                              )}
                              {secondField.field in fields &&
                              secondField.field !== firstField.field &&
                              (secondField.class === "enum" ||
                                secondField.class === "str") ? (
                                <div>
                                  <Text>
                                    <Icon icon="variable" />
                                    {secondField.name}
                                  </Text>
                                  {fields[secondField.field].map(
                                    ([typeLabel, valueGroupsMini]) => (
                                      <div
                                        className="cohort-container"
                                        key={typeLabel}
                                      >
                                        <Text>
                                          <Icon
                                            icon={legendSymbols[typeLabel]}
                                            style={{
                                              color: legendColors[typeLabel],
                                            }}
                                          />
                                          {typeLabel}
                                        </Text>
                                        <ButtonGroup
                                          className="buttons"
                                          fill
                                          style={{ width: "100%" }}
                                          vertical
                                        >
                                          {Object.entries(valueGroupsMini).map(
                                            ([valueName, ids]) => (
                                              <Button
                                                key={valueName}
                                                onClick={() =>
                                                  save(
                                                    ids.map(
                                                      (id) => id.split("+")[0]
                                                    ),
                                                    secondField.table ===
                                                      "Samples"
                                                      ? ids.map(
                                                          (id) =>
                                                            id.split("+")[1]
                                                        )
                                                      : [],
                                                    "Correlation",
                                                    [
                                                      secondField.name,
                                                      typeLabel,
                                                      valueName,
                                                    ]
                                                  )
                                                }
                                                small
                                                text={valueName}
                                              />
                                            )
                                          )}
                                          <Button
                                            onClick={() => {
                                              Object.entries(
                                                valueGroupsMini
                                              ).forEach(([valueName, ids]) =>
                                                save(
                                                  ids.map(
                                                    (id) => id.split("+")[0]
                                                  ),
                                                  secondField.table ===
                                                    "Samples"
                                                    ? ids.map(
                                                        (id) => id.split("+")[1]
                                                      )
                                                    : [],
                                                  "Correlation",
                                                  [
                                                    secondField.name,
                                                    typeLabel,
                                                    valueName,
                                                  ]
                                                )
                                              );
                                            }}
                                            small
                                            icon="multi-select"
                                          />
                                        </ButtonGroup>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div key={cohortName} />
                        )
                    )
                  ) : (
                    <Text>Nothing to display.</Text>
                  )}
                </div>
              </div>
            }
            panelClassName="values-container"
          />
          <Tab
            id="statistics-tab"
            title="Statistics"
            panel={<FeatureUnderConstruction description="" />}
            panelClassName="statistics-panel-container"
          />
          <Tabs.Expander />
        </Tabs>
      </div>
    );
  }
}

Statistics.propTypes = {
  selectedTabId: PropTypes.string.isRequired,
  firstField: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
    table: PropTypes.string,
    group: PropTypes.string,
    class: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  secondField: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
    table: PropTypes.string,
    group: PropTypes.string,
    class: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  potentialValues: PropTypes.object.isRequired,
  quartilesContainer: PropTypes.object.isRequired,
  valueGroupsContainer: PropTypes.object.isRequired,
  selectTabId: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default Statistics;
