import React from "react";
import PropTypes from "prop-types";
import {
  AnchorButton,
  ButtonGroup,
  ControlGroup,
  HTMLSelect,
  Popover,
  Tooltip,
  Tree,
} from "@blueprintjs/core";
import TreatmentPresetMenu from "./TreatmentPresetMenu";

class TreatmentFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTreatmentContext: "PRE",
      selectedTreatmentTarget: "PD_1",
      savedTreatmentTarget: undefined,
    };
    this.selectTreatmentContext = this.selectTreatmentContext.bind(this);
    this.selectTreatmentTarget = this.selectTreatmentTarget.bind(this);
  }

  selectTreatmentContext(treatmentContext) {
    const { selectedTreatmentTarget, savedTreatmentTarget } = this.state;
    switch (treatmentContext) {
      case "NAIVE":
        this.setState({
          selectedTreatmentContext: treatmentContext,
          selectedTreatmentTarget: "NOT_APPLICABLE",
          savedTreatmentTarget: selectedTreatmentTarget,
        });
        break;
      default:
        this.setState({
          selectedTreatmentContext: treatmentContext,
          selectedTreatmentTarget:
            savedTreatmentTarget === undefined
              ? selectedTreatmentTarget
              : savedTreatmentTarget,
          savedTreatmentTarget: undefined,
        });
    }
  }

  selectTreatmentTarget(treatmentTarget) {
    this.setState({
      selectedTreatmentTarget: treatmentTarget,
    });
  }

  render() {
    const {
      nodes,
      disableAll,
      isAnyNodeSelected,
      isLeafNodeSelected,
      selectNode,
      expandNode,
      collapseNode,
      insertNode,
      replaceNodes,
      removeNode,
      clearNodes,
    } = this.props;
    const { selectedTreatmentContext, selectedTreatmentTarget } = this.state;
    const emptyTree = nodes.length === 0;
    return (
      <div className="treatment-filter-container">
        <Tree
          className="treatment-filter-list"
          contents={nodes}
          onNodeClick={(node) => selectNode(node.id)}
          onNodeCollapse={(node) => collapseNode(node.id)}
          onNodeExpand={(node) => expandNode(node.id)}
        />
        <ControlGroup fill vertical={false} style={{ marginTop: "4px" }}>
          <Tooltip
            autoFocus="false"
            position="top"
            content="Treatment Context"
            usePortal={false}
          >
            <HTMLSelect
              fill
              options={[
                { label: "Pre", value: "PRE" },
                { label: "On", value: "ON" },
                { label: "Post", value: "POST" },
                { label: "On/Post", value: "AFTER_START" },
                { label: "Unknown", value: "UNKNOWN_STATUS" },
                { label: "ICI Naive", value: "NAIVE" },
              ]}
              disabled={disableAll}
              value={selectedTreatmentContext}
              onChange={(e) => this.selectTreatmentContext(e.target.value)}
            />
          </Tooltip>
          <Tooltip
            position="top"
            content="Treatment Target"
            disabled={selectedTreatmentContext === "ICI Naive"}
            usePortal={false}
          >
            <HTMLSelect
              fill
              options={[
                { label: "PD-1", value: "PD_1" },
                { label: "PD-L1", value: "PD_L1" },
                { label: "CTLA-4", value: "CTLA_4" },
                { label: "PD-1/PD-L1", value: "PD_1_OR_PD_L1" },
                { label: "Unknown ICI", value: "UNKNOWN_ICI_TARGET" },
                { label: "Combo", value: "COMBO" },
                { label: "Non-ICI Treatment", value: "NON_ICI_TREATMENT" },
                { label: "NA", value: "NOT_APPLICABLE", disabled: true },
              ]}
              disabled={disableAll || selectedTreatmentContext === "NAIVE"}
              value={selectedTreatmentTarget}
              onChange={(e) => this.selectTreatmentTarget(e.target.value)}
            />
          </Tooltip>
          <Tooltip position="top" content="Add Rule" usePortal={false}>
            <AnchorButton
              fill
              icon="plus"
              intent="primary"
              disabled={
                disableAll ||
                emptyTree ||
                !isAnyNodeSelected ||
                isLeafNodeSelected
              }
              onClick={() =>
                insertNode(
                  undefined,
                  selectedTreatmentContext,
                  selectedTreatmentTarget,
                  "prescription",
                  true
                )
              }
            />
          </Tooltip>
        </ControlGroup>
        <div className="treatment-filter-buttons-container">
          <ButtonGroup fill className="treatment-filter-buttons left">
            <Tooltip position="top" content="Logical OR" usePortal={false}>
              <AnchorButton
                disabled={disableAll}
                icon="resolve"
                onClick={() =>
                  insertNode(
                    "Logical OR",
                    undefined,
                    undefined,
                    "resolve",
                    false
                  )
                }
                small
              />
            </Tooltip>
            <Tooltip position="top" content="Logical AND" usePortal={false}>
              <AnchorButton
                disabled={disableAll}
                icon="intersection"
                onClick={() =>
                  insertNode(
                    "Logical AND",
                    undefined,
                    undefined,
                    "resolve",
                    false
                  )
                }
                small
              />
            </Tooltip>
            <Tooltip position="top" content="Logical NOT" usePortal={false}>
              <AnchorButton
                disabled={disableAll}
                icon="not-equal-to"
                onClick={() =>
                  insertNode(
                    "Logical NOT",
                    undefined,
                    undefined,
                    "not-equal-to",
                    false
                  )
                }
                small
              />
            </Tooltip>
          </ButtonGroup>
          <ButtonGroup fill className="treatment-filter-buttons right">
            <Tooltip
              position="top"
              content="Replace With Preset"
              usePortal={false}
            >
              <Popover position="bottom">
                <AnchorButton
                  small
                  icon="history"
                  intent="success"
                  onClick={() => {}}
                />
                <TreatmentPresetMenu replaceNodes={replaceNodes} />
              </Popover>
            </Tooltip>
            <Tooltip position="top" content="Remove" usePortal={false}>
              <AnchorButton
                disabled={disableAll || emptyTree}
                small
                icon="remove"
                intent="warning"
                onClick={() => removeNode()}
              />
            </Tooltip>
            <Tooltip position="top" content="Clear" usePortal={false}>
              <AnchorButton
                disabled={disableAll || emptyTree}
                small
                icon="trash"
                intent="danger"
                onClick={() => clearNodes({})}
              />
            </Tooltip>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

TreatmentFilter.propTypes = {
  nodes: PropTypes.array.isRequired,
  disableAll: PropTypes.bool.isRequired,
  isAnyNodeSelected: PropTypes.bool.isRequired,
  isLeafNodeSelected: PropTypes.bool.isRequired,
  selectNode: PropTypes.func.isRequired,
  expandNode: PropTypes.func.isRequired,
  collapseNode: PropTypes.func.isRequired,
  insertNode: PropTypes.func.isRequired,
  replaceNodes: PropTypes.func.isRequired,
  removeNode: PropTypes.func.isRequired,
  clearNodes: PropTypes.func.isRequired,
};

export default TreatmentFilter;
