import * as R from "ramda";
import { connect } from "react-redux";
import { getZeroIdx, getWithDefault } from "../../util";
import {
  clearHighlightedCohort,
  removeCohort,
  renameCohort,
} from "../../actions/cohorts";
import HighlightedSaved from "../../components/cohorts/HighlightedSaved";

const mapStateToProps = (state) => {
  const savedCohorts = getWithDefault(
    ["cohort", "present", "saved"],
    state,
    []
  );

  const anyTrue = R.any((e) => e);
  const someHighlighted = anyTrue(
    R.filter((c) => c.isHighlighted, savedCohorts)
  );

  // Nothing highlighted, nothing to pass into component
  if (!someHighlighted) {
    return {};
  }

  const highlightedCohort = getZeroIdx(
    R.filter((c) => c.isHighlighted, savedCohorts)
  );

  const { id, name, source, tags } = highlightedCohort;

  return {
    id,
    name,
    source,
    tags,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(clearHighlightedCohort()),
  remove: (id) => dispatch(removeCohort(id)),
  rename: (id, name) => dispatch(renameCohort(id, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HighlightedSaved);
