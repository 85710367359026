import { connect } from "react-redux";
import { toggleVolcanoDoShrinkFoldChange } from "../../actions";
import VolcanoShrinkFoldChangeRadio from "../../components/volcano/ShrinkFoldChangeRadio";

const mapStateToProps = (state) => ({
  doShrinkFoldChange: state.volcano.present.doShrinkFoldChange
    ? "true"
    : "false",
});

const mapDispatchToProps = (dispatch) => ({
  toggleDoShrinkFoldChange: () => dispatch(toggleVolcanoDoShrinkFoldChange()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VolcanoShrinkFoldChangeRadio);
