import { connect } from "react-redux";
// import { extractPatientIds, extractSampleIds } from "../../util";
import {
  confirmCohorts,
  removePendingCohorts,
  renamePendingCohort,
} from "../../actions/cohorts";
import CohortsSavedPendingDialog from "../../components/cohorts/PendingDialog";

const mapStateToProps = (state) => ({
  cohorts: state.cohort.present.pending,
});

const mapDispatchToProps = (dispatch) => ({
  confirm: (cohortIds) => dispatch(confirmCohorts(cohortIds)),
  remove: (cohortIds) => dispatch(removePendingCohorts(cohortIds)),
  rename: (cohortId, name) => dispatch(renamePendingCohort(cohortId, name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CohortsSavedPendingDialog);
