// const testGenes = [
//   {"ensembl": "ENSG00000141510", "symbol": "TP53", "entrez": "7157"},
//   {"ensembl": "ENSG00000141736", "symbol": "ERBB2", "entrez": "2064"},
//   {"ensembl": "ENSG00000171163", "symbol": "AREBP", "entrez": "55657"},
// ]

const predicate = (query, item, _unusedIndex, _unusedExactMatch) => {
  if (query.length < 2) {
    return false;
  }
  const entrezRegex = /^[0-9]+$/gm;
  const ensemblRegex = /^ENSG[0-9]+$/gim;

  // Single gene
  if (!query.includes(" ")) {
    if (item.type === "entrez") {
      if (entrezRegex.test(query)) {
        return query === item.entrez.slice(0, query.length);
      }
      return false;
    }
    if (item.type === "ensembl") {
      if (query.length > 10 && ensemblRegex.test(query)) {
        return query.toUpperCase() === item.ensembl.slice(0, query.length);
      }
      return false;
    }
    if (item.type === "symbol") {
      return query.toUpperCase() === item.symbol.slice(0, query.length);
    }
    return false;
  }

  // List of genes, separated by spaces
  const allQueries = query.split(" ");
  const ret = [];
  allQueries.forEach((q) => {
    if (item.type === "entrez") {
      if (entrezRegex.test(q)) {
        ret.push(q === item.entrez);
      }
      ret.push(false);
    } else if (item.type === "ensembl") {
      if (q.length > 12 && ensemblRegex.test(q)) {
        ret.push(q.toUpperCase() === item.ensembl);
      }
      ret.push(false);
    } else if (item.type === "symbol") {
      ret.push(q.toUpperCase() === item.symbol);
    }
  });
  return ret.some((bool) => bool);
};

export default predicate;
