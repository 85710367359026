import React from "react";
import PropTypes from "prop-types";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import Foldable from "./Foldable";

const isValidPageKey = (pageKey) => {
  switch (pageKey) {
    case "valero":
      return true;
    default:
      return false;
  }
};

const selectPage = (pageKey, funcs) => {
  const { openTutorialPanel, chooseTutorial } = funcs;
  switch (pageKey) {
    case "valero":
      return (
        <div className={Classes.DIALOG_BODY}>
          <p>
            It seems you have found us from your interest in the Valero et al.
            study published in Nature Genetics.
          </p>
          <Foldable
            buttonTitle="What is IOExplorer?"
            buttonParams={{ fill: true }}
          >
            <div style={{ marginTop: "10px" }}>
              <p>
                IOExplorer is an interactive graphical tool intended to
                facilitate the exploration and analysis of immuno-oncology (IO)
                clinical-genomic studies.
              </p>
              <p>
                The basic grouping of patients and samples in IOExplorer is
                called a cohort. The user first selects one or more pre-loaded
                cohorts of interest, based on published IO studies. The user may
                then explore the selected cohort(s) by examining distributions
                of clinical/genomic variables and relationships between
                variables.
              </p>
              <p>
                After identifying variables and subsets of interest, or (as in
                this tutorial) if they already know what they wish to analyze,
                the user then creates any requisite derived cohorts by merging,
                filtering, and partitioning existing cohorts. Finally, the user
                analyzes the derived cohorts. Depending on results, the user may
                wish to continue to iteratively refine their cohorts and
                analyses. Additional details about IOExplorer may be found in
                other tutorials and documentation pages.
              </p>
              <Button
                fill
                icon="home"
                onClick={() => {
                  chooseTutorial(0);
                  openTutorialPanel();
                }}
                style={{ marginBottom: "10px" }}
              >
                Go to the Getting Started Tutorial
              </Button>
            </div>
          </Foldable>
          <div style={{ marginBottom: "10px" }} />
          <p>
            We are happy to announce that we have included this study on our
            platform and we welcome clinicians and researchers alike to use
            IOExplorer to better understand it.
          </p>
          <p>
            In addition, we have crafted a tutorial, with author input, that
            serves as a walk-through for how to use IOExplorer to mirror the
            analysis in the study.
          </p>
          <Button
            fill
            icon="symbol-triangle-up"
            onClick={() => {
              chooseTutorial(2);
              openTutorialPanel();
            }}
          >
            Go to the Valero-2021-NatGen Tutorial
          </Button>
        </div>
      );
    default:
      return <div />;
  }
};

const LandingPageDialog = ({
  pageKey,
  clearLandingPageKey,
  openTutorialPanel,
  chooseTutorial,
}) => (
  <Dialog
    className="landing-page-dialog"
    icon="globe"
    isOpen={isValidPageKey(pageKey)}
    onClose={() => clearLandingPageKey()}
    title="Welcome to IOExplorer!"
    usePortal={false}
  >
    {selectPage(pageKey, { openTutorialPanel, chooseTutorial })}
  </Dialog>
);

LandingPageDialog.propTypes = {
  pageKey: PropTypes.string.isRequired,
  clearLandingPageKey: PropTypes.func.isRequired,
  openTutorialPanel: PropTypes.func.isRequired,
  chooseTutorial: PropTypes.func.isRequired,
};

export default LandingPageDialog;
