const toast = (
  state = {
    nextId: 1,
    toast: [
      {
        id: 0,
        intent: "primary",
        message: "Welcome to IOExplorer!",
        timeout: 1200,
      },
    ],
  },
  action
) => {
  switch (action.type) {
    case "NEW_TOAST":
      return {
        ...state,
        toast: [...state.toast, { id: state.nextId, ...action.toast }],
        nextId: state.nextId + 1,
      };
    case "REMOVE_TOAST":
      return {
        ...state,
        toast: state.toast.filter((t) => t.id !== action.id),
      };
    case "CLEAR_TOAST":
      return { ...state, toast: [] };
    default:
      return state;
  }
};

export default toast;
