import * as R from "ramda";

export const getWithDefault = (path, value, defaultValue) => {
  const target = R.path(path, value);
  return target !== undefined ? target : defaultValue;
};

export const getZeroIdx = R.view(R.lensIndex(0));

export const matchId = (id, target) =>
  R.view(
    R.lensIndex(0),
    R.filter((t) => t.id === id, target)
  );

/* istanbul ignore next */
export const getDataAsTableTemplate = (datasetName, state) => (dataTarget) =>
  R.prop(
    datasetName,
    R.groupBy(
      R.prop("datasetName"),
      R.path(["dataFetch", dataTarget, "data"], state)
    )
  );

/* istanbul ignore next */
export const getDataByDatasetAsTableTemplate = (state) => (
  datasetName,
  dataTarget
) =>
  R.prop(
    datasetName,
    R.groupBy(
      R.prop("datasetName"),
      R.path(["dataFetch", dataTarget, "data"], state)
    )
  );

/* istanbul ignore next */
export const debounce = (func, delay) => {
  let inDebounce;
  return function innerDebouce(...args) {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(this, args), delay);
  };
};

export const onlyIds = (table) => {
  if (!Array.isArray(table)) {
    return [];
  }
  return table.map((r) => ({ patientId: r.patientId, sampleId: r.sampleId }));
};

export const uniqueIds = (table) => {
  if (table === undefined) {
    return { patientIds: [], sampleIds: [] };
  }

  return {
    patientIds: Array.from(new Set(table.map((r) => r.patientId))),
    sampleIds: Array.from(new Set(table.map((r) => r.sampleId))),
  };
};

export const matchPatientIds = (sampleIds, treatmentTable) => {
  if (!Array.isArray(sampleIds) || !Array.isArray(treatmentTable)) {
    return [];
  }
  const sampleIdSet = new Set(sampleIds);
  return Array.from(
    new Set(
      treatmentTable
        .filter((r) => sampleIdSet.has(r.sampleId))
        .map((r) => r.patientId)
    )
  );
};

export const parseTreatmentTreeHelper = (root) => {
  if (root === undefined) {
    return R.F;
  }
  const parseChildren = R.map((child) => parseTreatmentTreeHelper(child));
  const { childNodes, label, nodeData } = root;

  if (
    label === undefined ||
    nodeData === undefined ||
    childNodes === undefined ||
    !R.is(Array, childNodes)
  ) {
    return R.F;
  }

  let ret;
  let children;
  let f;
  const { status, target } = nodeData;
  switch (label) {
    case "Logical OR":
      children = parseChildren(childNodes);
      if (children.length === 0) {
        return R.F;
      }
      ret = R.anyPass(children);
      break;
    case "Logical AND":
      children = parseChildren(childNodes);
      if (children.length === 0) {
        return R.F;
      }
      ret = R.allPass(children);
      break;
    case "Logical NOT":
      children = parseChildren(childNodes);
      if (children.length === 0) {
        return R.T;
      }
      ret = R.complement(R.anyPass(children));
      break;
    default:
      f = (i) => (r) =>
        r[i] !== undefined &&
        r[i].treatmentStatus === status &&
        r[i].treatmentTarget === target;
      ret = R.anyPass([f(0), f(1), f(2), f(3), f(4), f(5), f(6), f(7)]);
      break;
  }
  return ret;
};

export const parseTreatmentTree = (root, table) => {
  if (table === undefined) {
    return [];
  }
  if (root === undefined) {
    return onlyIds(table);
  }

  const tableBySampleId = R.groupBy((r) => r.sampleId)(table);

  const f = parseTreatmentTreeHelper(root);
  const sampleIds = R.keys(R.filter(f, tableBySampleId));
  const setOfSampleIds = new Set(sampleIds);
  const ret = R.uniqBy(
    (x) => x.sampleId,
    onlyIds(R.filter((x) => setOfSampleIds.has(x.sampleId), table))
  );
  return ret;
};

export const assignIdsToCohort = (cohort, table) => {
  if (cohort === undefined || table === undefined || !R.is(Array, table)) {
    return cohort;
  }

  const {
    isPublished,
    patientIds: defaultPatientIds,
    sampleIds: defaultSampleIds,
    mapping,
  } = cohort;

  if (isPublished === undefined || mapping === undefined) {
    return cohort;
  }

  const { treatmentTree, applyToPatients, useFixedSamples } = mapping;

  if (treatmentTree === undefined || applyToPatients === undefined) {
    return cohort;
  }

  const treatmentTreeRoot = getZeroIdx(treatmentTree);

  // Cohort is Published
  if (isPublished) {
    const filteredRecords = table.filter((r) => r.datasetName === cohort.name);
    const filteredTreatmentTable = parseTreatmentTree(
      treatmentTreeRoot,
      filteredRecords
    );
    const { patientIds: unfilteredPatientIds } = uniqueIds(filteredRecords);
    const { patientIds: filteredPatientIds, sampleIds } = uniqueIds(
      filteredTreatmentTable
    );
    const patientIds = applyToPatients
      ? filteredPatientIds
      : unfilteredPatientIds;
    return R.mergeRight(cohort, { patientIds, sampleIds });
  }

  if (useFixedSamples === undefined) {
    return cohort;
  }

  // Cohort is saved by samples
  if (useFixedSamples) {
    const sampleIds = defaultSampleIds;
    const patientIds = defaultPatientIds;
    return R.mergeRight(cohort, { patientIds, sampleIds });
  }

  // Cohort is saved by patients OR it is saved by samples but not using fixedSamples
  const defaultPatientIdsSet = new Set(defaultPatientIds);
  const filteredTreatmentTable = parseTreatmentTree(
    treatmentTreeRoot,
    table.filter((t) => defaultPatientIdsSet.has(t.patientId))
  );
  const { patientIds: filteredPatientIds, sampleIds } = uniqueIds(
    filteredTreatmentTable
  );
  const patientIds = applyToPatients ? filteredPatientIds : defaultPatientIds;
  return R.mergeRight(cohort, { patientIds, sampleIds });
};

export const getSelectedCohortsWithIds = (state) => {
  if (state === undefined) {
    return [];
  }

  const treatmentTable = getWithDefault(
    ["dataFetch", "treatments", "data"],
    state,
    []
  );

  return R.concat(
    getWithDefault(["cohort", "present", "published"], state, []),
    getWithDefault(["cohort", "present", "saved"], state, [])
  )
    .filter((p) => p.isSelected)
    .map((p) => assignIdsToCohort(p, treatmentTable));
};
