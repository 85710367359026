import { connect } from "react-redux";
import {
  addExpressionDataField,
  removeExpressionDataField,
  clearExpressionDataFields,
} from "../../actions";
import ExpressionTopTrackDataFieldPicker from "../../components/TopTrackDataFieldPicker";

const mapStateToProps = (state) => ({
  allFields: state.dataFetch.allDataFields.data,
  dataFields: state.expression.present.dataFields,
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.expressionTopTrackDataFieldPicker
      : state.tutorialTooltips.saved.expressionTopTrackDataFieldPicker,
});

const mapDispatchToProps = (dispatch) => ({
  add: (dataField) => dispatch(addExpressionDataField(dataField)),
  remove: (dataField) => dispatch(removeExpressionDataField(dataField)),
  clear: () => dispatch(clearExpressionDataFields()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressionTopTrackDataFieldPicker);
