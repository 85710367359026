import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Callout,
  Classes,
  DialogStep,
  MultistepDialog,
} from "@blueprintjs/core";
import VolcanoGeneSelect from "../../containers/volcano/GeneSelect";
import VolcanoShrinkFoldChangeRadio from "../../containers/volcano/ShrinkFoldChangeRadio";

const BatchCorrectionButton = ({
  selectedCohorts,
  numGenes,
  isDialogOpen,
  isTaskStarted,
  toggleDialog,
  triggerTask,
  checkStatus,
  setTimerId,
}) => (
  <div style={{ width: "100%" }}>
    <Button
      large
      fill
      text="Configure | Run"
      icon="cog"
      rightIcon="play"
      onClick={() => toggleDialog()}
    />
    <MultistepDialog
      className="volcano-dialog"
      finalButtonProps={{
        disabled:
          isTaskStarted || selectedCohorts.length !== 2 || numGenes === 0,
        onClick: () => {
          triggerTask();
          const timerId = setInterval(() => checkStatus(), 2000);
          setTimerId(timerId);
        },
        text: "Batch Correction",
      }}
      isOpen={isDialogOpen}
      onClose={() => toggleDialog()}
      title="Batch Correction & Differential Expression"
    >
      <DialogStep
        id="info"
        panel={
          <div className={Classes.DIALOG_BODY}>
            <p>
              We advise to only use this module if you are looking to do either
              of following analyses: comparing expression values between NGS
              sources (i.e. Van Allen vs. Riaz), running differential expression
              between two cohorts (either across NGS sources or within a single
              NGS source).
            </p>
            <p>
              If you are only interested in plotting expression values within a
              single NGS source, we advise that you use the Expression module as
              it is much faster and more straight-forward. (Eventually, these
              modules will be merged.)
            </p>
            <p>
              Once the job is started, it will take at least a few minutes to
              complete. The length of time it takes to run and return with
              results depends on the number of samples (with expression data)
              used.
            </p>
          </div>
        }
        title="Information"
      />
      <DialogStep
        id="genes"
        panel={
          <div className={Classes.DIALOG_BODY}>
            <VolcanoGeneSelect />
          </div>
        }
        title="Choose Genes"
      />
      <DialogStep
        id="shrink-fold-change"
        panel={
          <div className={Classes.DIALOG_BODY}>
            <VolcanoShrinkFoldChangeRadio />
          </div>
        }
        title="Shrink Fold Change"
      />
      <DialogStep
        id="confirm"
        panel={
          <div className={`${"volcano-confirm-step "}${Classes.DIALOG_BODY}`}>
            {!isTaskStarted ? (
              <Callout icon="tick" intent="success" title="No Running Task">
                There is no task blocking this request.
              </Callout>
            ) : (
              <Callout
                icon="cross"
                intent="danger"
                title="Existing Running Task"
              >
                There is a running task blocking this request.
              </Callout>
            )}
            {selectedCohorts.length === 2 ? (
              <Callout
                icon="tick"
                intent="success"
                title="Valid Cohort Selection"
              >
                You have selected 2 valid cohorts.
              </Callout>
            ) : (
              <Callout
                icon="cross"
                intent="danger"
                title="Invalid Cohort Selection"
              >
                You must select exactly 2 cohorts without any overlap of
                samples.
              </Callout>
            )}
            {numGenes > 0 ? (
              <Callout
                icon="tick"
                intent="success"
                title="Valid Gene Selection"
              >
                You have selected {numGenes} genes.
              </Callout>
            ) : (
              <Callout
                icon="cross"
                intent="danger"
                title="Invalid Gene Selection"
              >
                You must select at least one gene.
              </Callout>
            )}
          </div>
        }
        title="Confirm"
      />
    </MultistepDialog>
  </div>
);

// {true ? (
//   <Callout
//     intent="success"
//     title="Omit Differentially Expressed Genes"
//   >
//     You have chosen to omit significant genes from the heatmap.
//   </Callout>
// ) : (
//   <Callout
//     intent="success"
//     title="Include Differentially Expressed Genes"
//   >
//     You have chosen to include significant genes in the heatmap.
//   </Callout>
// )}

BatchCorrectionButton.propTypes = {
  selectedCohorts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      patientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      sampleIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  numGenes: PropTypes.number.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isTaskStarted: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  triggerTask: PropTypes.func.isRequired,
  checkStatus: PropTypes.func.isRequired,
  setTimerId: PropTypes.func.isRequired,
};

export default BatchCorrectionButton;
