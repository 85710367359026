import React from "react";
import PropTypes from "prop-types";
import Plotly from "plotly.js-cartesian-dist";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

class DistributionPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frames: [],
      config: {},
      className: "ioexplorer-plot",
      style: { display: "block" },
      useResizeHandler: true,
    };
    this.onSelected = this.onSelected.bind(this);
  }

  onSelected(eventData) {
    if (eventData === undefined || eventData.points.length === 0) {
      return;
    }
    const { plotType, field, saveCohort } = this.props;
    let sampleIds = [];
    let selectedValues = [];
    let { minValue, maxValue } = 0;
    let uniqueValues;
    const tags = [field.name];
    const points = eventData.points.filter((point) => point.text !== undefined);
    if (plotType === "sample") {
      sampleIds = points.map((point) => point.text.split("+")[1]);
    }
    if (field.class === "int" || field.class === "float") {
      if (eventData.lassoPoints !== undefined) {
        tags.push("Lasso");
      } else if (eventData.range !== undefined) {
        tags.push(
          `${Math.min(...eventData.range.x).toFixed(3)} - ${Math.max(
            ...eventData.range.x
          ).toFixed(3)}`
        );
      }
      selectedValues = eventData.points.map((p) => p.x);
      minValue = Math.min(...selectedValues);
      maxValue = Math.max(...selectedValues);
      tags.push(field.class === "int" ? minValue : minValue.toFixed(3));
      tags.push(field.class === "int" ? maxValue : maxValue.toFixed(3));
    } else {
      uniqueValues = [
        ...new Set(eventData.points.map((p) => p.data.customdata)),
      ];
      if (uniqueValues.length === 1) {
        tags.push(uniqueValues[0]);
      } else {
        tags.push("Multiple Values");
      }
    }
    saveCohort(
      points.map((point) => point.text.split("+")[0]),
      sampleIds,
      "Distribution",
      tags
    );
  }

  render() {
    const { data, layout, revision } = this.props;
    const { frames, config, className, style, useResizeHandler } = this.state;
    return (
      <Plot
        data={data}
        layout={layout}
        frames={frames}
        config={config}
        revision={revision}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
        onSelected={(figure) => this.onSelected(figure)}
        className={className}
        style={style}
        useResizeHandler={useResizeHandler}
      />
    );
  }
}

DistributionPlot.propTypes = {
  data: PropTypes.array.isRequired,
  layout: PropTypes.object.isRequired,
  revision: PropTypes.number.isRequired,
  plotType: PropTypes.oneOf(["patient", "sample"]).isRequired,
  field: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
    table: PropTypes.string,
    group: PropTypes.string,
    class: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  saveCohort: PropTypes.func.isRequired,
};

export default DistributionPlot;
