import { connect } from "react-redux";
import { selectDistributionTabId } from "../../actions";
import { saveCohort } from "../../actions/cohorts";
import DistributionStatistics from "../../components/distribution/Statistics";

const mapStateToProps = (state) => {
  const { data } = state.dataFetch.distribution;
  let selectedField = {
    field: "",
    table: "",
    group: "",
    class: "",
    description: "",
  };
  state.dataFetch.allDataFields.data.forEach((field) => {
    if (field.field === state.distribution.present.dataField) {
      selectedField = field;
    }
  });
  return {
    selectedTabId: state.distribution.present.ui.selectedTabId,
    field: selectedField,
    potentialValues: data.potentialValues,
    quartiles: data.quartiles,
    valueGroups: data.valueGroups,
    fieldInfo: data.fieldInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectTabId: (id) => dispatch(selectDistributionTabId(id)),
  save: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionStatistics);
