import React from "react";
import PropTypes from "prop-types";
import Plotly from "plotly.js-cartesian-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import {
  ControlGroup,
  Divider,
  HTMLSelect,
  Icon,
  Slider,
  Tab,
  Tabs,
  Text,
} from "@blueprintjs/core";
import Draggable from "react-draggable";
import FeatureUnderConstruction from "../FeatureUnderConstruction";
import LabelWithInfo from "../LabelWithInfo";
import PotentialValuesTabPanel from "../PotentialValuesTabPanel";
import HEDMatrixPlot from "../../containers/hla/HEDMatrixPlot";

const Plot = createPlotlyComponent(Plotly);

const hlaZygosityLegend = [
  [
    ["Homozygous", "rgba(197, 70, 48, 0.75)"],
    ["Homozygous At Any Locus", "rgba(197, 70, 48, 0.9)"],
  ],
  [
    ["Heterozygous", "rgba(54, 52, 92, 0.75)"],
    ["Heterozygous At All Loci", "rgba(54, 52, 92, 0.9)"],
  ],
];

const hedDensityLegend = [
  [
    ["HED at HLA-A", "rgba(27.0,158.0,119.0,0.75)"],
    ["Mean HED", "rgba(231.0,41.0,138.0,0.75)"],
  ],
  [
    ["HED at HLA-B", "rgba(217.0,95.0,2.0,0.75)"],
    ["", "white"],
  ],
  [
    ["HED at HLA-C", "rgba(117.0,112.0,179.0,0.75)"],
    ["", "white"],
  ],
];

const fontFamily =
  '-apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif';

const hlaLocusValueToLabel = (hlaLocus) => {
  switch (hlaLocus) {
    case "hlaA":
      return "HLA-A";
    case "hlaB":
      return "HLA-B";
    case "hlaC":
      return "HLA-C";
    default:
      return "HLA-A";
  }
};

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hlaLocus: "hlaA" };
    this.changeStaticPLotLocus = this.changeStaticPLotLocus.bind(this);
  }

  changeStaticPLotLocus(e) {
    this.setState({ hlaLocus: e.target.value });
  }

  render() {
    const {
      selectedTabId,
      potentialValues,
      highestCount,
      selectTabId,
    } = this.props;
    const { hlaLocus } = this.state;
    let noCohortsSelected = true;
    if (
      potentialValues !== undefined &&
      Object.entries(potentialValues).length !== 0
    ) {
      noCohortsSelected = false;
    }
    return (
      <div className="statistics-container">
        <Tabs
          id="TabsExample"
          onChange={(newTabId) => selectTabId(newTabId)}
          selectedTabId={selectedTabId}
        >
          <Tab
            id="legend-tab"
            title="Legend"
            panel=<div className="hla-legend">
              <LabelWithInfo title="HLA Zygosity Legend" body={[]} />
              <div className="hla-zygosity-legend">
                {hlaZygosityLegend.map((row) => (
                  <div className="column" key={row[0][0]}>
                    <Text>
                      <Icon
                        icon="horizontal-bar-chart"
                        style={{ color: row[0][1] }}
                      />
                      {row[0][0]}
                    </Text>
                    <Text>
                      <Icon
                        icon="horizontal-bar-chart"
                        style={{ color: row[1][1] }}
                      />
                      {row[1][0]}
                    </Text>
                  </div>
                ))}
              </div>
              <Divider />
              <LabelWithInfo title="HED Density Legend" body={[]} />
              <div className="hed-density-legend">
                {hedDensityLegend.map((row) => (
                  <div className="column" key={row[0][0]}>
                    <Text>
                      <Icon
                        icon="timeline-area-chart"
                        style={{ color: row[0][1] }}
                      />
                      {row[0][0]}
                    </Text>
                    <Text>
                      <Icon
                        icon="timeline-area-chart"
                        style={{ color: row[1][1] }}
                      />
                      {row[1][0]}
                    </Text>
                  </div>
                ))}
              </div>
              <Divider />
              <LabelWithInfo title="Matrix HED Score Legend" body={[]} />
              <div className="hla-heatmap-legend">
                <Slider
                  min={0}
                  max={16}
                  labelStepSize={2}
                  showTrackFill={false}
                />
              </div>
              <Divider />
              <LabelWithInfo title="Allele Pair Frequency Legend" body={[]} />
              <div className="hla-allele-pair-legend">
                <Plot
                  data={[
                    {
                      x: [1],
                      y: [0],
                      type: "scatter",
                      mode: "text",
                      text: "0",
                      textfont: { family: fontFamily },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [2.7],
                      y: [0],
                      type: "scatter",
                      mode: "text",
                      text:
                        highestCount >= 4
                          ? (highestCount * 0.25).toString()
                          : "-",
                      textfont: { family: fontFamily },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [4.7],
                      y: [0],
                      type: "scatter",
                      mode: "text",
                      text:
                        highestCount >= 2
                          ? (highestCount * 0.5).toString()
                          : "-",
                      textfont: { family: fontFamily },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [6.7],
                      y: [0],
                      type: "scatter",
                      mode: "text",
                      text:
                        highestCount >= 2
                          ? (highestCount * 0.75).toString()
                          : "-",
                      textfont: { family: fontFamily },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [9],
                      y: [0],
                      type: "scatter",
                      mode: "text",
                      text: highestCount >= 1 ? highestCount.toString() : "-",
                      textfont: { family: fontFamily },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [1],
                      y: [11],
                      type: "scatter",
                      mode: "marker",
                      marker: {
                        color: "#394B59",
                        size: 2,
                      },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [2.7],
                      y: [11],
                      type: "scatter",
                      mode: "marker",
                      marker: {
                        color: "#394B59",
                        size: 10,
                      },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [4.7],
                      y: [11],
                      type: "scatter",
                      mode: "marker",
                      marker: {
                        color: "#394B59",
                        size: 20,
                      },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [6.7],
                      y: [11],
                      type: "scatter",
                      mode: "marker",
                      marker: {
                        color: "#394B59",
                        size: 30,
                      },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                    {
                      x: [9],
                      y: [11],
                      type: "scatter",
                      mode: "marker",
                      marker: {
                        color: "#394B59",
                        size: 40,
                      },
                      xaxis: "x",
                      yaxis: "y",
                      showlegend: false,
                    },
                  ]}
                  layout={{
                    xaxis: {
                      domain: [0, 1],
                      range: [0.25, 10],
                      fixedrange: true,
                      showticklabels: false,
                      autotick: false,
                      showgrid: false,
                      zeroline: false,
                    },
                    yaxis: {
                      domain: [0, 1],
                      range: [-1, 20],
                      fixedrange: true,
                      showticklabels: false,
                      autotick: false,
                      showgrid: false,
                      zeroline: false,
                    },
                    margin: {
                      t: 0,
                      r: 0,
                      b: 0,
                      l: 0,
                    },
                  }}
                  config={{ staticPlot: true }}
                  style={{ height: "80px", width: "100%" }}
                />
              </div>
            </div>
            panelClassName="legend-container"
          />
          <Tab
            id="potential-values-tab"
            title="Potential Values"
            panel=<PotentialValuesTabPanel
              noCohortsSelected={noCohortsSelected}
              potentialValues={potentialValues}
            />
            panelClassName="potential-values-container"
          />
          <Tab
            id="statistics-tab"
            title="Statistics"
            panel={<FeatureUnderConstruction description="" />}
            panelClassName="statistics-panel-container"
          />
          <Tab
            id="static-hed-matrix-tab"
            title="HED Matrix"
            panel={
              <div>
                <LabelWithInfo
                  title="HED Matrix"
                  body={[
                    "A draggable static HED matrix for each HLA class I locus.",
                  ]}
                />
                <ControlGroup style={{ marginBottom: "10px" }}>
                  <HTMLSelect
                    fill
                    options={[
                      { label: "HLA-A", value: "hlaA" },
                      { label: "HLA-B", value: "hlaB" },
                      { label: "HLA-C", value: "hlaC" },
                    ]}
                    onChange={(e) => this.changeStaticPLotLocus(e)}
                  />
                </ControlGroup>
                <Draggable handle="strong">
                  <div
                    className="box no-cursor"
                    style={{
                      height: "350px",
                      position: "absolute",
                      padding: "4px",
                      zIndex: 10,
                      border: "solid lightgray 1px",
                      borderRadius: "4px",
                      background: "white",
                    }}
                  >
                    <strong>
                      <Icon
                        className="cursor"
                        icon="drag-handle-horizontal"
                        iconSize={40}
                        style={{ margin: "0", float: "left" }}
                      />
                      <p
                        style={{
                          width: "calc(100% - 80px)",
                          float: "left",
                          marginTop: "10px",
                          textAlign: "center",
                        }}
                      >
                        HED Matrix at {hlaLocusValueToLabel(hlaLocus)}
                      </p>
                      <Icon
                        className="cursor"
                        icon="drag-handle-horizontal"
                        iconSize={40}
                        style={{ margin: "0", float: "left" }}
                      />
                    </strong>
                    <div style={{ height: "300px", width: "340px" }}>
                      <HEDMatrixPlot hlaLocus={hlaLocus} />
                    </div>
                  </div>
                </Draggable>
              </div>
            }
            panelClassName="static-hed-matrix-container"
          />
          <Tabs.Expander />
        </Tabs>
      </div>
    );
  }
}

Statistics.propTypes = {
  selectedTabId: PropTypes.string.isRequired,
  potentialValues: PropTypes.object.isRequired,
  highestCount: PropTypes.number.isRequired,
  selectTabId: PropTypes.func.isRequired,
};

export default Statistics;
