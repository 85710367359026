import { connect } from "react-redux";
import { postComment } from "../actions/async";
import Comments from "../components/Comments";

const mapStateToProps = (state) => ({
  tooltip:
    state.ui.present.isTutorialPanelOpen &&
    state.tutorialTooltips.present !== undefined
      ? state.tutorialTooltips.present.commentsButton
      : state.tutorialTooltips.saved.commentsButton,
});

const mapDispatchToProps = (dispatch) => ({
  postComment: (intent, text) => dispatch(postComment(intent, text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
