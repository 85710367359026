import { connect } from "react-redux";
import { saveCohort } from "../../actions/cohorts";
import DistributionPlot from "../../components/distribution/Plot";

const mapStateToProps = (state) => {
  const { data, isFetching, revision } = state.dataFetch.distribution;

  let selectedField = {
    field: "",
    table: "",
    group: "",
    class: "",
    description: "",
  };
  state.dataFetch.allDataFields.data.forEach((field) => {
    if (field.field === state.distribution.present.dataField) {
      selectedField = field;
    }
  });

  let plotType = "patient";
  if (selectedField.table === "Samples") {
    plotType = "sample";
  }
  const layout = {
    margin: {
      t: 50,
      b: 50,
    },
    xaxis: {
      showgrid: false,
      zeroline: false,
      ticks: "",
      showticklabels: false,
    },
    yaxis: {
      showgrid: false,
      zeroline: false,
      ticks: "",
      showticklabels: false,
    },
    autosize: true,
  };

  if (isFetching) {
    return {
      revision,
      data: [],
      layout: {
        margin: {
          t: 50,
          b: 50,
        },
        xaxis: {
          showgrid: false,
          zeroline: false,
          ticks: "",
          showticklabels: false,
        },
        yaxis: {
          showgrid: false,
          zeroline: false,
          ticks: "",
          showticklabels: false,
        },
        autosize: true,
        images: [
          {
            x: 0.3,
            y: 0.8,
            sizex: 0.5,
            sizey: 0.5,
            source: "https://i.ibb.co/tcrz9JW/fetching-results.png",
            xanchor: "left",
            xref: "paper",
            yanchor: "top",
            yref: "paper",
          },
        ],
      },
      plotType,
      field: {},
      fieldInfo: {},
    };
  }
  return {
    revision,
    data: data.traces,
    layout: Object.assign(layout, data.layout),
    plotType,
    field: selectedField,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveCohort: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DistributionPlot);
