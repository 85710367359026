import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {
  Alignment,
  Boundary,
  Button,
  ButtonGroup,
  Callout,
  Card,
  Divider,
  Drawer,
  Elevation,
  FocusStyleManager,
  Hotkey,
  Hotkeys,
  HotkeysTarget,
  Intent,
  Menu,
  Navbar,
  OverflowList,
  Popover,
  Position,
  Tabs,
  Tab,
  Toast,
  Toaster,
  Tooltip,
} from "@blueprintjs/core";
import { NavigationMapping, EndpointMapping } from "../constants";
import ModuleLink from "./ModuleLink";
import Status from "../containers/Status";
import LandingPageDialog from "../containers/LandingPageDialog";
import CohortsContainer from "../containers/cohorts/Container";
import Comments from "../containers/Comments";
import SessionMenu from "../containers/SessionMenu";
import TutorialContainer from "../containers/tutorial/Container";
import CohortsHighlightedPublished from "../containers/cohorts/HighlightedPublished";
import CohortsHighlightedSaved from "../containers/cohorts/HighlightedSaved";
import CohortsPendingDialog from "../containers/cohorts/PendingDialog";
import CorrelationSourcePicker from "../containers/correlation/SourcePicker";
import CorrelationFirstDataFieldPicker from "../containers/correlation/FirstDataFieldPicker";
import CorrelationSecondDataFieldPicker from "../containers/correlation/SecondDataFieldPicker";
import CorrelationDataFieldSwapper from "../containers/correlation/DataFieldSwapper";
import CorrelationPlot from "../containers/correlation/Plot";
import CorrelationStatistics from "../containers/correlation/Statistics";
import DistributionSourcePicker from "../containers/distribution/SourcePicker";
import DistributionDataFieldPicker from "../containers/distribution/DataFieldPicker";
import DistributionPlot from "../containers/distribution/Plot";
import DistributionStatistics from "../containers/distribution/Statistics";
import ExpressionSourcePicker from "../containers/expression/SourcePicker";
import ExpressionGeneSelect from "../containers/expression/GeneSelect";
import ExpressionSortBySelect from "../containers/expression/SortBySelect";
import ExpressionTopTrackDataFieldPicker from "../containers/expression/TopTrackDataFieldPicker";
import ExpressionScaleSelect from "../containers/expression/ScaleSelect";
import ExpressionNormalizeSelect from "../containers/expression/NormalizeSelect";
import ExpressionDataFieldScaleSelect from "../containers/expression/DataFieldScaleSelect";
import ExpressionStatistics from "../containers/expression/Statistics";
import ExpressionPlot from "../containers/expression/Plot";
import VolcanoBatchCorrectionButton from "../containers/volcano/BatchCorrectionButton";
import VolcanoColoringRadio from "../containers/volcano/ColoringRadio";
import VolcanoPCADomainRadio from "../containers/volcano/PCADomainRadio";
import VolcanoChartContainer from "./volcano/ChartContainer";
import MutationSourcePicker from "../containers/mutation/SourcePicker";
import MutationGeneSelect from "../containers/mutation/GeneSelect";
import MutationSortBySelect from "../containers/mutation/SortBySelect";
import MutationTopTrackDataFieldPicker from "../containers/mutation/TopTrackDataFieldPicker";
import MutationDataFieldScaleSelect from "../containers/mutation/DataFieldScaleSelect";
import MutationStatistics from "../containers/mutation/Statistics";
import MutationPlot from "../containers/mutation/Plot";
import HLASourcePicker from "../containers/hla/SourcePicker";
import HLAPlotContainer from "../containers/hla/PlotContainer";
import HLAStatistics from "../containers/hla/Statistics";
import SurvivalSourcePicker from "../containers/survival/SourcePicker";
import SurvivalLogrankWeightingPicker from "../containers/survival/LogrankWeightingPicker";
import SurvivalConfidenceIntervalPicker from "../containers/survival/ConfidenceIntervalPicker";
import SurvivalPlot from "../containers/survival/Plot";
import SurvivalStatistics from "../containers/survival/Statistics";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/table/lib/css/table.css";
import "../css/Body.css";
import "../css/Cohorts.css";
import "../css/Comments.css";
import "../css/Distribution.css";
import "../css/FilterList.css";
import "../css/GeneModules.css";
import "../css/Parameter.css";
import "../css/Plot.css";
import "../css/Published.css";
import "../css/SelectionFilterTextArea.css";
import "../css/Session.css";
import "../css/Statistics.css";
import "../css/Survival.css";
import "../css/Tutorial.css";
import "../css/Volcano.css";

FocusStyleManager.onlyShowFocusOnTabs();

class App extends React.Component {
  constructor(props) {
    super(props);
    const { isSelectionPanelOpen, isTutorialPanelOpen, fetchData } = this.props;
    this.state = {
      bodyClassNameLeft: isSelectionPanelOpen ? "with-left-margin" : "",
      bodyClassNameRight: isTutorialPanelOpen ? "with-right-margin" : "",
      resizePlotsTriggerProp: true,
    };
    this.changeBodyMargin = this.changeBodyMargin.bind(this);
    this.resizePlots = this.resizePlots.bind(this);
    this.toggleSelectionPanel = this.toggleSelectionPanel.bind(this);
    this.toggleTutorialPanel = this.toggleTutorialPanel.bind(this);
    fetchData([
      EndpointMapping.DATASETS,
      EndpointMapping.TREATMENTS,
      EndpointMapping.CANCER_TYPES,
      EndpointMapping.DNA_COUNTS,
      EndpointMapping.RNA_COUNTS,
      EndpointMapping.ALL_DATA_FIELDS,
      EndpointMapping.ALL_GENES,
      EndpointMapping.GENE_SETS,
      EndpointMapping.STATIC_HED_MATRIX,
    ]);
  }

  componentDidMount() {
    const { fetchSession } = this.props;
    fetchSession();
    setTimeout(() => this.resizePlots(), 500);
  }

  static getDerivedStateFromProps(props, state) {
    const { isSelectionPanelOpen, isTutorialPanelOpen } = props;
    const { resizePlotsTriggerProp } = state;
    return {
      bodyClassNameLeft: isSelectionPanelOpen ? "with-left-margin" : "",
      bodyClassNameRight: isTutorialPanelOpen ? "with-right-margin" : "",
      resizePlotsTriggerProp: !resizePlotsTriggerProp,
    };
  }

  changeBodyMargin(side) {
    const { bodyClassNameLeft, bodyClassNameRight } = this.state;
    const newBodyClassNameLeft =
      bodyClassNameLeft === "with-left-margin" ? "" : "with-left-margin";
    const newBodyClassNameRight =
      bodyClassNameRight === "with-right-margin" ? "" : "with-right-margin";
    this.setState({
      bodyClassNameLeft:
        side === "left" ? newBodyClassNameLeft : bodyClassNameLeft,
      bodyClassNameRight:
        side === "right" ? newBodyClassNameRight : bodyClassNameRight,
    });
  }

  resizePlots() {
    const { resizePlotsTriggerProp } = this.state;
    this.setState({
      resizePlotsTriggerProp: !resizePlotsTriggerProp,
    });
  }

  toggleSelectionPanel() {
    const { toggleSelectionPanelTwo } = this.props;
    this.changeBodyMargin("left");
    setTimeout(() => this.resizePlots(), 350);
    toggleSelectionPanelTwo();
  }

  toggleTutorialPanel() {
    const { toggleTutorialPanelTwo } = this.props;
    this.changeBodyMargin("right");
    setTimeout(() => this.resizePlots(), 350);
    toggleTutorialPanelTwo();
  }

  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global
          combo="shift + f"
          label="Start Fresh Session"
          onKeyDown={() => {
            window.location.href = "/";
          }}
        />
        <Hotkey
          global
          combo="shift + x"
          label="Toggle Selection Panel"
          onKeyDown={() => this.toggleSelectionPanel()}
        />
        <Hotkey
          global
          combo="shift + t"
          label="Toggle Tutorial Panel"
          onKeyDown={() => this.toggleTutorialPanel()}
        />
      </Hotkeys>
    );
  }

  render() {
    const {
      bodyClassNameLeft,
      bodyClassNameRight,
      resizePlotsTriggerProp,
    } = this.state;
    const {
      isTutorialActive,
      isTutorialFinished,
      redirectPathname,
      useRedirect,
      currentPage,
      currentSearch,
      toast,
      tooltips,
      setPathname,
      openSelectionPanel,
      removeToast,
      chooseGettingStartedTutorial,
    } = this.props;
    let body;
    switch (currentPage) {
      case NavigationMapping.DISTRIBUTION:
        body = (
          <div className="analysis-module">
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <Callout icon="timeline-bar-chart" title="Distribution">
                  Use this module to plot the distributions of clinical,
                  demographic, and pipeline-derived data fields.
                </Callout>
                <div className="parameter-container">
                  <DistributionSourcePicker />
                  <Divider />
                  <DistributionDataFieldPicker />
                </div>
              </Card>
            </div>
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <DistributionStatistics />
              </Card>
            </div>
            <div className="plot-container">
              <DistributionPlot
                resizePlotsTriggerProp={resizePlotsTriggerProp}
              />
            </div>
          </div>
        );
        break;
      case NavigationMapping.CORRELATION:
        body = (
          <div className="analysis-module">
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <Callout icon="scatter-plot" title="Correlation">
                  Use this module to investigate the correlations between data
                  fields.
                </Callout>
                <div className="parameter-container">
                  <CorrelationSourcePicker />
                  <Divider />
                  <CorrelationFirstDataFieldPicker />
                  <Divider />
                  <CorrelationSecondDataFieldPicker />
                  <Divider />
                  <CorrelationDataFieldSwapper />
                </div>
              </Card>
            </div>
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <CorrelationStatistics />
              </Card>
            </div>
            <div className="plot-container">
              <CorrelationPlot
                resizePlotsTriggerProp={resizePlotsTriggerProp}
              />
            </div>
          </div>
        );
        break;
      case NavigationMapping.MUTATION:
        body = (
          <div className="analysis-module">
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <Callout icon="heat-grid" title="Mutation">
                  Use this module to plot DNA variants.
                </Callout>
                <div className="parameter-container">
                  <MutationSourcePicker />
                  <Divider />
                  <MutationGeneSelect />
                  <Divider />
                  <MutationTopTrackDataFieldPicker />
                  <Divider />
                  <MutationSortBySelect />
                  <Divider />
                  <MutationDataFieldScaleSelect />
                </div>
              </Card>
            </div>
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <MutationStatistics />
              </Card>
            </div>
            <div className="plot-container">
              <MutationPlot resizePlotsTriggerProp={resizePlotsTriggerProp} />
            </div>
          </div>
        );
        break;
      case NavigationMapping.EXPRESSION:
        body = (
          <div className="analysis-module">
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <Callout icon="grid" title="Expression">
                  Use this module to plot RNA expression values.
                </Callout>
                <div className="parameter-container">
                  <ExpressionSourcePicker />
                  <Divider />
                  <ExpressionGeneSelect />
                  <Divider />
                  <ExpressionTopTrackDataFieldPicker />
                  <Divider />
                  <ExpressionSortBySelect />
                  <Divider />
                  <ExpressionScaleSelect />
                  <Divider />
                  <ExpressionNormalizeSelect />
                  <Divider />
                  <ExpressionDataFieldScaleSelect />
                </div>
              </Card>
            </div>
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <ExpressionStatistics />
              </Card>
            </div>
            <div className="plot-container">
              <ExpressionPlot resizePlotsTriggerProp={resizePlotsTriggerProp} />
            </div>
          </div>
        );
        break;
      case NavigationMapping.VOLCANO:
        body = (
          <div id="volcano-container">
            <div id="volcano-menu-container">
              <Callout icon="grid" title="Volcano">
                Use this module to plot differentially expression genes and
                batch-corrected RNA expression values.
                <VolcanoBatchCorrectionButton />
              </Callout>
              <div id="volcano-tabs">
                <Tabs>
                  <Tab
                    id="tab-1"
                    title="PCA Coloring"
                    panel=<VolcanoColoringRadio />
                  />
                  <Tab
                    id="tab-2"
                    title="PCA Domain"
                    panel=<VolcanoPCADomainRadio />
                  />
                </Tabs>
              </div>
            </div>
            <Divider />
            <VolcanoChartContainer />
          </div>
        );
        break;
      case NavigationMapping.HLA:
        body = (
          <div className="analysis-module">
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <Callout icon="heatmap" title="HLA Divergence">
                  Use this module to explore the divergence of HLA class-I
                  alleles.
                </Callout>
                <div className="parameter-container">
                  <HLASourcePicker />
                </div>
              </Card>
            </div>
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <HLAStatistics />
              </Card>
            </div>
            <HLAPlotContainer resizePlotsTriggerProp={resizePlotsTriggerProp} />
          </div>
        );
        break;
      case NavigationMapping.SURVIVAL:
        body = (
          <div className="analysis-module">
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <Callout icon="lifesaver" title="Survival">
                  Use this module to plot Kaplan-Meier survival curves.
                </Callout>
                <div className="parameter-container">
                  <SurvivalSourcePicker />
                  <Divider />
                  <SurvivalConfidenceIntervalPicker />
                  <Divider />
                  <SurvivalLogrankWeightingPicker />
                </div>
              </Card>
            </div>
            <div className="plot-controls">
              <Card className="inner" elevation={0}>
                <SurvivalStatistics />
              </Card>
            </div>
            <div className="plot-container">
              <SurvivalPlot resizePlotsTriggerProp={resizePlotsTriggerProp} />
            </div>
          </div>
        );
        break;
      case NavigationMapping.HOME:
      default:
        body = (
          <div className="home-container">
            <div className="heading-container">
              <h1 className="bp3-heading">IOExplorer</h1>
              <p>
                A cancer immunogenomics analysis platform developed by CCF
                Immunotherapy Center.
              </p>
            </div>
            <div className="card-container">
              <div className="row">
                <Card
                  className="column-12-12"
                  interactive
                  elevation={Elevation.TWO}
                >
                  <h4 className="bp3-heading">Getting Started</h4>
                  <p>
                    Follow an interactive tutorial to learn how to use
                    IOExplorer.
                  </p>
                  <ButtonGroup>
                    <Button
                      disabled={isTutorialActive}
                      icon="learning"
                      intent="primary"
                      onClick={() => {
                        chooseGettingStartedTutorial();
                        this.toggleTutorialPanel();
                      }}
                      text="Start Tutorial"
                      rightIcon="arrow-right"
                    />
                  </ButtonGroup>
                </Card>
              </div>
              <div className="row">
                <Card
                  className="column-6-12"
                  interactive
                  elevation={Elevation.TWO}
                >
                  <h4 className="bp3-heading">Mutation</h4>
                  <p>
                    Visualize DNA variants in an interactive OncoPrint plot.
                  </p>
                  <button
                    className="link"
                    onClick={() => {
                      openSelectionPanel();
                      setPathname(NavigationMapping.MUTATION);
                    }}
                    type="button"
                  >
                    <ModuleLink pathname={NavigationMapping.MUTATION}>
                      Jump To Mutation
                    </ModuleLink>
                  </button>
                </Card>
                <Card
                  className="column-6-12"
                  interactive
                  elevation={Elevation.TWO}
                >
                  <h4 className="bp3-heading">Expression</h4>
                  <p>
                    Plot RNA expression values and arrange samples by gene
                    expression levels.
                  </p>
                  <button
                    className="link"
                    onClick={() => {
                      openSelectionPanel();
                      setPathname(NavigationMapping.EXPRESSION);
                    }}
                    type="button"
                  >
                    <ModuleLink pathname={NavigationMapping.EXPRESSION}>
                      Jump To Expression
                    </ModuleLink>
                  </button>
                </Card>
              </div>
            </div>
          </div>
        );
    }
    return (
      <div>
        {useRedirect ? (
          <Redirect to={redirectPathname + currentSearch} />
        ) : (
          <div />
        )}
        <div className="drawer-container">
          <Drawer
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus={false}
            icon="person"
            isOpen={bodyClassNameLeft === "with-left-margin"}
            hasBackdrop={false}
            onClose={() => this.toggleSelectionPanel()}
            position={Position.LEFT}
            size={360}
            title="Cohorts"
            usePortal={false}
          >
            <CohortsContainer />
          </Drawer>
        </div>
        <div
          className={`tutorial-drawer-container ${
            isTutorialActive ? "active" : ""
          } ${isTutorialFinished ? "finished" : ""}`}
        >
          <Drawer
            canOutsideClickClose
            enforceFocus={false}
            icon="learning"
            isOpen={bodyClassNameRight === "with-right-margin"}
            hasBackdrop
            onClose={() => this.toggleTutorialPanel()}
            position={Position.RIGHT}
            size={360}
            title="Tutorials"
            transitionDuration={1}
            transitionName="none"
            usePortal={false}
          >
            <TutorialContainer />
          </Drawer>
        </div>
        <div className={`${"body outer"} ${bodyClassNameRight}`}>
          <div className={`${"body inner"} ${bodyClassNameLeft}`}>
            <Navbar className="navbar">
              <div className="module-links-container">
                <Navbar.Group align={Alignment.LEFT}>
                  <Tooltip {...tooltips.navigationBar}>
                    <button
                      onClick={() => setPathname(NavigationMapping.HOME)}
                      type="button"
                    >
                      <ModuleLink pathname={NavigationMapping.HOME}>
                        IOExplorer
                      </ModuleLink>
                    </button>
                  </Tooltip>
                  <Navbar.Divider />
                  <OverflowList
                    visibleItemRenderer={(props) => (
                      <button
                        key={props.text}
                        onClick={() => setPathname(props.href)}
                        type="button"
                      >
                        <ModuleLink
                          pathname={props.href}
                          superscript={props.superscript}
                        >
                          {props.text}
                        </ModuleLink>
                      </button>
                    )}
                    collapseFrom={Boundary.END}
                    items={[
                      {
                        text: "Distribution",
                        href: NavigationMapping.DISTRIBUTION,
                      },
                      {
                        text: "Correlation",
                        href: NavigationMapping.CORRELATION,
                      },
                      {
                        text: "Mutation",
                        href: NavigationMapping.MUTATION,
                      },
                      {
                        text: "Expression",
                        href: NavigationMapping.EXPRESSION,
                      },
                      {
                        text: "Volcano",
                        href: NavigationMapping.VOLCANO,
                        superscript: "Beta",
                      },
                      {
                        text: "HLA Divergence",
                        href: NavigationMapping.HLA,
                      },
                      {
                        text: "Survival",
                        href: NavigationMapping.SURVIVAL,
                      },
                    ]}
                    overflowRenderer={(items) => (
                      <Popover
                        content={
                          <Menu>
                            {items.map((item) => (
                              <div key={item.text} style={{ margin: "5px 0" }}>
                                <button
                                  key={item.text}
                                  onClick={() => setPathname(item.href)}
                                  type="button"
                                >
                                  <ModuleLink pathname={item.href}>
                                    {item.text}
                                  </ModuleLink>
                                </button>
                              </div>
                            ))}
                          </Menu>
                        }
                        usePortal={false}
                      >
                        <Button icon="more" minimal small />
                      </Popover>
                    )}
                  />
                </Navbar.Group>
              </div>
              <Navbar.Group align={Alignment.RIGHT}>
                <Tooltip {...tooltips.sessionButton}>
                  <Popover content=<SessionMenu /> usePortal={false}>
                    <Button icon="time" text="Session" />
                  </Popover>
                </Tooltip>
                <Navbar.Divider />
                <Tooltip {...tooltips.cohortPanelButton}>
                  <Button
                    active={bodyClassNameLeft === "with-left-margin"}
                    icon="person"
                    text="Cohorts"
                    intent={Intent.PRIMARY}
                    onClick={() => this.toggleSelectionPanel()}
                  />
                </Tooltip>
                <Navbar.Divider />
                <Button
                  active={isTutorialActive}
                  icon="learning"
                  text="Tutorials"
                  intent={
                    isTutorialActive
                      ? isTutorialFinished
                        ? Intent.SUCCESS
                        : Intent.DANGER
                      : Intent.PRIMARY
                  }
                  onClick={() => this.toggleTutorialPanel()}
                  rightIcon={
                    isTutorialActive
                      ? isTutorialFinished
                        ? "tick-circle"
                        : "stop"
                      : "play"
                  }
                />
              </Navbar.Group>
            </Navbar>
            <Toaster position={Position.TOP_CENTER}>
              {toast.length !== 0 ? (
                toast.map((t) => (
                  <Toast
                    key={t.id}
                    {...t}
                    onDismiss={() => removeToast(t.id)}
                  />
                ))
              ) : (
                <div />
              )}
            </Toaster>
            {body}
            <LandingPageDialog />
            <CohortsPendingDialog />
            <CohortsHighlightedPublished />
            <CohortsHighlightedSaved />
            <Comments />
            <Status />
          </div>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  isTutorialActive: PropTypes.bool.isRequired,
  isTutorialFinished: PropTypes.bool.isRequired,
  isSelectionPanelOpen: PropTypes.bool.isRequired,
  isTutorialPanelOpen: PropTypes.bool.isRequired,
  redirectPathname: PropTypes.string.isRequired,
  useRedirect: PropTypes.bool.isRequired,
  currentPage: PropTypes.string.isRequired,
  currentSearch: PropTypes.string.isRequired,
  toast: PropTypes.array.isRequired,
  tooltips: PropTypes.object.isRequired,
  setPathname: PropTypes.func.isRequired,
  toggleSelectionPanelTwo: PropTypes.func.isRequired,
  toggleTutorialPanelTwo: PropTypes.func.isRequired,
  openSelectionPanel: PropTypes.func.isRequired,
  removeToast: PropTypes.func.isRequired,
  clearToast: PropTypes.func.isRequired,
  chooseGettingStartedTutorial: PropTypes.func.isRequired,
  fetchSession: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
};

const HotKeysApp = HotkeysTarget(App);

export default HotKeysApp;
