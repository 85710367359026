import React from "react";
import PropTypes from "prop-types";
import { Alignment, Icon, Radio, RadioGroup } from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";

const NormalizeSelect = ({ normalize, select }) => (
  <div className="expression-normalize-container">
    <LabelWithInfo title="Normalize" body={[]} />
    <RadioGroup
      className="normalize-container"
      onChange={(e) => select(e.target.value)}
      selectedValue={normalize}
    >
      <Radio
        label=<div>
          <Icon icon="ungroup-objects" />
          Within Cohorts
        </div>
        value="within-cohorts"
        alignIndicator={Alignment.RIGHT}
      />
      <Radio
        label=<div>
          <Icon icon="full-circle" />
          Across Cohorts
        </div>
        value="across-cohorts"
        alignIndicator={Alignment.RIGHT}
      />
    </RadioGroup>
  </div>
);

NormalizeSelect.propTypes = {
  normalize: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
};

export default NormalizeSelect;
