import { connect } from "react-redux";
import { TutorialTreeStructure, fillNode } from "../../tutorial/tutorialList";
import { chooseTutorial } from "../../actions";
import TutorialTree from "../../components/tutorial/Tree";

const mapStateToProps = (state) => ({
  tutorialTree: TutorialTreeStructure.map((node) => fillNode(node, state)),
  currentTutorialIdx: state.tutorial.currentTutorial,
  didTutorialStart: state.tutorial.didTutorialStart,
});

const mapDispatchToProps = (dispatch) => ({
  chooseTutorial: (tutorialIdx) => dispatch(chooseTutorial(tutorialIdx)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorialTree);
