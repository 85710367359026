import { connect } from "react-redux";
import { selectHLATabId } from "../../actions";
import HLAStatistics from "../../components/hla/Statistics";

const mapStateToProps = (state) => {
  return {
    selectedTabId: state.hla.present.ui.selectedTabId,
    potentialValues: state.dataFetch.hlaZygosityDensity.data.potentialValues,
    highestCount: state.dataFetch.hlaAllelePairMatrix.data.highestCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectTabId: (id) => dispatch(selectHLATabId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HLAStatistics);
